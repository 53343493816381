import { Injectable } from "@angular/core";
import Web3 from "web3";
// const Web3 = require("web3");
// const web3socket = new Web3(new Web3.providers.WebsocketProvider("wss://sepolia.infura.io/ws/v3/f2b02be664444780ba4f3050f2c0fa0e"));
// const web3 = new Web3('https://mainnet.infura.io/v3/f2b02be664444780ba4f3050f2c0fa0e');

import { BlockHeader } from "web3-eth";
import { TransactionReceipt } from 'web3-core';
import { Subscription } from 'web3-core-subscriptions';
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import detectEthereumProvider from '@metamask/detect-provider';
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { EXPLORER_TRANSACTION, VAULT_ADDRESS, WIRE_API } from '../_constants/constants'
import { SystemService } from "./system.service";
import { AlertController, LoadingController, ToastButton, ToastController } from "@ionic/angular";
import { ContractService } from "./contract.service";
import { ChainID, ShopClaim, ShopListing } from "./eth.service";

import ABI_1155 from '../_abis/abi_1155.json';
import ABI_721 from '../_abis/abi_721.json';
import { SocketService } from "./socket.service";
import { clearInterval } from "timers";
// import * as ABI_165 from '../_abis/abi_165.json';

const chain_id = {
    eth : 1,
    matic : 137,
    sepolia: 11155111
}
const chain_name = {
    1 : "eth",
    137 : "matic",
    11155111 : "sepolia"
}
const chain_explorer = {
    1 : "https://etherscan.io",
    137 : "https://polygonscan.com",
    11155111 : "https://sepolia.etherscan.io",
    80001 : "https://mumbai.polygonscan.com"
}

@Injectable()
export class ConnectService {
    private events: any = {};
    private _accounts: string[] = [];
    private _balance: any;

    public net_mismatch: boolean = false;
    public network_str: string = 'Mainnet';
    public network_id: number = 0;
    // public network_str: string = 'Ropsten';

    public isWireNetwork: boolean = false;
    public isMainNetwork: boolean = false;
    public wire_network_str: string = 'Airwire';

    public web3?: Web3;
    public provider: any;

    private logSubscription?: Subscription<BlockHeader>;

    // public pendingTrx? : MatSnackBarRef<TextOnlySnackBar>
    public pendingData : any
    activePending : boolean = false

    // pendingToast? : HTMLIonToastElement
    pendingHash? : string

    public get balance(): string {
        return this._balance && this._balance != '' ? this.weitoeth(this._balance) : '0';
    }

    public get accounts(): string[] {
        return this._accounts;
    }

    public get connected(): boolean {
        return this._accounts.length > 0;
    }


    constructor(
        public auth : AuthService,
        private router : Router,
        private http : HttpClient,
        private system : SystemService,
        private alert : AlertController,
        private contract : ContractService,
        private load : LoadingController,
        private toast : ToastController,
        private socket : SocketService
        // private web3modal: Web3ModalService,
    ){

        this.system.on('disconnect').subscribe(()=>{
            this.disconnect()
        })
        this.auth.on('socket-init').subscribe(()=>{
            this.checkTrxStatus()
        })
    }

    async connect() : Promise<string>{
        return new Promise((resolve, reject)=>{
            let ethereum = (window as any).ethereum;
            if (typeof ethereum !== 'undefined') {
                // console.log('MetaMask is available');
            }
            if (ethereum) {
                try {
                    ethereum.request({ method: 'eth_requestAccounts' }).then(async (address:any) => {
                        this._accounts = address

                        if (this.auth.address && this.auth.address.length >= 40){
                            localStorage.setItem('address', address[0])
                            this.auth.emit('address-set', address[0])
                        }
                        else {
                            localStorage.setItem('address', address[0])
                            this.auth.emit('new-address-set', address[0])
                        }
                        resolve(address[0])

                        this.provider = await detectEthereumProvider();                    
                        this.web3 = new Web3(this.provider);

                        this._accounts = await this.web3.eth.getAccounts();
                        this._balance = await this.web3.eth.getBalance(this._accounts[0]);
                        this.subscribeLogs(this._accounts[0]);
                        this.emit('accountsChanged', this._accounts)

                        let net = await this.web3.eth.getChainId();
                        // console.log("get chain id", +net);
                        this.network_id = net;
                        // this.isWireNetwork = environment.ethereum_network.includes(+net);
                        // this.isMainNetwork = environment.ethereum_network.includes(+net);
                        // this.net_mismatch = !environment.ethereum_network.includes(+net);

                        this.provider.on('networkChanged', (id : any) => {
                            // console.log("network changed to", +id);
                            this.network_id = id;
                            // this.net_mismatch = !environment.ethereum_network.includes(+id);
                            // this.isWireNetwork = environment.ethereum_network.includes(+id);
                            // this.isMainNetwork = environment.ethereum_network.includes(+net);
                            // console.log(environment.ethereum_network, id, this.net_mismatch);
                            this.emit('networkChanged', id)
                        })

                        this.provider.on('accountsChanged', async (accounts : any) => {
                            this._accounts = accounts;

                            if (accounts.length > 0){
                                this._balance = await this.web3!.eth.getBalance(this._accounts[0]);
                                this.subscribeLogs(this._accounts[0]);
                                this.emit('accountsChanged', this._accounts);
                            }
                            else {
                                localStorage.removeItem('address')
                                this.auth.emit('remove-address')
                            }
                        });

                        this.provider.on('chainChanged', () => {
                            this.web3!.eth.net.getId().then((id) => {
                                // console.log(id);
                            })
                        })

                        this.provider.on('disconnect', (code: number, reason: string) => {
                            // console.log(code, reason);
                        })

                        // this.provider.on('message', (message: any) => {
                        //     console.log("message", message);
                        // })
                    }, (err:any) =>{
                        console.log(err);
                    });
                } catch (error) {
                    // User denied account access...
                    console.log("User denied account access");

                    this.disconnect()
                }
            }
        })
    }

    getChainID(): Promise<ChainID> {
        return new Promise(async (resolve, reject)=>{
            this.provider = await detectEthereumProvider();                    
            this.web3 = new Web3(this.provider);
            let net = await this.web3!.eth.getChainId();
            resolve(<ChainID>net)
        })
    }

    transferNFT(nft : ShopListing) {
        return new Promise(async (resolve, reject)=>{
            let connectedAddess = await this.connect()
            let ethereum = (window as any).ethereum;
            if (typeof ethereum !== 'undefined' && this.web3) {
                const contractAddress = nft.token_address;
                const contractAbi : any = nft.contract_type == 1155 ? ABI_1155.abi : ABI_721.abi;
                const contract = new this.web3.eth.Contract(contractAbi, contractAddress);
                const tokenId = nft.token_id;
                const recipientAddress = '0xe525Ceb549AEf3e6826B452385C1732CafA3Ac6B';
                const fromAddress = connectedAddess;

                // // const web3 = new Web3(window.ethereum);
                // if (nft.seller_address && connectedAddess.toLowerCase() == nft.seller_address.toLowerCase()) try {
                //     // await window.ethereum.enable();
                //     // let listing : ShopListing = await this.contract.getListingByKey(nft.key)
                //     const abi : any = nft.contract_type == 1155 ? ABI_1155.abi : ABI_721.abi
                //     const tokenId = nft.token_id.toString();
                //     const contractAddress = nft.token_address;
                //     const fromAddress = connectedAddess;
                //     const toAddress = '0x7412BC256355ABD22dD53De3a38E8995b5d4c1D1';
                //     const contract = new this.web3.eth.Contract(abi, contractAddress);
                //     const data = contract.methods.transferFrom(fromAddress, toAddress, tokenId).encodeABI();

                //     // const gasPrice = await this.web3.eth.getGasPrice();
                //     // const gasLimit = await contract.methods.transferFrom(fromAddress, toAddress, tokenId).estimateGas();
                //     // const nonce = await this.web3.eth.getTransactionCount(fromAddress);

                //     const rawTransaction = {
                //         'from': fromAddress,
                //         'to': toAddress,
                //         // 'value': '0x00',
                //         // 'gasPrice': this.web3.utils.toHex(gasPrice),
                //         // 'gasLimit': this.web3.utils.toHex(gasLimit),
                //         // 'nonce': parseInt(this.web3.utils.toHex(nonce), 10),
                //         'data': data
                //     };
                //     // console.log(rawTransaction);

                //     const signedTransaction = await (window as any).ethereum.request({
                //         method: 'eth_sendTransaction',
                //         params: [rawTransaction]
                //     });
                //     // console.log(signedTransaction);

                //     // this.socket.emit('submit-trx', { claimkey: nft.claimkey, address: connectedAddess })
                //     // this.pendingClaimKey = nft.claimkey
                    
                //     this.subscribeLogs()
                //     this.checkTrxStatus()

                //     // const receipt = await this.web3.eth.getTransactionReceipt(signedTransaction);

                // } catch (error : MetamaskError | any) {
                //     reject(error)
                //     console.log(error);
                //     this.showToast({ header: error.message, color: 'warning' })
                // }
                // else {
                //     this.showToast({ 
                //         header: `Error: Address mismatch`, 
                //         message: `Sender address does not match the address you currently have linked. Please link the correct address matching: <b>${this.shortenAddress(nft.seller_address!)}</b>`, 
                //         color: 'danger',
                //         duration: 6000
                //     })
                //     reject()
                // }
            } else {
                reject()
                console.log('MetaMask is not installed');
            }
        })
    }

    submitListing(nft : ShopListing) {
        return new Promise(async (resolve, reject)=>{
            let connectedAddess = await this.connect()
            let ethereum = (window as any).ethereum;
            if (typeof ethereum !== 'undefined' && this.web3) {
                const chainid : number = await this.web3.eth.getChainId()
                if (chainid != nft.chainid){
                    console.log('CHAIN MISMATCH:', chainid, nft.chainid);
                    // return
                }

                const loading1 = await this.load.create({ spinner: 'crescent', message: `Metamask...`, cssClass: 'loading-overlay', backdropDismiss: true });
                await loading1.present();
                try {
                    const abi : any = nft.contract_type == 1155 ? ABI_1155.abi : ABI_721.abi;
                    const tokenId : string = nft.token_id.toString();
                    const contractAddress = nft.token_address;
                    const fromAddress = connectedAddess;
                    const toAddress = VAULT_ADDRESS;
                    const nftContract = new this.web3.eth.Contract(abi, contractAddress);
                    const gasPrice = await this.web3.eth.getGasPrice();
                    const maxPriorityFeePerGas = this.web3.utils.toWei('30', 'gwei');

                    if (nft.contract_type == 721) {
                        // Estimate gas for transferFrom
                        const gas = await nftContract.methods.transferFrom(fromAddress, toAddress, tokenId).estimateGas();
                        nftContract.methods.transferFrom(fromAddress, toAddress, tokenId)
                            .send({ from: fromAddress, gas, gasPrice, maxPriorityFeePerGas })
                            .on('transactionHash', (signedTransaction: any) => {
                                console.log('transactionsignedTransaction', signedTransaction);
                                loading1.dismiss();
                                this.addlisting(nft, signedTransaction, connectedAddess, <ChainID>chainid).then((trx)=>{
                                    resolve(trx);
                                });
                            }).on('confirmation', (confirmationNumber: any, receipt: any) => {
                                if (confirmationNumber == 0) {
                                    console.log('First confirmation received');
                                    this.socket.emit('check-listings');
                                }
                                else if (confirmationNumber == 1) this.socket.emit('check-listings');
                            }).on('error', (error: any, receipt: any) => {
                                console.log('ERROR:', error.message);
                                loading1.dismiss();
                                this.showToast({ header: error.message, color: 'warning' });
                            });
                    } else {
                        nftContract.methods.safeTransferFrom(fromAddress, toAddress, tokenId, nft.available.toString(), "0x")
                            .send({ from: fromAddress, gasPrice, maxPriorityFeePerGas })
                            .on('transactionHash', (signedTransaction : any) => {
                                console.log('transactionsignedTransaction', signedTransaction);
                                loading1.dismiss();
                                this.addlisting(nft, signedTransaction, connectedAddess, <ChainID>chainid).then((trx)=>{
                                    resolve(trx);
                                });
                            }).on('confirmation', (confirmationNumber : any, receipt : any) => {
                                if (confirmationNumber == 0) {
                                    console.log('First confirmation received');
                                    this.socket.emit('check-listings');
                                }
                                else if (confirmationNumber == 1) this.socket.emit('check-listings');
                            }).on('error', (error : any, receipt : any) => {
                                console.log('ERROR:', error.message);
                                loading1.dismiss();
                                this.showToast({ header: error.message, color: 'warning' });
                            });
                    }



                } catch (error : MetamaskError | any) {
                    // reject(error)
                    console.log(">>>", error);
                    loading1.dismiss()
                    this.showToast({ header: error.message, color: 'warning' })
                }
            } else {
                reject()
                console.log('MetaMask is not installed');
            }
        })
    }

    addlisting(nft : ShopListing, signedTransaction : string, address : string, chainid : ChainID){
        return new Promise(async (resolve, reject)=>{
            // console.log('adding listing');
            
            // Track pending
            this.pendingHash = signedTransaction
            this.socket.emit('submit-trx', { hash: signedTransaction, address } )
            this.checkTrxStatus()

            const loading2 = await this.load.create({ spinner: 'crescent', message: `Listing...`, cssClass: 'loading-overlay', backdropDismiss: false });
            await loading2.present();

            let newListing : ShopListing = {
                seller : this.auth.user.username,
                seller_address: this.auth.address,
                token_address : nft.token_address,
                token_id : nft.token_id.toString(),
                image : nft.image,
                title : nft.title,
                description : nft.description,
                company : nft.company,
                categories : nft.categories,
                chainid : chainid,
                contract_type : nft.contract_type,
                price : nft.price,
                available : nft.available,
                trx: signedTransaction,
                acctlimit: nft.acctlimit
            }

            let tryCount = 0;
            const maxTries = 5;
            const retryInterval = 3000;
            let complete = false
            let error = undefined

            while(!complete){
                // console.log('submitting...');
                
                let res = await this.contract.pushTransaction({
                    account: 'metakademy',
                    name: 'addlisting',
                    actor: this.auth.user.username,
                    data: newListing
                }).catch(async (err)=>{
                    console.log(err);
                    if (++tryCount === maxTries) {
                        loading2.dismiss();
                        this.system.showToast({ header: `Maximum retries reached...`, color: 'danger' });
                        error = err
                    }
                    else await this.delay(retryInterval)
                });
                if (res) {
                    loading2.dismiss();
                    // console.log('done');
                    complete = true
                    resolve(res);
                }
            }
        })
    }

    addlistingManual(nft : ShopListing){
        return new Promise(async (resolve, reject)=>{
            // console.log('adding listing');
            
            // Track pending
            // this.pendingHash = signedTransaction
            // this.socket.emit('submit-trx', { hash: signedTransaction, address } )
            // this.checkTrxStatus()

            const loading2 = await this.load.create({ spinner: 'crescent', message: `Listing...`, cssClass: 'loading-overlay', backdropDismiss: false });
            await loading2.present();

            let newListing : ShopListing = {
                seller : this.auth.user.username,
                seller_address: this.auth.address,
                token_address : nft.token_address,
                token_id : nft.token_id.toString(),
                image : nft.image,
                title : nft.title,
                description : nft.description,
                company : nft.company,
                categories : nft.categories,
                chainid : nft.chainid,
                contract_type : nft.contract_type,
                price : nft.price,
                available : nft.available,
                trx: '0x',
                acctlimit: nft.acctlimit
            }

            let tryCount = 0;
            const maxTries = 5;
            const retryInterval = 3000;
            let complete = false
            let error = undefined

            while(!complete){
                let res = await this.contract.pushTransaction({
                    account: 'metakademy',
                    name: 'addlisting',
                    actor: this.auth.user.username,
                    data: newListing
                }).catch(async (err)=>{
                    console.log(err);
                    if (++tryCount === maxTries) {
                        loading2.dismiss();
                        this.system.showToast({ header: `Maximum retries reached...`, color: 'danger' });
                        error = err
                    }
                    else await this.delay(retryInterval)
                });
                if (res) {
                    loading2.dismiss();
                    // console.log('done');
                    complete = true

                    setTimeout(()=>{
                        this.socket.emit('check-listings');
                    }, 1000)
                    resolve(res);
                }
            }
        })
    }

    checkTrxStatus(){
        if (this.pendingHash !== undefined){
            setTimeout(()=>{
                // console.log('checkTrxStatus', this.pendingHash);
                this.socket.io!.emit('check-trx', { hash: this.pendingHash })
            }, 1000)
        }
        else {
            setTimeout(()=>{
                // console.log('check trx status with address', this.auth.address);
                this.socket.io!.emit('check-trx', { address: this.auth.address })
            }, 1000)
        }

        this.socket.io!.on(`trx-status`, async (data : { ethTrx : EthTrx | null }) => {
            // console.log('status received', data.ethTrx);
            if (data.ethTrx){
                if (!this.connected) await this.connect()
                if (!this.logSubscription) this.subscribeLogs()
                if (!this.pendingHash) this.pendingHash = data.ethTrx.hash
                
                if (data.ethTrx.status == 'pending' && !this.activePending){
                    this.activePending = true
                    try { await this.toast?.dismiss() } catch(err){}
                    let pendingToast = await this.toast.create({
                        header: 'Metamask Transaction Pending',
                        message: `Submitted: ${data.ethTrx.submitted}`,
                        duration: 0,
                        position: 'bottom',
                        color: 'warning',
                        cssClass: 'my-toast',
                        buttons: [ data.ethTrx!.hash ? {
                            side: 'end',
                            text: 'Copy TRX',
                            handler: () => {
                                this.system.copyClipboard(data.ethTrx!.hash, false)
                            } 
                        }:{},
                        {
                            side: 'end',
                            icon: 'close',
                            role: 'cancel',
                        }]
                    });
                    pendingToast.present();
                }
                else if (data.ethTrx.status == 'complete') {
                    this.activePending = false
                    this.logSubscription?.unsubscribe((error) => {});
                    this.logSubscription = undefined
                    this.pendingHash = undefined

                    try { await this.toast?.dismiss() } catch(err){}

                    if (data.ethTrx.hash != ''){
                        if (data.ethTrx.hash == 'vault'){
                            let doneToast = await this.toast.create({
                                header: 'Manual Vault Listing Received!',
                                message: 'Now pending admin approval',
                                duration: 5000,
                                position: 'bottom',
                                color: 'success',
                                cssClass: 'my-toast',
                            });
                            doneToast.present();
                            this.contract.emit('update-listings')
                        } 
                        else {
                            let buttons : (string | ToastButton)[] = []
                            if (data.ethTrx.chainid){
                                buttons.push({ 
                                    side: 'end', 
                                    text: 'VIEW', 
                                    handler: () => { 
                                        window.open(chain_explorer[data.ethTrx!.chainid!] + `/tx/${data.ethTrx!.hash}`, '_blank') 
                                    } 
                                })
                            }
                            let doneToast = await this.toast.create({
                                header: 'Metamask Transaction Confirmed',
                                message: `Transaction ID: ${data.ethTrx.hash.slice(0, 12)}...`,
                                duration: 5000,
                                position: 'bottom',
                                color: 'success',
                                cssClass: 'my-toast',
                                buttons
                            });
                            doneToast.present();
                            setTimeout(()=>{
                                this.contract.emit('update-listings')
                            }, 2000)
                        }
                    }
                }
                else if (data.ethTrx.status == 'error') {
                    console.log('ERROR');
                    if (data.ethTrx.hash != ''){
                        this.activePending = false
                        this.logSubscription?.unsubscribe((error) => {});
                        this.logSubscription = undefined
                        this.pendingHash = undefined

                        try { await this.toast?.dismiss() } catch(err){}

                        let buttons : (string | ToastButton)[] = []
                        if (data.ethTrx.chainid){
                            buttons.push({ 
                                side: 'end', 
                                text: 'VIEW', 
                                handler: () => {window.open(chain_explorer[data.ethTrx!.chainid!] + `/tx/${data.ethTrx!.hash}`, '_blank') }
                            })
                        }
                        let doneToast = await this.toast.create({
                            header: 'Metamask Transaction Failed',
                            message: `Transaction ID: ${data.ethTrx.hash.slice(0, 12)}...`,
                            duration: 5000,
                            position: 'bottom',
                            color: 'danger',
                            cssClass: 'my-toast',
                            buttons
                        });
                        doneToast.present();
                        // this.activePending = false

                        setTimeout(()=>{
                            this.contract.emit('update-listings')
                        }, 2000)
                    }
                    else {
                        let doneToast = await this.toast.create({
                            header: 'Error creating listing',
                            // message: 'Now pending admin approval',
                            duration: 5000,
                            position: 'bottom',
                            color: 'danger',
                            cssClass: 'my-toast',
                        });
                        doneToast.present();
                        this.contract.emit('update-listings')
                    }
                }
            }
            // else if (data.ethTrxs){
            //     this.pendingHash = Object.keys(data.ethTrxs)[0]

            // }
            else {
                // console.log('null');
                // if (this.pendingToast) this.pendingToast.dismiss()
                this.logSubscription?.unsubscribe((error) => {});
                this.logSubscription = undefined
            }
        })

        this.socket.io!.on(`trx-error`, async (data : { error : any, claimkey?: number}) => {
            this.activePending = false
            // console.log('error received', data);
            try { await this.toast?.dismiss() } catch(err){}
            let errorToast = await this.toast.create({
                header: 'ETH Transation Verification Error - Please try again',
                message: data.error,
                duration: 10000,
                position: 'bottom',
                color: 'danger',
                cssClass: 'my-toast'
            });
            errorToast.present();
        })
    }

    async switchNetwork(chainid: ChainID){
        // console.log('switch', chainid);
        let ethereum = (window as any).ethereum;
        if (typeof ethereum !== 'undefined' && this.web3) {
            try {
                await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x' + chainid.toString(16) }],
                });
                // console.log('top');
            } catch (switchError : any) {
                console.log(switchError);
                if (switchError.code === 4902) {
                    try {
                        await ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainId: '0x' + chainid.toString(16), 
                                chainName:'Polygon Mainnet',
                                rpcUrls:['https://polygon-rpc.com'],                   
                                blockExplorerUrls:['https://polygonscan.com/'],  
                                nativeCurrency: { 
                                    symbol:'MATIC',   
                                    decimals: 18
                                }     
                            }]
                        })
                        console.log('DONE switched');
                        
                    } catch (addError) {
                        console.log(addError);
                    }
                }
            }
        }
    }

    contractType(token_address : string) : Promise<'1155'|'721'>{
		return new Promise((resolve, reject)=>{
		// 	const myContract = new Web3.eth.Contract(ABI_165.abi, token_address);

		// 	myContract.methods.supportsInterface(ERC1155_InterfaceId).call().then((res: any) => {
		// 		if (res === true) resolve('1155')
		// 		// console.log("Is MyContract", token_address, " 1155 - ", res);
		// 	});
	
		// 	myContract.methods.supportsInterface(ERC721_InterfaceId).call().then((res: any) => {
		// 		if (res === true) resolve('721')
		// 		// console.log("Is MyContract", token_address, " 721 - ", res);
		// 	});
		})
	}

	async accountOptions() {
		let ethereum = (window as any)?.ethereum;
		// if (typeof ethereum !== 'undefined') {
		// 	console.log('MetaMask is available');
		// }
		if (ethereum && typeof ethereum !== 'undefined') {
			if (this.auth.address && this.auth.address.length >= 40) {
				const alert = await this.alert.create({
					cssClass: 'my-custom-class',
					header: 'MetaMask Options',
					message: `Link a different account via MetaMask, or Disconnect from MetaMask.<br><br>Linked Address:&nbsp;<span class="courier primary bold">${this.auth.addressShort}</span>`,
					buttons: [
						{
							text: 'Link Different',
							handler: () => { this.editAccounts().then((addr : string)=>{
								localStorage.setItem('address', addr)
								this.auth.emit('new-address-set', addr)
							})}
						}, 
						{
							text: 'Unlink',
							handler: () => { 
								this.contract.unlinkAddr()
							}
						}
					]
				});
				await alert.present();
			}
			else {
				// if (!this.connect.connected) this.connect.connect()
				// else {
					this.editAccounts().then((addr : string)=>{
						localStorage.setItem('address', addr)
						this.auth.emit('new-address-set', addr)
					})
				// }
			}
		}
		else {
			const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Open Metamask',
                message: `This browser doesn't support Metamask. Click to continue with Metamask browser to link you address.`,
                buttons: [{
                    text: 'Continue',
                    handler: () => {
                        let url = 'https://metamask.app.link/dapp/metakademy.com'
                        if (this.auth.user) url = `https://metamask.app.link/dapp/metakademy.com`
                        window.open(url, '_blank')
                    }
                },{
                    text: 'Cancel',
                    role: 'cancel'
                }]
            });
            await alert.present();
		}
    }

    subscribeLogs(address?: string) {
        this.logSubscription = this.web3?.eth.subscribe('newBlockHeaders', (err, blockheader) => {
            // console.log('LOGS', data);
            // if(err) console.log(err);
        }).on('data', (blockHeader) => {
            // console.log(blockHeader);
            this.readBlock(blockHeader.number);
        })
    }

    async readBlock(currentBlock: number) {
        let transactions = await this.web3!.eth.getBlockTransactionCount(currentBlock);
        let currentTransaction = 0;
        while (currentTransaction <= transactions) {
            let tx : EthTrxReceipt = await this.web3!.eth.getTransactionFromBlock(currentBlock, currentTransaction);   
            // console.log('from - ', tx ? tx.from : 'null');  
            if (this._accounts.length > 0){
                if (tx && ((tx.to && tx.to.toLowerCase() == this._accounts[0].toLowerCase()) || (tx.from && tx.from.toLowerCase() == this._accounts[0].toLowerCase())) ) {
                    // console.log("Transaction found", tx);
                    let error = false;
                    const complete = await this.isComplete(tx.hash).catch((err:any) => { error = true; });
                    if(!complete) continue;
                    if(error) continue;

                    if (tx.from.toLowerCase() == this._accounts[0].toLowerCase()){
                        console.log("SUCCESS", tx);
                        this.socket.emit('check-listings')

                        setTimeout(()=>
                            this.socket.emit('check-trx', this.pendingHash !== undefined ? { hash: this.pendingHash } : { address: this._accounts[0].toLowerCase() })
                        , 1000)
                        
                        // if (this.pendingHash !== undefined) {
                        //     // this.socket.emit('complete-trx', { hash: this.pendingHash, receipt: tx })
                        //     this.logSubscription?.unsubscribe((error) => {});
                        //     this.logSubscription = undefined
                        //     this.pendingHash = undefined

                        //     try { await this.toast?.dismiss() } catch(err){}
                        //     let doneToast = await this.toast.create({
                        //         header: 'ETH Transaction Confirmed',
                        //         message: `Transaction ID: ${tx.hash.slice(0, 12)}...`,
                        //         duration: 5000,
                        //         position: 'bottom',
                        //         color: 'success',
                        //         cssClass: 'my-toast',
                        //         buttons: [{ 
                        //             side: 'end', 
                        //             text: 'VIEW', 
                        //             handler: () => { window.open(`https://etherscan.io/tx/${tx.hash}`, '_blank') } 
                        //         }]
                        //     });
                        //     doneToast.present();
                        //     // this.activePending = false

                        //     setTimeout(()=>{
                        //         this.contract.emit('update-listings')
                        //     }, 1000)
                        // }
                    }
                }
            } currentTransaction++;
        }
    }
                
    async isComplete(txHash: string): Promise<boolean> {
        return new Promise((res, rej) => {
            this.web3!.eth.getTransactionReceipt(txHash, (error: Error, receipt: TransactionReceipt) => {
                if(error) rej(error);
                else res(receipt.status);
            });
        })
    }

    async disconnect() {
        this._accounts = [];
        // localStorage.removeItem('web3-provider');
        localStorage.removeItem('address');
        this.auth.emit('remove-address')
    }

    async editAccounts() : Promise<string>{
        return new Promise(async (resolve, reject)=>{
            await (window as any).ethereum.request({
                method: "wallet_requestPermissions",
                params: [{ eth_accounts: {} }]
            }).then((res : any)=>{
                // console.log(res);
                this.connect().then((addr : string)=>{
                    resolve(addr)
                })
            }, (err:any) =>{
                this.showToast({ header: 'Metamask Error:', message: err.message, color: 'warning' })
            });
        })
    }

    getAccounts(){
        // console.log(this.web3?.eth.accounts);
        
        return new Promise(async (resolve, reject) => {
            let provider : any = await detectEthereumProvider();                    
            let web3 = new Web3(provider);
            let accounts = await web3.eth.getAccounts();
            // console.log(accounts);
            resolve(accounts)
        })
    }

    on(event: string) {
        const sub = new Subject();
        if (this.events[event] && this.events[event].length) {
            this.events[event].push(sub);
        } else { this.events[event] = [sub]; }
        return sub;
    }

    private emit(event: string, data?: any) {
        if (this.events[event]) {
            for (const ev of this.events[event]) {
                ev.next(data);
            }
        }
    }

    private weitoeth(weival: string | number): string {
        let wei: string = '0';
        if(typeof weival == 'number') wei = weival + '';
        else wei = weival;

        return this.web3 ? this.web3.utils.fromWei(wei, 'ether') : '0';
    }

    async showToast(data: {
        header: string;
        message?: string;
        icon?: string;
        link?: string;
        linkText?: string;
        duration?: number;
        color?: string;
        close? : boolean;
        external? : string;
    }) {
        let buttons : any[] = []
        if (data.icon) buttons.push({ side: 'start', icon: data.icon })
        if (data.link) buttons.push({ side: 'end', text: data.linkText ? data.linkText : 'VIEW', handler: () => { this.router.navigateByUrl(data.link!) } })
        if (data.close) buttons.push({ side: 'end', icon: 'close-sharp', role: 'cancel' })
        
        if (data.external) buttons.push({ side: 'end', text: data.linkText ? data.linkText : 'VIEW', handler: async () => { 
            let url : any = data.external
            // if ((window as any).Capacitor.isPluginAvailable('Browser')) await Browser.open({ toolbarColor: '#131725', url })
            // else 
            window.open(url, '_blank')
        }})

        // let message = data.message ? `${data.header} : ${data.message}` : `${data.header}`

        // this.pendingTrx = this.snack.open(msg, 'Ok', {
        //     duration: data.duration ? data.duration : 4000,
            
        // })

        const toast = await this.toast.create({
            header: data.header,
            message: data.message,
            duration: data.duration ? data.duration : 4000,
            position: 'bottom',
            color: data.color ? data.color : 'dark',
            cssClass: 'my-toast',
            buttons
        });
        toast.present();
        return toast;
    

        // this.pendingTrx = await this.toast.create({
        //     header: data.header,
        //     message: data.message,
        //     duration: data.duration ? data.duration : undefined,
        //     position: 'bottom',
        //     color: data.color ? data.color : 'dark',
        //     cssClass: 'left-toast',
        //     buttons
        // });
        // this.pendingTrx.present();

        // return this.pendingTrx;
    }

    shortenAddress(address : string) {
        const prefix = address.slice(0, 6);
        const suffix = address.slice(-4);
        return `${prefix}...${suffix}`;
    }

	delay(ms: number) { return new Promise((resolve) => { setTimeout(() => { resolve(true); }, ms) }) }
}


export interface MetamaskError {
    code : number
    message : string
    stack : string
}

export interface EthTrxObj {
    [key: string]: EthTrx
}
export interface EthTrx {
    hash: string
    address: string
    status : 'pending' | 'complete' | 'error'
    submitted: Date
    completed? : Date
    receipt? : EthTrxReceipt
    chainid? : ChainID
}
export interface EthTrxReceipt {
    // blockHash: string
    // blockNumber: number,
    // chainId: string
    // from: string
    // gas: number,
    // gasPrice: string
    // hash: string
    // input: string
    // nonce: number,
    // r: string
    // s: string
    // to: string
    // transactionIndex: number,
    // type: number,
    // v: string
    // value: string

    hash: string;
    nonce: number;
    blockHash: string | null;
    blockNumber: number | null;
    transactionIndex: number | null;
    from: string;
    to: string | null;
    value: string;
    gasPrice: string;
    maxPriorityFeePerGas?: number | string | any;
    maxFeePerGas?: number | string | any;
    gas: number;
    input: string;
}



                    // const transferFrom = nftContract.methods.safeTransferFrom(fromAddress, toAddress, +tokenId, nft.available, "0x")
                    // const gas = await transferFrom.estimateGas({ from: fromAddress });
                    
                    // const data = contract.methods.transferFrom(fromAddress, toAddress, tokenId).encodeABI();
                    // const data = contract.methods.safeTransferFrom(fromAddress, toAddress, tokenId, 1, '0x').encodeABI();
                    // const chain_id : number = await this.web3.eth.getChainId()
                    // const gasPrice = await this.web3.eth.getGasPrice();
                    // const gasLimit = await nftContract.methods.safeTransferFrom(fromAddress, toAddress, +tokenId, nft.available, "0x").estimateGas();
                    // console.log(gasLimit);
                    
                    // const nonce = await this.web3.eth.getTransactionCount(fromAddress);
                    // console.log(contract.methods.transferFrom(fromAddress, toAddress, tokenId));

                    // const rawTransaction = {
                    //     'from': fromAddress,
                    //     'to': toAddress,
                    //     // 'value': '0x00',
                    //     // 'gasPrice': this.web3.utils.toHex(gasPrice),
                    //     // 'gasLimit': this.web3.utils.toHex(gasLimit),
                    //     // 'nonce': this.web3.utils.toHex(nonce),
                    //     'data': data
                    // };
                    // console.log(rawTransaction);