import { User } from "../_services/auth.service"

export const API = 'https://api.metakademy.com/'
// export const API = 'http://localhost:3000/'
// export const API = 'http://51.15.243.17:3000/'
// export const API = 'https://sydneyprod.airwire.io:444/'

export const WIRE_API = 'https://api.airwire.io/'
export const HYPERION : string = "https://hyperwire.airwire.io/"
export const CHAIN : string = "https://swamprod.airwire.io/"

export const AES_KEY : string = "meta-secret"

export const VAULT_ADDRESS = "0x0a149b40f7ba6d5355dE80cB88EDB7829e312474"
// export const VAULT_ADDRESS = "0xFdDd7e391d87d203185D8A2B92D4c98b5c47A744"

// export const IPFS_GATEWAY : string = "https://ipfs.airwire.io/ipfs/"
export const IPFS_GATEWAY : string = "https://ipfs.fuego.airwire.io/"
export const IPFS_SUFFIX : string = "/image.png"

export const METAKADEMY_ACTIVE_PUBLIC : string = "EOS5BMN141GRW49LkaLqwxL11je5LHaREw96G8AudZe2prCbbAiLt"

export const EXPLORER_TRANSACTION : string = "https://local.bloks.io/transaction/"
// export const EXPLORER_TRANSACTION : string = "https://explorer.airwire.io/transaction/"

export const DefaultUser : User = {
    username : '',
    name : '',
    title : '',
    profilePic : '',
    bio : '',
    interests : '',
    favorited : 0,
    favorites : 0,
    numCourses : 0,
    updated : null,
}

export const DEFAULT_SETTINGS : any = {
    dark: true,
}