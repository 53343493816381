import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EthService } from 'src/app/_services/eth.service';
import { MetaGuideFilter, MetaGuideFilterOption, MetaGuideService } from 'src/app/_services/metaguide.service';
import { SystemService, ShopFilterOption } from 'src/app/_services/system.service';

@Component({
  selector: 'app-store-filters',
  templateUrl: './store-filters.component.html',
  styleUrls: ['./store-filters.component.scss'],
})
export class StoreFiltersComponent implements OnInit, AfterViewInit {

    constructor(
		public metaguide : MetaGuideService,
		private system : SystemService,
		public modal : ModalController,
        public eth : EthService ){

		this.metaguide.on('clear-meta-filters').subscribe(()=>{
			document.querySelector('ion-searchbar')?.getInputElement().then((i) => {
				i.value = '';
			});
		})
	}

    ngOnInit(){
		// for (let name of this.system.NFTcategories){
		// 	console.log(name);
		// 	this.filters[1].options.push({ name, checked : false })
			
		// }
	}

	ngAfterViewInit(): void {
		document.getElementById("storeFilter.Chains")?.classList?.add("open");
		document.getElementById("storeFilter.Availability")?.classList?.add("open");
	}

	toggleOpen(filterName : string){
		// this.system.showToast({ header: 'Filters coming soon!', message: 'Almost done :)', color: 'warning'})

		var element = document.getElementById("storeFilter." + filterName);
		element?.classList.toggle("open");
	}

	numFilters(options: ShopFilterOption[]){
		return options.filter((x : ShopFilterOption)=>{ return x.checked }).length
	}

	toggle(e : any, option : ShopFilterOption){
		option.checked = e.detail.checked
        this.eth.emit('update-filters')
		// this.metaguide.getMetaGuideFilters()
	}

	search(e : any){
		this.eth.shopSearch = e.target.value.toLowerCase()
        this.eth.emit('update-filters')
		// this.system.emit('store-search')
		// this.metaguide.getMetaGuideFilters()
	}
}
