<div class="review-card" [class.outline]="outline">
	<div class="flex-start">
		<div class="img-container">
			<ng-container *ngIf="review.profilePic && review.profilePic != ''; else noPic">
				<img [src]="review.profilePic" alt="">
			</ng-container>
			<ng-template #noPic>
				<svg class="pointer zoomIn delay1" width="80" height="80" [data-jdenticon-value]="review.username"></svg>
			</ng-template>
		</div>
		<div>
			<div class="review-card-title">{{ review.name ? review.name : review.username }}</div>
			<div class="review-card-subtitle">{{ review.title ? review.title : 'Metakademy User'}}</div>
			<div class="rating">
				<div class="num">{{ review.rating | number:'1.0-0':'en-US'}}</div>
				<div class="stars">
					<img src="/assets/icons/heart_{{ system.mode }}.png" *ngFor="let x of [].constructor(wholeStars)"> 	
					<img src="/assets/icons/heart_{{ system.mode }}_half.png" *ngIf="halfStar"> 	
				</div>
			</div>
		</div>
	</div>
	
	<div class="review-card-body" *ngIf="review.review && review.review != ''">
		<div class="review-card-bio">
			{{ review.review }}
		</div>
	</div>
	<ng-container *ngIf="review.status === 0">
		<div class="separator"></div>
		<div>
			<div class="flex-start">
				<div class="approve" (click)="course.approveReview(+review.key!, review.course_id)">
					<i class="fa-solid fa-check"></i>
					<ion-label>Approve</ion-label>
				</div>
				<div class="deny" (click)="course.denyReview(+review.key!)">
					<i class="fa-solid fa-xmark"></i>
					<ion-label>Deny</ion-label>
				</div>
				<ion-chip class="zoomIn" (click)="course.openCourseID(review.course_id)">
					<i class="fa-solid fa-eye"></i>
					<ion-label>Course {{ review.course_id }}</ion-label>
				</ion-chip>
			</div>
		</div>
	</ng-container>
</div>