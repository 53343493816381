

<ng-container *ngIf="isProcessing && status">
	<!-- <ion-progress-bar style="width: 100%; margin-top: 6px;" 
	value=".25"
	buffer=".75"
	[color]="isProcessing ? 'success' : 'primary'">
</ion-progress-bar> -->
	<div class="processing" >
		<div class="my-small" style="width: 105px">Processing...</div>

		<ion-progress-bar style="width: 100%; margin-top: 6px;" 
			[value]="status[3]! / statusCount!"
			[buffer]="(status[3] + status[2]) / statusCount"
			[color]="isProcessing ? 'success' : 'primary'">
		</ion-progress-bar>
		<!-- <div class="my-small text-right" style="width: 60px">
			{{status[0]}}/{{status[1]}}/{{status[2]}}/{{status[3]}}
		</div> -->
	</div>
</ng-container>

<div class="h-100 w-100" oncontextmenu="return false;">
	<video 
		[id]="lecture.lecture_id" 
		#videoPlayer
		controls autoplay
		playsinline=""
		controlsList="nodownload"
		style="height: 100%; width: 100%; margin-bottom: -6px" 
		[class.borderBottom]="rewarded"
		>
	</video>

	<ion-progress-bar
		*ngIf="!rewarded && auth.user"
		style="border-radius: 0px 0px 10px 10px; height: 5px"
		[color]="'success'" 
		[value]="progress">
	</ion-progress-bar>
</div>

