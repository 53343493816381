import { Component, Input, OnInit } from '@angular/core';
import { SystemService } from '../../_services/system.service';
import { ConnectService } from "../../_services/connect.service";
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/_services/auth.service';
import { CourseService, Review } from 'src/app/_services/course.service';

@Component({
    selector: 'app-review',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss'],
})
export class ReviewModalComponent implements OnInit {
	@Input() course_id! : number
	@Input() metaguide! : string

    review? : string
    rating : number = 0

    get canSubmit(){ return this.rating > 0 }

    constructor(
        public connect: ConnectService,
        private alert : AlertController,
        private load : LoadingController,
        public auth : AuthService,
        private course : CourseService,
        public modal : ModalController,
        public system: SystemService ) {
     }

    ngOnInit() {
        // console.log(this.course_id);
        // console.log(this.metaguide);
        
    }


    select(x : number){
        this.rating = x
    }

    async confirm() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Submit Review',
            message: `Are you sure you want to submit this review? It must be approved by Metakademy Admin before it displays on the main site.`,
            buttons: [{
                text: 'Submit',
                handler: () => this.submit()
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }

    async submit(){
        const loading = await this.load.create({ spinner: 'crescent', message: `Submitting...`, cssClass: 'loading-overlay', backdropDismiss: false });
		await loading.present();

        let review : Review = {
            course_id : this.course_id,
            metaguide : this.metaguide,
            rating : this.rating,
        }

        if (this.review) review.review = this.review
        if (this.auth.user.profilePic) review.profilePic = this.auth.user.profilePic
        if (this.auth.user.title) review.title = this.auth.user.title
        if (this.auth.user.name) review.name = this.auth.user.name

		this.course.addReview(review).then(res =>{
			loading.dismiss()
			this.system.showToast({ header: 'Review Submitted!', message: 'Once approved by an admin, your review will show up here.', color: 'success', duration: 5000})
            this.modal.dismiss(true)
		}, err => {
			loading.dismiss()
			this.system.showToast({ header: 'Error submitting review...', message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
		})
    }

}

