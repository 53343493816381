<ion-content style="height: 100%; ">
	<ion-icon color="primary" size="large" (click)="modal.dismiss()" name="close-outline" class="fixed-top-right pointer"></ion-icon>

	<div class="flex-center" style="margin: 1.5rem;">
		<div class="block " >
			<h2 class="flex-center purple mb-4 mt-3">Apply to Become a MetaGuide</h2>

			<div>
				<p>
					Metakademy is a collection of expert courses curated by the Metakey community for the next generation of Web 3 creators. It's our mission to help fellow explorers unlock their potential with thoughtfully curated content-helping them navigate new frontiers with your expertise and guidance.  
				</p>
				<p>
					Each course will be made up of approximately 5 videos of roughly 20 minutes in length - ideally scripted and with appropriate visual aids.
				</p>
				<p>
					MetaGuides will also be required to submit their script/notes, as well as a raw recording/edit to be reviewed before final approval of each video is granted.
				</p>
				<p>
					MetaGuides will be compensated - compensation can be negotiated based on the estimated workload of each course. Metakey staff can help with editing, recording and whatever else you might need as appropriate and as our bandwidth allows.
				</p>
				<p>
					Please fill in the below form and we'll get back to you for a follow-up as soon as possible. 
				</p>
			</div>



			<div class="separator"></div>
			
			
			<div class="my-input">
				<div class="my-small">Why do you want to become a MetaGuide?</div>
				<textarea type="text" class="mb-3" [(ngModel)]="question1"></textarea>
			</div>
			<div class="my-input">
				<div class="my-small">What would you like to offer a course on?</div>
				<textarea type="text" class="mb-3" [(ngModel)]="question2"></textarea>
			</div>
			<div class="my-input">
				<div class="my-small">What experience or qualifications do you have in this subject or content? This may be formal qualifications and/or demonstrable experience.</div>
				<textarea type="text" class="mb-3" [(ngModel)]="question3"></textarea>
			</div>
			<div class="my-input">
				<div class="my-small">Any example of previous courses and/or Web3 involvement? (Videos, Links, Copywriting, Articles, Blogs)</div>
				<textarea type="text" class="mb-3" [(ngModel)]="question4"></textarea>
			</div>
			<div class="my-input">
				<div class="my-small">How would you like to be contacted? (Please provide email/contact details)</div>
				<textarea type="text" class="mb-3" [(ngModel)]="question5"></textarea>
			</div>
			
			

			<!-- <ion-item>
				<ion-textarea [(ngModel)]="question1"></ion-textarea>
			</ion-item>
			<ion-item>
				<ion-textarea [(ngModel)]="question2"></ion-textarea>
			</ion-item>
			<ion-item>
				<ion-textarea [(ngModel)]="question3"></ion-textarea>
			</ion-item>
			<ion-item>
				<ion-textarea [(ngModel)]="question4"></ion-textarea>
			</ion-item>
			<ion-item>
				<ion-textarea [(ngModel)]="question5"></ion-textarea>
			</ion-item> -->


			<!-- <div class="my-input">
				<textarea type="text" placeholder="Review" class="mt-3" [(ngModel)]="review"></textarea>
			</div> -->

			<div class="block mb-3">
				<div class="flex-center">
					<div class="btn-main item-shadow mt-4" (click)="confirm()" [class.disabled]="!canSubmit">
						Submit 
					</div>
				</div>
			</div>
		</div>
	</div>
</ion-content>
