import { Injectable } from "@angular/core";
import { ContractService, GetRowData } from "./contract.service";
import Web3 from "web3";
import { ConnectService } from "./connect.service";
import { AuthService } from "./auth.service";
import { Subject } from "rxjs";

export const chain_name = {
    1 : 'eth',
    11155111 : 'eth',
    137 : 'matic',
	80001 : 'matic'
}

@Injectable()
export class EthService  {
    private events: any = {};

    public shopFilters : any[] = [
        {   title: 'Chains',
            options: [
                { name : 'ETH',	  checked: false },
                { name : 'Polygon',  checked: false },
            ]
        },
        {   title: 'Availability',
            options: [
                { name : 'Available',	  checked: false },
                // { name : 'Some Claimed',  checked: false },
                // { name : 'All Claimed',  checked: false },
                { name : 'Can Afford',  checked: false },
            ]
        },
        {   title: 'Categories',
            options: [
                { name : 'NFTs',	  checked: false },
                { name : 'Services',  checked: false },
                { name : 'Discounts', checked: false },
                { name : 'Tokens',	  checked: false },
                { name : 'Wire Network', checked: false }
            ]
        },
        {   title: 'Points',
            options: [
                { name : '0 - 500',	      checked: false },
                { name : '500 - 1,000',   checked: false },
                { name : '1,000 - 2,000', checked: false },
                { name : '2,000 - 3,000', checked: false },
                { name : '3,000 - 4,000', checked: false },
                { name : '4,000+',		  checked: false }
            ]
        }
    ]
	public shopSearch? : string 

    constructor(
        private auth : AuthService,
        private connect : ConnectService,
        private contract : ContractService){

    }

    // getShopListings(status? : number): Promise<ShopListing[]>{
    //     return new Promise((resolve, reject) => {
    //         let getListings  = async (lower_bound? : string) : Promise<ShopListing[]> => {
    //             let shopRow = await this.contract.getRows<ShopListing>({
    //                 table: 'listings',
    //                 lower_bound: lower_bound ? lower_bound! : undefined
    //             })
    //             let listings = shopRow.rows
    //             if (shopRow && shopRow.more && shopRow.next_key) {
    //                 let moreListings = await getListings(shopRow.next_key)
    //                 return moreListings.concat(listings)
    //             }   else return listings
    //         }
    //         getListings().then(async (listings : ShopListing[])=>{
    //             if (status || status === 0) listings = listings.filter((x : ShopListing)=>{ return x.status === status })
    //             resolve(listings) 
    //         })
    //     })
    // }
    getShopFilters(balance? : number): Promise<ShopListing[]>{
        return new Promise((resolve, reject) => {
            this.contract.getRows<ShopListing>({ 
                contract: 'metakademy', 
                table: 'listings',
                key_type : 'i64',
                index_position : 2,
                lower_bound : 3,
                upper_bound : 3,
                reverse: true
            }).then((data)=>{
                if (data.rows){
                    //FILTER 
                    let temp : ShopListing[] = data.rows
                    for (let filter of this.shopFilters){
                        switch(filter.title){
                            case 'Chains':
                                if (filter.options[0].checked ^ filter.options[1].checked){
                                    if (filter.options[0].checked) temp = temp.filter((x)=>{ return x.chainid == 1 })
                                    if (filter.options[1].checked) temp = temp.filter((x)=>{ return x.chainid == 137 })
                                }
                                break;

                            case 'Availability':
                                if (filter.options[0].checked) temp = temp.filter((x)=>{ return x.claimed! < x.available })
                                if (filter.options[1].checked && (balance || balance === 0)) temp = temp.filter((x)=>{ return x.price! < balance })
                                break;

                            case 'Categories':
                                const checkedOptions = filter.options
                                    .filter((option:any) => option.checked)
                                    .map((option:any) => option.name); 
                                    
                                if (checkedOptions.length) temp = temp.filter((x : ShopListing)=>{ return x.categories.split(',').some(str => checkedOptions.includes(str)); })
                                break;

                            case 'Points':
                                const filteredOptions = filter.options.filter((option:any) => option.checked);
                                if (filteredOptions.length > 0) {
                                    temp = temp.filter(item => {
                                        const matchingOption = filteredOptions.find((option:any) => {
                                            const [minPrice, maxPrice] = option.name.split(' - ').map((range:any) => Number(range.replace(',', '')));
                                            return item.price! >= minPrice && item.price! <= maxPrice;
                                        });
                                        return !!matchingOption;
                                    });
                                }
                                break;
                        }
                    }
                    if (this.shopSearch && this.shopSearch != '') temp = temp.filter((x)=>{ 
                        return x.title.toLowerCase().includes(this.shopSearch!) 
                            || x.description.toLowerCase().includes(this.shopSearch!) 
                            || x.company.toLowerCase().includes(this.shopSearch!) 
                        })
                    // console.log(temp);
                    resolve(temp)
                }
                else resolve([])
            })
        })
    }
    getShopListings(status? : number): Promise<ShopListing[]>{
        return new Promise((resolve, reject) => {
            let data : any = { contract: 'metakademy', table: 'listings' }
            if (status) {
                data.key_type = 'i64',
                data.index_position = 2,
                data.lower_bound = status,
                data.upper_bound = status
            }

            this.contract.getRows<ShopListing>(data).then((data)=>{
                resolve(data.rows ? data.rows : [])
            })

            // let getListings  = async (lower_bound? : string) : Promise<ShopListing[]> => {
            //     let shopRow = await this.contract.getRows<ShopListing>({
            //         table: 'listings',
            //         lower_bound: lower_bound ? lower_bound! : undefined
            //     })
            //     let listings = shopRow.rows
            //     if (shopRow && shopRow.more && shopRow.next_key) {
            //         let moreListings = await getListings(shopRow.next_key)
            //         return moreListings.concat(listings)
            //     }   else return listings
            // }
            // getListings().then(async (listings : ShopListing[])=>{
            //     if (status || status === 0) listings = listings.filter((x : ShopListing)=>{ return x.status === status })
            //     resolve(listings) 
            // })
        })
    }
    getUserListings(user? : string): Promise<ShopListing[]>{
        return new Promise((resolve, reject) => {
            if (this.auth.user || user){
                if (!user) user = this.auth.user.username
                this.contract.getRows<ShopListing>({
                    contract: 'metakademy',
                    table: 'listings',
                    key_type : 'name',
                    index_position : 3,
                    lower_bound : user,
                    upper_bound : user
                }).then((data)=>{
                    resolve(data.rows ? data.rows : [])
                })
            }
        })
    }

    getPendingClaims(isMetaguide: boolean, user? : string): Promise<ShopClaim[]>{
        return new Promise((resolve, reject) => {
            if (this.auth.user || user){
                if (!user) user = this.auth.user.username
                this.contract.getRows<ShopClaim>({
                    contract: 'metakademy',
                    table: 'claims',
                    key_type : 'name',
                    index_position : isMetaguide ? 5 : 4,
                    lower_bound : user,
                    upper_bound : user
                }).then((data)=>{
                    resolve(data.rows ? data.rows : [])
                })
            }
        })
    }

    clearFilters(){
        this.shopFilters.forEach(filter => {
            filter.options.forEach((option:any) => {
                option.checked = false;
            });
        });
        this.shopSearch = undefined
        this.emit('update-filters')
    }

    delay(ms: number) { return new Promise((resolve) => { setTimeout(() => { resolve(true); }, ms) }) }

    on(event: string) : any {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)

        else this.events[event] = [sub]
        return sub
    }
    emit(event: string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

// export interface EthNft {
//     image: string,
//     title: string,
//     company: string,
//     categories: string,
//     points: number | undefined,
//     available: number,
//     claimed: number,
//     token_address: string,
//     token_id: number,
//     description?: string
// }

export interface ShopListing {
    key? : number
    status? : 0 | 1 | 2 | 3 | 4 | 5
            // 0 = draft
            // 1 = sending to contract
            // 2 = pending approval
            // 3 = approved / active
            // 4 = denied - trigger return to seller?
            // 5 = error transferring to vault
    added?: string
    available: number
    categories: string
    claimed?: number
    company: string
    description: string
    image: string
    price?: number
    seller: string
    seller_address: string
    title: string
    contract_type: ContractType
    chainid: ChainID
    token_address: string
    token_id: string
    trx?: string
    gas? : number
    submitted? : string
    received? : string
    approved? : string
    acctlimit : number
}

export interface ShopClaim {
    claimkey: number
    listingkey: number
    buyer: string
    seller: string
    buyer_address: string
    seller_address: string
    token_address: string
    token_id: number
    chainid : number
	contract_type : 1155 | 721
    image: string
    title: string
    price: number
    status: 0 | 1 | 2 | 3 
		// 0: pending claim, REWARDS sent
		// 1: transferring NFT from Vault to Buyer
		// 2: completed, NFT transferred
		// 3: error, error with transfer, refund REWARDS?
    added: string
    submitted : any
    completed: string
    trx: string
	gas: number
    error? : string
}

export type ChainID = 1 | 11155111 | 137 | 80001
export type ContractType = 1155 | 721