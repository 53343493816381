import { Injectable } from "@angular/core";
import { API } from "../_constants/constants";
import { io, Socket, SocketOptions } from 'socket.io-client';
import { AuthService } from "./auth.service";
import { EthTrx } from "./connect.service";
import { SystemService } from "./system.service";
import { ToastController } from "@ionic/angular";
import { Subject } from "rxjs";
import { ContractService } from "./contract.service";

@Injectable()
export class SocketService {
    connected: boolean = false;
    signature? : string

    public io? : Socket;
    public roomId = '';

    pendingToast? : HTMLIonToastElement

    private events : any = {};


    constructor(
        private auth : AuthService,
        private toast: ToastController,
        public system : SystemService,
        public contract : ContractService
        ){ 
        // this.io = io(API); 

        if (this.auth.user) {
            this.auth.generateSignature().then((signature)=>{
                this.signature = signature
                this.io = io(API, { 
                    auth: { username: this.auth.user.username, signature },
                    reconnection: true,
                });
                this.init()
            })
        } 
        // else this.io = io(API); 

        this.auth.on('login').subscribe((username : any)=>{
            // setTimeout(()=>{ 
                this.auth.generateSignature().then((signature)=>{
                    this.signature = signature
                    this.io = io(API, { auth: { username, signature } });
                    this.init()
                    // setTimeout(()=>{ this.emit('check-listings')}, 500)
                })
            // }, 500)
        })
        this.auth.on('logout').subscribe(()=>{
            setTimeout(()=>{
                // console.log('logout');
                // this.io?.emit('logout')
                this.io?.close()
            }, 500)
        })

        this.auth.on('check-claims').subscribe(()=>{ 
            console.log('check claims received');
            this.emit('check-claims') 
        })
        this.auth.on('check-listings').subscribe(()=>{ 
            console.log('check listings received');
            
            this.emit('check-listings') 
        })
    }

    init(){
        this.localEmit('ready')
        this.initListeners()
        this.checkTrxStatus()

        setTimeout(()=>{
            this.emit('check-claims')
            this.emit('check-listings')

        }, 1000)
    }

    initListeners(){
        if (this.io){
            // console.log('initted socket listeners');
            this.io!.on(`error`, async (data : { header : string, message : string }) => {
                console.log('error received', data);
                this.system.showToast({ header: data.header, message: data.message, color: 'danger', duration: 8000, icon: 'alert-circle' })

                setTimeout(()=>{
                    this.contract.emit('update-listings')
                }, 1000)
            })

            this.io!.on(`claim-success`, async (data : { header : string, message : string, link? : string }) => {
                console.log('claim-success', data);
                this.system.showToast({ 
                    header: data.header, 
                    message: data.message, 
                    color: 'success', 
                    duration: 10000, 
                    icon: 'download', 
                    link: data.link ? data.link : undefined, 
                    linkText: data.link ? 'View NFT' : undefined 
                })

                setTimeout(()=>{
                    this.contract.emit('update-listings')
                }, 1000)
            })
        }
    }
    
    checkTrxStatus(){
        this.auth.emit('socket-init')
    }

    async emit(msg : string, data?: any){
        if (this.io){
            if (data) this.io.emit(msg, data)
            else this.io.emit(msg)
        }
        // if (this.auth.user){
        //     if (!this.signature) this.signature = await this.auth.generateSignature()
        //     if (!data) data = {}
        //     data.username = this.auth.user.username
        //     data.signature = this.signature
        //     this.io!.emit(msg, data)
        // }
    }

    localOn(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    localEmit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}