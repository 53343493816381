import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { API } from "../_constants/constants";
import { ContractService } from './contract.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { ChainID, ContractType } from './eth.service';

// import { initializeApp, FirebaseApp } from 'firebase/app';
// import { Database, getDatabase, onChildAdded, ref, query, onValue, limitToFirst, limitToLast, orderByChild, orderByValue, equalTo, QueryConstraint } from "firebase/database";
// import { Firestore, getFirestore, doc, getDoc, getDocs, collection, query as fsquery, where, orderBy, limit, startAfter, QueryConstraint as FQueryConstraint, QueryDocumentSnapshot, DocumentData } from "firebase/firestore";

const httpOptions = {
    headers: new HttpHeaders ({
        'Access-Control-Allow-Origin': '*',
        // 'Content-Type': '*/*',
        'Accept': '*/*'
    })
}


@Injectable()
export class NftService implements OnInit {
    private events : any = {};

        get address(): string {
        let a = localStorage.getItem('address')
        return a ? a : ''
    }

    constructor(
        private contract : ContractService,
        private http : HttpClient, 
        private sanitizer: DomSanitizer ){
        }

    ngOnInit(){
    }

    verifyNFT(token_address : string, token_id : string): Promise<{ nft : EthAsset, holding: number, contractType : ContractType, chainID : ChainID }>{
        return new Promise((resolve, reject)=>{
            this.http.get(`${API}/verifyNFT`, { params: { token_address, token_id: token_id.toString(), user_address: this.address } }).subscribe((res : any)=>{
                // res.holding = 1000000000000000000
                resolve(res)
            }, err => {
                reject(err)
            })
        });
    }
    verifyNFTvault(token_address : string, token_id : string): Promise<{ nft : EthAsset, holding: number, contractType : ContractType, chainID : ChainID }>{
        return new Promise((resolve, reject)=>{
            this.http.get(`${API}/verifyNFTvault`, { params: { token_address, token_id: token_id.toString() } }).subscribe((res : any)=>{
                // res.holding = 1000
                resolve(res)
            }, err => {
                reject(err)
            })
        });
    }

    getEthNFTs(address : string, offset? : number, limit? : number, chain? : string) : Promise<EthAsset[]>{
        let params: any = { address }
        if(offset) params.offset = offset;
        if(limit) params.limit = limit;
        if(chain) params.chain = chain;
        return new Promise((resolve, reject)=>{
            this.http.get(`${API}/eth_nfts`, { params }).subscribe((res : any)=>{
                resolve(res)
            })
        });
    }

    getEthNFT(token_address : string, token_id : string) : Promise<EthAsset>{
        return new Promise((resolve, reject)=>{
            this.http.get(`${API}/eth_nft`, { params: { token_address, token_id } }).subscribe((res : any)=>{
                resolve(res)
            })
        });
    }

    getEthCollection(address : string){
        return new Promise((resolve, reject)=>{
            this.http.get(`${API}/eth_collection`, { params: { address } }).subscribe((res : any)=>{
                resolve(res)
            })
        });
    }

    getEthCollectionNfts(address : string, offset?: number, limit? : number, chain? : string): Promise<MoralisNFTResult>{
        return new Promise((resolve, reject)=>{
            let params: any = { 
                address,
            }
            if(offset) params.offset = offset;
            if(limit) params.limit = limit;
            if(chain) params.chain = chain;

            this.http.get(`${API}/eth_collection_nfts`, { params }).subscribe((res : any)=>{
                resolve(res)
            })
        });
    }

    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export interface Collection {
    collection_name: string
    author: string
    allow_notify: boolean
    authorized_accounts: string[]
    notify_accounts: string[]
    market_fee: number
    serialized_data: any
    data: any
}

export interface Schema {
    schema_name : string
    format : any[]
}

export interface NewSchema {
    authorized_creator : string
    collection_name : string
    schema_name : string
    schema_format : any[]
}

export interface Asset {
    asset_id: string
    schema_name: string
    collection_name: string
    encrypted: number
    minter: string
    ram_payer: string
    immutable_serialized_data: any
    mutable_serialized_data: any[]
    data? : {
        [key:string]: any
    }
    royalties: any[]
    backed_tokens: any[]
    mint_index: number
    edition_id: number
    owner? : string
    sale? : any
    schema_format : any
    minted? : any
    file? : string
    thumbnail? : string;
    max_supply? : number
    auction? : Auction | any
}

export interface AssetData {
    asset_id? : string
    authorized_minter: string
    collection_name : string
    schema_name : string
    encrypted: number
    edition_id : number
    royalties: any[],
    immutable_data : any,
    mutable_data: any[],
    tokens_to_back: any[]
}

export interface EthAsset {
    amount: string
    block_number: string
    block_number_minted: string
    contract_type: string
    metadata: any
    name: string
    owner_of: string
    symbol: string
    synced_at: string
    token_address: string
    token_id: string
    token_uri: string
    chain? : string
}

export interface Account {
    user: string
    profileImgURL : string
    data : any
    created : any
}

export interface AssetQuery {
    sale?: boolean;
    orderBy?: 'mint' | 'price';
    orderDir?: 'DESC' | 'ASC';
    limit?: number;
    filter_collections?: string[];
    filter_owners?: string[];
    asset_ids?: string[];
}

export interface AssetsResult {
    assets: Asset[],
    // last: QueryDocumentSnapshot<DocumentData> | undefined
}

export interface Query {
    where? : string
    sortBy? : string
    orderBy? : string
    showBy? : string
    limit? : number
    offset? : number
    search? : string
}

export interface MoralisNFTResult {
    total: number;
    page: number;
    page_size: string;
    result: MoralisNFT[]
}

export interface MoralisNFT {
    token_address: string,
    token_id: string,
    contract_type: string,
    token_uri: string,
    metadata: any,
    synced_at: string,
    amount: string,
    name: string,
    symbol: string,
    block_number: any, 
    block_number_minted: any,
    owner_of: any,
    chain?: any
}

export interface Pool {
    pool_id: string
    creator: string
    group: string | undefined
    claim_limit: number
    random: number
    card_image: string
    claim_till : string;
    asset_count: number;
}

export interface AssetSale {
    forSale: true;
    sale_id: string;
    seller: string;
    asset_id: string;
    offer_id: string;
    listing_price: string;
    settlement_symbol: string;
    collection_fee: number;
    asset_royalties: Royalty[];
}

export interface SaleDictionary {
    [key:string]: AssetSale
}

export interface Royalty {
    fee: string;
    user: string;
}

export interface FBAsset {
    asset_id: string;
    collection_name: string;
    data: {
        file: string;
        name: string;
        [key:string]: any;
    }
    edition_id: number;
    encrypted: number;
    forsalelisting? : number;
    forsaleminted? : number
    max_supply? : number
    mint_index: number;
    minted?: any;
    minter: string;
    owner: string;
    royalties: Royalty[];
    sale?: AssetSale;
    schema_name: string;
    thumbnail? : string
    file? : string
    auction_id?: number
}

export interface UpdateOwnerData {
    data: {
        new_owner: string; 
        asset_id: any; 
    }[] | {
        new_owner: string; 
        asset_id: any; 
    }
    noCancel? : boolean
}

export interface Auction {
    auction_id: number,
    seller: string,
    asset_id: string,
    end_time: number,
    asset_transferred: number,
    current_bid: string,
    current_bidder: string,
    claimed_by_seller: number,
    claimed_by_buyer: number,
    maker_marketplace: 'airwire',
    taker_marketplace: 'airwire',
    collection_name: string,
    collection_fee: number,
    asset_royalties: Royalty[]
    file? : any
    bids? : Bid[]
    created? : string | Date | any
    name? : string
}

export interface Bid {
    bidder : string,
    amount : number,
    date : string | Date | any
    trx_id : string
}

export interface ChainUsersAssetsOptions {
    ids?: string[];
    limit?: number;
    next_key?: string;
}

interface ChainUsersAssetsResult {
    assets: Asset[];
    next_key?: string;
}

interface ChainAsset {
    asset_id: string;
    owner?: string;
    collection_name: string;
    schema_name: string;
    edition_id: number;
    mint_index: number;
    minter: string;
    royalties: Royalty[];
    encrypted: 0 | 1;
    ram_payer: string;
    backed_tokens: any[];
    immutable_serialized_data: number[];
    mutable_serialized_data : number[];
    data?: any;
}

interface ChainColleciton {
    collection_name: string;
    author: string;
    allow_notify: number;
    authorized_accounts: string[];
    notify_accounts: string[];
    market_fee: string | number;
    serialized_data: number[];
    collection_data?: {
        name: string;
        file: string;
        description: string;
        url: string;
    };
    schemas?: { [key:string]: Array<SchemaEntry> }
}

interface ChainSchemas {
    schema_name: string;
    format: Array<SchemaEntry>
}

interface SchemaEntry {
    name: string;
    type:string;
}

export interface StringDictionary {
    [key : string] : string
}