<ion-content>
	<ion-icon color="primary" size="large" (click)="modal.dismiss()" name="close-outline" class="fixed-top-right pointer"></ion-icon>

    <div class="container mt-5 mb-5 text-center" style="width: 90%;">
		<h2 class="flex-center purple mb-4">Reset Password</h2>

		<ion-item style="margin-bottom: 1rem;">
			<ion-label position="floating">Account Username</ion-label>
			<ion-input ngDefaultControl [(ngModel)]="username" autocomplete="username" ></ion-input>
			<div class="icon-container" slot="end">
				<ion-icon name="person-circle-outline" class="gray" style="font-size: 25px"></ion-icon>
			</div>
		</ion-item>
		<div class="flex-center">
			<div class="btn-main item-shadow" (click)="checkVerified()" [class.disabled]="loadingCheck || !username">
				Continue
			</div>
		</div>

		<div *ngIf="loadingCheck" class="w-100 flex-center mt-2">
			<ion-spinner name="dots" color="primary" size="large" style="font-size: 30px;"></ion-spinner>
		</div>

		<ng-container *ngIf="checked">
			<ng-container *ngIf="(phone || email) && !loadingCheck && !error">
				<div class="separator"></div>

				<h5 class="text-center zoomIn ">Reset password via:</h5>
				<div class="flex-center zoomIn delay2`">
					<div class="btn-main item-shadow" (click)="sendEmail()" [class.disabled]="sendingCode || codeSent">
						<ion-icon name="mail-outline" style="font-size: 23px; margin-right: 1rem;"></ion-icon>
						Email
					</div>
				</div>
				<div class="flex-center mt-3 " *ngIf="codeSent">
					<ion-chip outline color="dark" class=" " (click)="resend()">
						<ion-label>Resend New Code</ion-label>
					</ion-chip>
				</div>
			</ng-container>

			<ng-container *ngIf="error ">
				<div class="my-alert danger mt-3 mb-3 zoomIn">Error: {{ error }}</div>
			</ng-container>

			<div *ngIf="sendingCode" class="w-100 flex-center mt-3 zoomIn">
				<div class="primary">Sending code</div>
				<ion-spinner name="dots" color="primary" size="large" style="font-size: 30px;" class="ml-2"></ion-spinner>
			</div>

			<ng-container *ngIf="!sendingCode && codeSent">
				<div class="separator"></div>

				<ion-item style="margin-bottom: 1rem;">
					<ion-label position="floating">Verification Code</ion-label>
					<ion-input type="tel" ngDefaultControl [(ngModel)]="code" maxlength="6"></ion-input>
					<div class="icon-container" slot="end">
						<ion-icon name="keypad-outline" class="gray" slot="end" style="font-size: 25px"></ion-icon>
					</div>
				</ion-item>

				<div class="flex-center zoomIn">
					<div class="btn-main item-shadow" (click)="checkCode()" [class.disabled]="!code || code.length != 6 || checkingCode">
						Verify
					</div>
				</div>

				<div *ngIf="checkingCode" class="w-100 flex-center mt-3 zoomIn">
					<small class="primary">Checking code</small>
					<ion-spinner name="dots" color="primary" size="large" style="font-size: 30px;" class="ml-2"></ion-spinner>
				</div>

				<ng-container *ngIf="!checkingCode && checkedCode">
					<div class="separator"></div>
					<h3 *ngIf="verifiedCode" class="green text-center bold zoomIn">VERIFIED!</h3>
					<h3 *ngIf="!verifiedCode" class="red text-center bold zoomIn">Incorrect Code</h3>
				</ng-container>

				<ng-container *ngIf="verifiedCode">
					<div class="separator"></div>

					<div class="text-center mb-3">
						<span class="text-color">Create a new password. Must contain at least one:</span><br>
						<span [ngClass]="checkUpper ? 'green' : 'red'">uppercase letter,&nbsp;</span>
						<span [ngClass]="checkLower ? 'green' : 'red'">lowercase letter,&nbsp;</span>
						<span [ngClass]="checkNumber ? 'green' : 'red'">number,&nbsp;</span>
						<span [ngClass]="checkSpecial ? 'green' : 'red'">special character</span>
					</div>

					<ion-item >
						<ion-label position="floating">New Password</ion-label>
						<ion-input ngDefaultControl [(ngModel)]="password" [type]="passwordType" id="new_password" (ionChange)="checkPassword($event)" autocomplete="password"></ion-input>
						<div class="icon-container" slot="end">
							<ion-icon slot="end" class="gray pointer" [name]="passwordIcon" style="font-size: 25px" (click)="togglePassword()"></ion-icon>
						</div>
					</ion-item>
					<small class="red" *ngIf="password && !valid_password">Requirements not met</small>

					<ion-item style="margin-top: 1rem;">
						<ion-label position="floating">Confirm New Password</ion-label>
						<ion-input ngDefaultControl [(ngModel)]="password_confirm" [type]="passwordType" id="new_password"></ion-input>
						<div class="icon-container" slot="end">
							<ion-icon slot="end" class="gray pointer" [name]="passwordIcon" style="font-size: 25px" (click)="togglePassword()"></ion-icon>
						</div>
					</ion-item>
					<small class="red zoomIn" *ngIf="password && password_confirm && password != password_confirm">
						Passwords don't match
					</small>

					<ng-container *ngIf="valid_password && password && password_confirm && password == password_confirm">
						<div class="flex-center zoomIn" style="margin-top: 1.5rem;">
							<div class="btn-main item-shadow" (click)="confirmChange()" [class.disabled]="!code || code.length != 6 || checkingCode">
								Change Password
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
    </div>
</ion-content>

