import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AuthService } from './_services/auth.service';
import { EthTrx } from './_services/connect.service';
import { ContractService } from './_services/contract.service';
import { SocketService } from './_services/socket.service';
import { SystemService } from './_services/system.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    
    constructor(
        public auth : AuthService,
        private system : SystemService,
        private socket : SocketService,
        private toast : ToastController,
        private contract : ContractService ){

            this.init()

            this.auth.on('login').subscribe(()=>{ this.init() })

            // this.socket.localOn('initted').subscribe(()=>{
            //     console.log('initted');
                
            //     this.socket.io!.on(`error`, async (data : { header : string, message : string }) => {
            //         console.log('error received', data);
            //     })
            // })
        }

    async init(){

        if (this.auth.user){
            this.auth.verifyUsernameKey().then((res)=>{
                // this.socket.emit('auth')
                // setTimeout(()=>{
                    // this.socket.emit('auth', { username: this.auth.user.username })
                // }, 500)
                // this.auth.generateSignature().then((signature)=>{
                //     this.socket.io.emit('auth', {  username: this.auth.user.username, signature })
                // })
            }, err => {
                // console.log(err);
                
                // this.system.showToast({ header: 'Cannot verify credentials', message: 'Please log back', color: 'danger', linkText: "Login"})
                // this.auth.logoutHard()
            })
        }
    }
}
