<ion-header  >
	<ion-toolbar style="--background-color: none !important; ">
		<ion-title slot="start">
            {{ nft.title }}
        </ion-title>

		<ion-buttons slot="end">
            <ion-icon color="primary" size="large" (click)="modal.dismiss()" style="margin-right: 5px" name="close-outline" class="pointer"></ion-icon>
        </ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content id="listing">
	<!-- <ion-icon color="primary" size="large" (click)="modal.dismiss()" name="close-outline" class="fixed-top-right pointer"></ion-icon> -->

	<div class="listing">
		<div class="top">

			<div class="flex-center">
				<img [src]="nft.image" alt="">
			</div>
			<!-- <h3 class="mt-3 text-center">{{ nft.title }}</h3> -->
			<!-- <h6 class="text-color-2 text-center">{{ nft.company }}</h6> -->
			<p class="text-center mb-0 mt-3" [innerHTML]="system.linkify(nft.description)"></p>
		</div>

		<ion-list lines="full" style="background: none !important">
			<ion-item>
				<ion-label>Price</ion-label>
				<div slot="end" class="green bold">{{ nft.price | number:'1.0-0':'en-US' }} REWARDS</div>
			</ion-item>
			<ion-item>
			  	<ion-label>Listed by Metaguide:</ion-label>
				<div slot="end" class="primary pointer" (click)="openMetaguide()">{{ nft.seller }}</div>
			</ion-item>
			<!-- <ion-item>
			  	<ion-label>Seller Address</ion-label>
				<div slot="end" class="primary pointer" (click)="openAddress()">{{ system.shortenAddress(nft.seller_address) }}</div>
			</ion-item> -->
			<ion-item>
				<ion-label>Token Address</ion-label>
				<div slot="end" class="primary pointer" (click)="openTokenAddress()">{{ system.shortenAddress(nft.token_address) }}</div>
			</ion-item>
			<ion-item>
				<ion-label>Token ID</ion-label>
				<div slot="end" class="primary pointer" (click)="openTokenID()">{{ nft.token_id }}</div>
			</ion-item>
			<ion-item>
				<ion-label>Company</ion-label>
				<div slot="end">{{ nft.company }}</div>
			</ion-item>
			<ion-item>
				<ion-label>Number Available</ion-label>
				<div slot="end" *ngIf="nft.available != undefined && nft.claimed != undefined" [class.red]="allClaimed">{{ nft.available - nft.claimed }}</div>
			</ion-item>
			<ion-item>
				<ion-label>Claim Limit per Account</ion-label>
				<div slot="end" [class.red]="allClaimed">{{ nft.acctlimit > 0 ? nft.acctlimit : 'Unlimited' }}</div>
			</ion-item>
			<ion-item>
				<ion-label>Categories</ion-label>
				<div slot="end">{{ nft.categories }}</div>
			</ion-item>
			<ion-item>
				<ion-label>Date Listed</ion-label>
				<div slot="end">{{ nft.approved | date:'medium' }}</div>
			</ion-item>
		</ion-list>
	</div>

</ion-content>

<ion-footer class="ion-no-border my-modal-footer" style="--background-color: none !important; padding: 0;" *ngIf="!allClaimed">
	<div class="flex-between">
		<div class="btn-main w-100 no-grow" style=" border-radius: 0 0 8px 8px; height: 50px;" (click)="claim()">
			Claim
		</div>
	</div>
</ion-footer>