<div class="metaguide-card" (click)="openMetaGuide()" [class.noHover]="noHover">
	<div class="flex-start">
		<div class="img-container">
			<ion-skeleton-text *ngIf="!metaguide || loading" animated class="skeleton-pfp"></ion-skeleton-text>
			
			<ng-container *ngIf="metaguide">

				<ng-container *ngIf="metaguide.profilePic && metaguide.profilePic != ''; else noPic">
					<img [src]="metaguide.profilePic + '?quality=20&width=200'" *ngIf="metaguide" alt="">
				</ng-container>
				<ng-template #noPic>
					<svg width="80" height="80" [data-jdenticon-value]="metaguide.username"></svg>
				</ng-template>
			</ng-container>
		</div>
		<ng-container *ngIf="metaguide && !loading; else loadingM">
			<div>
				<div class="metaguide-card-title">
					{{ metaguide.name ? metaguide.name : metaguide.username }}
				</div>
				<div class="metaguide-card-subtitle">{{ metaguide?.title }}</div>
				<div class="my-small mt-1">
                    <i *ngIf="metaguide.pinned" class="fa-solid fa-map-pin fa-sm"></i>
                    &nbsp;@{{ metaguide.username }}
                </div>
			</div>
		</ng-container>
		<ng-template #loadingM>
			<div class="w-100">
				<ion-skeleton-text class="mb-3" style="border-radius: 10px; width: 100%" animated></ion-skeleton-text>
				<ion-skeleton-text style="border-radius: 10px; width: 100%" animated></ion-skeleton-text>
			</div>
		</ng-template>
	</div>

	<div class="metaguide-card-body">
		<div class="metaguide-card-bio" *ngIf="metaguide && !loading">
			{{ metaguide.bio }}
		</div>
		<!-- <div class="flex-between">
			<div class="socials">
				<ng-container *ngFor="let social of metaguide.socials">
					<div [class]="system.social(social) + ' social-icon'" (click)="system.openLink(social)">
						<i [class]="system.socialIcon(social)"></i>
					</div>
				</ng-container>
			</div>
			<i class="fa-solid fa-ellipsis-vertical fa-xl text-color-3 primary-hover"></i>
		</div> -->
	</div>

	<ng-container *ngIf="metaguide && metaguide.applied && !loading">
		<div class="separator"></div>
		<div>
			<div class="my-small">Why do you want to become a MetaGuide?</div>
			<p class="text-color">{{ metaguide.question1 }}</p>
			<div class="my-small">What would you like to offer a course on?</div>
			<p class="text-color">{{ metaguide.question2 }}</p>
			<div class="my-small">What experience or qualifications do you have in this subject or content? This may be formal qualifications and/or demonstrable experience.</div>
			<p class="text-color">{{ metaguide.question3 }}</p>
			<div class="my-small">Any example of previous courses and/or Web3 involvement? (Videos, Links, Copywriting, Articles, Blogs)</div>
			<p class="text-color">{{ metaguide.question4 }}</p>
			<div class="my-small">How would you like to be contacted? (Please provide email/contact details)</div>
			<p class="text-color">{{ metaguide.question5 }}</p>

			<!-- <div class="my-small">Application Message:</div>
			<div class="text-color">{{ metaguide.message }}</div> -->
			<div class="flex-start mt-2">
				<div class="approve" (click)="MetaGuide.approveMetaGuide(metaguide.username)">
					<i class="fa-solid fa-check"></i>
					<ion-label>Approve</ion-label>
				</div>
				<div class="deny" (click)="MetaGuide.denyMetaGuide(metaguide.username)">
					<i class="fa-solid fa-xmark"></i>
					<ion-label>Deny</ion-label>
				</div>
			</div>
		</div>
	</ng-container>
</div>