import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { CourseFilterOption, CourseFilter, CourseService } from 'src/app/_services/course.service';
import { MetaGuide, MetaGuideService } from 'src/app/_services/metaguide.service';

@Component({
  selector: 'app-course-filters',
  templateUrl: './course-filters.component.html',
  styleUrls: ['./course-filters.component.scss'],
})
export class CourseFiltersComponent implements OnInit, AfterViewInit {

	constructor(
		public course : CourseService,
		public metaguide : MetaGuideService,
		public modal : ModalController ){

	}

    ngOnInit(){
		this.course.on('clear-filters').subscribe(()=>{
			document.querySelector('ion-searchbar')?.getInputElement().then((i) => {
				i.value = '';
			});
		})
		// this.metaGuide.on('metaguides-loaded').subscribe((res)=>{})
	}

	ngAfterViewInit(): void {
		document.getElementById("courseFilter.Categories")?.classList?.add("open");
	}

	toggleOpen(filterName : string){
		var element = document.getElementById("courseFilter." + filterName);
		element?.classList.toggle("open");
	}

	numFilters(options: CourseFilterOption[]){
		return options.filter((x : CourseFilterOption)=>{ return x.checked }).length
	}

	toggle(e : any, option : CourseFilterOption){
		option.checked = e.detail.checked
		this.course.getCoursesFilter()
	}

	search(e : any){
		this.course.courseSearch = e.target.value
		this.course.getCoursesFilter()
	}
}
