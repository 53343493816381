import { Component, Input, OnInit } from '@angular/core';
import { SystemService } from '../../_services/system.service';
import { ConnectService } from "../../_services/connect.service";
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/_services/auth.service';
import { CourseService, Review } from 'src/app/_services/course.service';
import { MetaguideApplication, MetaGuideService } from 'src/app/_services/metaguide.service';

@Component({
    selector: 'app-apply',
    templateUrl: './apply.component.html',
    styleUrls: ['./apply.component.scss'],
})
export class ApplyModalComponent implements OnInit {
	@Input() course_id! : number
	@Input() metaguide! : string

    question1? : string
    question2? : string
    question3? : string
    question4? : string
    question5? : string

    get canSubmit(){ return this.question1 && this.question2 && this.question3 && this.question4 && this.question5 }

    constructor(
        public connect: ConnectService,
        private alert : AlertController,
        private load : LoadingController,
        public auth : AuthService,
        private course : CourseService,
        public modal : ModalController,
        public Metaguide : MetaGuideService,
        public system: SystemService ) {
     }

    ngOnInit() {
        // console.log(this.course_id);
        // console.log(this.metaguide);
        
    }

    async confirm() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Submit Application',
            message: `Are you sure you want to submit this MetaGuide application? It can be cancelled at any time from your dashboard.`,
            buttons: [{
                text: 'Submit',
                handler: () => this.submit()
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }

    async submit(){
        if (this.canSubmit){
            let application : MetaguideApplication = {
                question1: this.question1!,
                question2: this.question2!,
                question3: this.question3!,
                question4: this.question4!,
                question5: this.question5!,
            }

            this.Metaguide.apply(application).then((res:any)=>{
                this.modal.dismiss()
            }, (err:any)=> {
                
            })
        }
        else this.system.showToast({ header: 'All fields required', color: 'warning'})
    }
}
