import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { ListingModalComponent } from 'src/app/_modals/listing/listing.component';
import { AuthService } from 'src/app/_services/auth.service';
import { ConnectService } from 'src/app/_services/connect.service';
import { ContractService } from 'src/app/_services/contract.service';
import { Course, CourseService } from 'src/app/_services/course.service';
import { ShopListing } from 'src/app/_services/eth.service';
import { MetaGuideService } from 'src/app/_services/metaguide.service';
import { SystemService } from 'src/app/_services/system.service';
import { CourseOptionsComponent } from '../course-options/course-options.component';

@Component({
  selector: 'app-store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.scss'],
})
export class StoreCardComponent implements OnInit {
	@Input() nft? : ShopListing
	@Input() loading? : boolean = false
	@Input() noHover? : boolean = false
	@Input() balance? : number | void
	// @Input() addImg? : boolean

	imgLoading = true;

	get allClaimed(){
		return this.nft && this.nft.claimed != undefined && this.nft.available != undefined && this.nft.claimed >= this.nft.available
	}
	get cantAfford(){
		return this.balance != undefined && this.nft && this.nft.price && this.balance < this.nft.price
	}
	get isTestnet(){
		return this.nft && (this.nft.chainid == 11155111 || this.nft.chainid == 80001)
	}
    get error(){
        return this.nft && this.nft.status == 5
    }
    get activeListing(){
        return this.nft && this.nft.status == 3
    }

	constructor(
		public system : SystemService,
		private connect : ConnectService,
		private popover : PopoverController,
		public auth : AuthService,
		public Course : CourseService,
		private modal : ModalController,
		private contract : ContractService,
		private meta : MetaGuideService,
        private alert : AlertController,
		private nav : NavController){}

	ngOnInit(){
        // console.log(this.addImg);
        
	}

    onLoad() { 
		this.imgLoading = false; 

	}

	async claim(e? : Event){
        e?.stopPropagation();

		if (!this.auth.user){
			this.system.showToast({ header: 'Not logged in', message: 'Please log in or register to claim NFTs from the Shop.', color: 'warning', linkText: "Login"})
			return
		}
		// else if (this.isTestnet){
		// 	this.system.showToast({ header: 'Testnet Listing', message: 'Cannot claim testnet listings, only showing for testing.', color: 'warning'})
		// 	return
		// }
		else if (this.meta.isMetaGuide(this.auth.user.username)){
			this.system.showToast({ header: 'Not allowed for Metaguide at this time', message: 'During the shop beta, only users are allowed to claim NFTs.', color: 'warning'})
			return
		}
		else {
			this.contract.submitClaim(this.nft!).then((res)=>{
				// console.log(res);
				this.system.showToast({ header: 'Shop Claim Submitted!', message: 'You can view pending claims in your dashboard in the Rewards section.', color: 'success', linkText: 'nav', link: '/dashboard'})
			}, err =>{
                err = err.replace('Refund: ', '')
				this.system.showToast({ header: 'Error Submitting Claim', message: err, color: 'danger' })
			})
		}
		// this.connect.transferNFT(this.nft!).then((res)=>{

		// })

	}
	async delete(e? : Event){
        e?.stopPropagation();
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Delete Failed Listing',
            message: 'Something went wrong transferring this to the vault - you can try to list this again from scratch after deleting.',
            buttons: [
                {
                    text: 'Delete',
                    handler: (data : any) => {
                        this.contract.deleteListing(this.nft!.key!)
                    }
                },
                {
                    text: 'Nevermind',
                    role: 'cancel',
                }

            ]
        });
        await alert.present();
    }   
    
	async openOptions(e : Event){
        e.stopPropagation();

        const popover = await this.popover.create({
            component: CourseOptionsComponent,
            cssClass: 'options-popover',
            event: e,
            translucent: true,
            // componentProps: { course : this.course }
        });
        await popover.present();
    }

	openNft(e? : any){
		e?.stopPropagation()

		if (this.nft && this.nft.token_address){
			if (this.nft.chainid == 1 || this.nft.chainid == 11155111)
				window.open(`https://etherscan.io/nft/${this.nft.token_address}/${ this.nft.token_id ? this.nft.token_id : '' }`)
			else if (this.nft.chainid == 137)
				window.open(`https://polygonscan.com/token/${this.nft.token_address}?a=${ this.nft.token_id ? this.nft.token_id : '' }`)
		}
	}

	async openListing(){
        if (!this.noHover){
            let modal = await this.modal.create({
                component: ListingModalComponent,
                canDismiss: true,
                cssClass: 'listing-modal',
                componentProps: { nft: this.nft! }
            });
            modal.onDidDismiss().then(async (res:any) =>{
                if (res.data === true) this.claim()
            })
            return await modal.present(); 
        }
	}
}
