import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MetaGuideFilterOption, MetaGuideService } from 'src/app/_services/metaguide.service';

@Component({
  selector: 'app-metaguide-filters',
  templateUrl: './metaguide-filters.component.html',
  styleUrls: ['./metaguide-filters.component.scss'],
})
export class MetaGuideFiltersComponent implements OnInit, AfterViewInit {

    constructor(
		public metaguide : MetaGuideService,
		public modal : ModalController ){

		this.metaguide.on('clear-meta-filters').subscribe(()=>{
			document.querySelector('ion-searchbar')?.getInputElement().then((i) => {
				i.value = '';
			});
		})
	}

    ngOnInit(){
	}

	ngAfterViewInit(): void {
		document.getElementById("metaguideFilter.Categories")?.classList?.add("open");
	}

	toggleOpen(filterName : string){
		var element = document.getElementById("metaguideFilter." + filterName);
		element?.classList.toggle("open");
	}

	numFilters(options: MetaGuideFilterOption[]){
		return options.filter((x : MetaGuideFilterOption)=>{ return x.checked }).length
	}

	toggle(e : any, option : MetaGuideFilterOption){
		option.checked = e.detail.checked
		this.metaguide.getMetaGuideFilters()
	}

	search(e : any){
		this.metaguide.metaguideSearch = e.target.value
		this.metaguide.getMetaGuideFilters()
	}
}
