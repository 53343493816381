<ion-content id="review">
	<ion-icon color="primary" size="large" (click)="modal.dismiss()" name="close-outline" class="fixed-top-right pointer"></ion-icon>

	<div class="flex-center h-100 w-100">
		<div class="block" style="width: 80%">
			<h2 class="flex-center purple mb-4">Write a Review</h2>

			<div class="hearts">
				<img src="/assets/icons/heart_{{ system.mode }}.png" id="x5" (click)="select(5)" [class.selected]="rating >= 5"> 	
				<img src="/assets/icons/heart_{{ system.mode }}.png" id="x4" (click)="select(4)" [class.selected]="rating >= 4"> 	
				<img src="/assets/icons/heart_{{ system.mode }}.png" id="x3" (click)="select(3)" [class.selected]="rating >= 3"> 	
				<img src="/assets/icons/heart_{{ system.mode }}.png" id="x2" (click)="select(2)" [class.selected]="rating >= 2"> 	
				<img src="/assets/icons/heart_{{ system.mode }}.png" id="x1" (click)="select(1)" [class.selected]="rating >= 1"> 	
			</div>

			<div class="my-input">
				<textarea type="text" placeholder="Review" class="mt-3" [(ngModel)]="review"></textarea>
			</div>

			<div class="block">
				<div class="flex-center">
					<div class="btn-main item-shadow mt-4" (click)="confirm()" [class.disabled]="!canSubmit">
						Submit 
					</div>
				</div>
			</div>
		</div>
	</div>
</ion-content>

