import { Injectable } from "@angular/core";
import { PartialObserver, Subject } from "rxjs";
import { Key } from "./crypto.service";
import { DEFAULT_SETTINGS, API, AES_KEY, METAKADEMY_ACTIVE_PUBLIC, HYPERION } from '../_constants/constants';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { AlertController } from "@ionic/angular";
const ecc = require('eosjs-ecc');
import { Storage } from '@ionic/storage';
import { Config } from "./course.service";

@Injectable()
export class AuthService  {
    private events : any = {};
    public authHeaders? : HttpHeaders
    public authSignature? : string
    private headerSet = false

    constructor(
        private storage : Storage,
        private alert : AlertController,
        private http : HttpClient ){

        this.storage.create()
        if (this.user && !this.authHeaders) this.setAuthHeaders()

        // this.sendEmail('josh.glogau@siliconswamp.io')

    }

    get user(): User {
        return localStorage.getItem('meta-user') ? JSON.parse(localStorage.getItem('meta-user')!) : null
    }
    get authHeader() : HttpHeaders | any {
        return this.authHeaders ? {  headers: this.authHeaders }
        : { 
            headers: new HttpHeaders({
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*'
            }) 
        }
    }
    get address(): string {
        let a = localStorage.getItem('address')
        return a ? a : ''
    }
    get addressShort(): string {
        let a = this.address;
        return a ? a.substring(0, 6) + '...' + a.substring(a.length - 4) : '';
    }
    get addressShort2(): string {
        let a = this.address;
        return a ? a.substring(2, 6) + '...' + a.substring(a.length - 4) : '';
    }
    get settings() {
        let settings = localStorage.getItem('meta-settings')
        if (!settings) return DEFAULT_SETTINGS
        else return JSON.parse(settings)
    }
    get signature(){
        return this.authSignature
    }
    get isAdmin(){
        return this.user && this.user.username == 'metaadmin'
    }

    setSettings(settings : any) {
        localStorage.setItem('meta-settings', JSON.stringify(settings))
    }
    setUser(user : User) {
        localStorage.setItem('meta-user', JSON.stringify(user))
    }

    login(user : Account){
        if (user.address) localStorage.setItem('address', user.address);
        let str = JSON.stringify(user)
        localStorage.setItem('meta-user', str);
        
        setTimeout(()=>{
            this.emit('login', user.username)
            this.setAuthHeaders()
        }, 500)
    }
    
    async logout() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Logout',
            message: `Are you sure you want to log out?`,
            buttons: [{
                text: 'Logout',
                handler: () => {
                    this.logoutHard()
                }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }

    logoutHard(){
        this.emit("logout")
        this.clearStorage();
        localStorage.setItem('meta-settings', JSON.stringify(DEFAULT_SETTINGS))
    }

    register(){

    }
    

    setKey(key : Key){
        this.storage.set('key', CryptoJS.AES.encrypt(JSON.stringify(key), AES_KEY).toString())
    }
    getKey() : Promise<Key>{
        return new Promise((res, rej) => {
            this.storage.get('key').then((key) => {
                if (key){
                    try         { res(JSON.parse(CryptoJS.AES.decrypt(key, AES_KEY).toString(CryptoJS.enc.Utf8))) } 
                    catch(err)  { console.log("ERR", err) }
                }
            });
        })
    }

    setAuthHeaders(username? : string){
        return new Promise((resolve, reject)=>{
            this.generateSignature().then((signature : string)=>{
                this.authSignature = signature
                this.authHeaders = new HttpHeaders ({
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': '*',
                    'username': username ? username : this.user.username,
                    'signature': signature
                }) 
                this.headerSet = true
                resolve(this.authHeaders)
            })
        })
    }
    
    getNonce(username: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.http.get(`${API}get-nonce`, { params: {username }}).toPromise().then((response: any) => {
                if(response.nonce) resolve(response.nonce);
            }).catch((err) => {
                reject(err);
            })
        })
    }
    generateSignature() : Promise<string>{
        return new Promise((resolve, reject)=>{
            if (this.user) this.getKey().then((res: Key)=>{
                resolve(ecc.sign(this.user.username, res.priv_key))
            }, err => reject('Error getting keys'))
            else reject('Not logged in')
        })
    }

    // encryptSign(str : string){
    //     return ecc.sign(str, METAKADEMY_ACTIVE_PUBLIC).toString()
    // }

    registerMetamask(message: string, username: string, email : string) {
        return new Promise((resolve, reject) => {
            this.http.post(`${API}register-metamask`, { message, username, email }).toPromise().then((data) => {
                let response = <HTTPResponse<Array<any>>>data;
                resolve(response);
            }).catch((err) => {
                console.log('HTTP Error', err);
                reject(err)
            })
        })
    }

    metaKeyHolder(usernameOrAddress : string) : Promise<boolean>{
        return new Promise((resolve, reject)=>{
            this.http.get(API + 'metaKeyHolder/' + usernameOrAddress).subscribe((res : any)=>{
                resolve(res.metaKeyHolder)
            }, err => reject(err))
        })
    }

    checkMultiplier(username? : string) : Promise<number>{
        let user = username ? username : this.user ? this.user.username : undefined
        return new Promise((resolve, reject)=>{
            if (!user) reject('No username')
            else this.http.get(API + 'checkMultiplier/' + user).subscribe((res : any)=>{
                resolve(res.key_multiplier)
            }, err => reject(err))
        })
    }

    sendEmail(email : string){
        return new Promise((resolve, reject)=>{
            this.http.post(API + "sendEmail", { email }).subscribe((res:any) => {
                resolve(res)
            }, err => reject(err))
        })
    }

    saveConfig(config : Config){
        return new Promise((resolve, reject)=>{
			this.http.post(API + 'saveConfig', { config }, this.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
    }
    saveReferrals(referrals : Referral[]){
        return new Promise((resolve, reject)=>{
			this.http.post(API + 'saveReferrals', { referrals }, this.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
    }

    reorderFAQ(order : {[key:number] : number}){
        return new Promise((resolve, reject)=>{
			this.http.post(API + 'reorderFAQ', { order }, this.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
    }
    addQuestion(question : string, answer : string){
        return new Promise((resolve, reject)=>{
			this.http.post(API + 'addQuestion', { question, answer }, this.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
    }
    saveQuestion(question : string, answer : string, id : number){
        return new Promise((resolve, reject)=>{
			this.http.post(API + 'saveQuestion', { question, answer, id }, this.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
    }
    deleteQuestion(id : number){
        return new Promise((resolve, reject)=>{
			this.http.post(API + 'deleteQuestion', { id }, this.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
    }
    getReferrals(): Promise<Referral[]>{
        return new Promise(async (resolve, reject)=>{
            if (!this.headerSet) await this.setAuthHeaders()
			this.http.post(API + 'getReferrals', {}, this.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
    }
    exportReferrals(code? : string): Promise<Referral[]>{
        return new Promise(async (resolve, reject)=>{
            if (!this.headerSet) await this.setAuthHeaders()

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'username': this.user.username,
                    'signature': this.authSignature!
                }),
                responseType: 'blob' as 'json'
            };

            this.http.post(API + 'exportReferrals', code ? { code } : {}, httpOptions).subscribe((res: any) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
            
                link.href = url;
                if (code) link.download = `referrals_${code}_${new Date().toISOString()}.xlsx`;
                else link.download = `referrals_${new Date().toISOString()}.xlsx`;
                link.click();
            });

            // let httpOptions = this.authHeader
            // httpOptions.responseType = 'blob' as 'json'
            // const response : any = await this.http.post(API + 'exportReferrals', code ? { code } : {}, httpOptions);
            // const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // const url = URL.createObjectURL(blob);
            // const link = document.createElement('a');
      
            // link.href = url;
            // link.download = 'REWARDS.xlsx';
            // link.click();

			// this.http.post(API + 'exportReferrals', code ? { code } : {}, this.authHeader).subscribe((res: any)=>{
            //     console.log('RESOLVE');
                
            //     console.log(res);
			// 	// resolve(res)
			// }, err=>{
            //     console.log('err');
                
            //     console.log(err);
                
			// 	// reject(err)
			// })
		})
    }


    usernameExists(username : string){
        return new Promise((resolve) => {
            this.http.get(`${HYPERION}v2/state/get_account?account=${username}`).subscribe((res:any) => {
                resolve(true)
            }, (err:any) => {
                resolve(false)
            })
        })
    }

    getRewardsBalance(username : string): Promise<number>{
        return new Promise((resolve) => {
            this.http.get(`${HYPERION}v2/state/get_tokens?account=${username}`).subscribe((res:any) => {
                // console.log(res);
                if (res && res.tokens?.length){
                    let rwds : Token[] = res.tokens.filter((x : Token)=>{ return x.symbol == "REWARDS" })
                    if (rwds.length) resolve(rwds[0].amount)
                    else resolve(0)
                } else resolve(0)
                // resolve(true)
            }, (err:any) => {
                resolve(0)
                // resolve(false)
            })
        })
    }

    pinCourse(course_id : string | number){
        return new Promise((resolve, reject)=>{
            if (this.isAdmin) this.http.post(API + 'pin', { course_id }, this.authHeader).subscribe((res: any)=>{
                resolve(res)
            }, err=>{
                reject(err)
            })
            else reject('Not authorized')
        })
    }
    pinMetaguide(metaguide : string){
        return new Promise((resolve, reject)=>{
            if (this.isAdmin) this.http.post(API + 'pin', { metaguide }, this.authHeader).subscribe((res: any)=>{
                resolve(res)
            }, err=>{
                reject(err)
            })
            else reject('Not authorized')
        })
    }

    // verifyAdmin(){

    // }

    verifyUsernameKey(){
        return new Promise(async (resolve, reject)=>{
            if (this.user){
                let public_key = (await this.getKey()).pub_key
                this.http.post(HYPERION + "v1/history/get_key_accounts", { public_key }).subscribe((res:any) => {
                    if (res.account_names.includes(this.user.username)) resolve(true)
                    else reject()
                }, err => reject(err))
            }
            else reject('Not logged in')
        })
    }

    clearStorage(){
        this.storage.clear()
        localStorage.removeItem('meta-user')
        localStorage.removeItem('meta-settings')
        localStorage.removeItem('address')
        this.authHeaders = undefined
        this.authSignature = undefined
    }


    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export interface User {
    username : string
    name : string
    title : string
    profilePic : string
    bio : string
    interests : string
    favorited : number
    favorites : number
    numCourses : number
    updated : any
}
interface HTTPResponse<T> {
    error: boolean;
    data?: T;
    message?: string;
}


export interface Account {
    username: string
    email: string
    address?: string
    key? : string,
    code? : number
    joined? : string | Date
    referral? : string
}

export interface Referral {
    id? : number
    code : string
    reward : number
    uses? : number
    active? : 0 | 1 | boolean
}

export interface Token {
    amount: number
    contract: string
    precision: number
    symbol: string
}