<div>
	<ng-container *ngIf="loading; else loaded">
		<div class="flex-center mt-4 fadeIn duration2" *ngIf="!noQuiz">
			<ion-spinner color="primary" name="crescent" id="loader"></ion-spinner>
		</div>
		<div class="text-center text-color-3 w-100 mt-4 zoomIn" *ngIf="noQuiz">
			No quiz yet
		</div>
	</ng-container>

	<ng-template #loaded>
		<ng-container *ngIf="quizResponse; else notTakenYet">
			<div class="btn-main mt-3 w-100 text-center no-grow" *ngIf="!passed" (click)="retakeQuiz()">
				Retake Quiz
			</div>

			<div class="my-alert mt-3 success fadeInUp" 
					[class.success]="passed"
					[class.danger]="!passed">
				<div class="flex-between">
					<div class="block">
						<div>Your Score:&nbsp;<span class="bold">{{ totalCorrect }}/{{ totalQuestions }}</span></div>
						<div>Date Completed:&nbsp;<span class="bold">{{ dateCompleted | date:'short' }}</span></div>
						<div>Duration:&nbsp;<span class="bold">{{ system.timeFormat( duration ) }}</span></div>
						<div *ngIf="passed">Rewards:&nbsp;<span class="bold">{{ quizReward }}</span></div>
					</div>
					<h3 class="m-0">{{ (totalCorrect / totalQuestions) * 100 | number:'1.0-0':'en-US'}}%</h3>
				</div>
			</div>
			<ng-container *ngFor="let q of quiz; let i = index">
				<div class="light-box fadeInUp delay-child mt-3 pointer">
					<div class="response-header">
						<h4 class="m-0">#{{ i+1 }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ q.question }}</h4>
						
						<ion-chip *ngIf="quizResponse.correct.split(',').includes((i+1).toString())" color="success" class="m-0" style="min-width: max-content;">
							<i class="fa-solid fa-check"></i>
							<ion-label>Correct</ion-label>
						</ion-chip>
						<ion-chip *ngIf="!quizResponse.correct.split(',').includes((i+1).toString())" color="danger" class="m-0" style="min-width: max-content;">
							<i class="fa-solid fa-xmark"></i>
							<ion-label>Incorrect</ion-label>
						</ion-chip>
					</div>
				</div>
			</ng-container>
		</ng-container>

		<ng-template #notTakenYet>
			<div class="my-alert mt-3 fadeInUp">
				<div class="flex-between">
					<div>Quiz Reward on Pass:</div>
					<div class="flex-end">
						<i class="fa-solid fa-trophy fa-lg"></i>
						<div>&nbsp;&nbsp;
							<ng-container *ngIf="multiplier && multiplier > 1; else default">
								<del class="text-color-3">{{ course.config?.quiz_reward | number:'1.0-0':'en-US' }}</del>&nbsp;&nbsp;{{ multiplied | number:'1.0-0':'en-US' }} Points
							</ng-container>
							<ng-template #default>
								{{ course.config?.quiz_reward | number:'1.0-0':'en-US' }}
							</ng-template>
						</div>
					</div>
				</div>
			</div>
			<ng-container *ngIf="auth.user.username != 'metaadmin' && auth.user.username != metaguide; else admin">
				<ng-container *ngIf="started; else notStarted">
					<div class="my-alert success mt-3 fadeInUp">
						<div class="flex-between">
							<div>Taking Quiz</div>
							<div *ngIf="timer" class="zoomIn">{{ timer }}</div>
						</div>
					</div>

					<ng-container *ngIf="quiz.length > 0; else loading">
						<ng-container *ngFor="let q of quiz; let i = index">
							<div class="light-box fadeInUp delay-child mt-3 pointer">
								<div class="question-header">
									<h4 class="m-0">{{ q.question }}</h4>
									<div class="my-small" *ngIf="responses[i+1]"
											[class.red]="responses[i+1].length > q.num_correct" 
											[class.green]="responses[i+1].length == q.num_correct">
										Select&nbsp;{{ q.num_correct }}
									</div>
								</div>
								<ion-item *ngFor="let a of q.answers | keyvalue; let j = index" lines="none" class="quiz">
									<ion-checkbox slot="start" (ionChange)="checkbox($event, i, +a.key)" [checked]="responses[i+1] && responses[i+1].includes(a.key)"></ion-checkbox>
									<ion-label>{{ a.value }}</ion-label>
								</ion-item>
							</div>
						</ng-container>
						<div class="btn-main w-100 br-15 mt-3 fadeInUp delay{{ quiz.length+2 }}" [class.disabled]="!canSubmit" (click)="confirmSubmit()">
							Submit Quiz
						</div>
					</ng-container>
					<ng-template #loading>
						<div class="flex-center mt-4 ">
							<ion-spinner color="primary" name="circles"></ion-spinner>
						</div>
					</ng-template>
				</ng-container>

				<ng-template #notStarted>
					<div class="flex-center mt-3" >
						<ng-container *ngIf="!course.activeQuiz || (course.activeQuiz && course.activeQuiz.course_id == course_id); else otherQuiz">
							<div class="btn-main br-15 zoomIn" style="height: 70px; width: 300px" (click)="startQuiz()">
								<i class="fa-solid fa-list"></i>
								Start Quiz
							</div>
						</ng-container>
						<ng-template #otherQuiz>
							<div class="btn-main br-15 zoomIn" style="height: 70px; width: 300px" routerLink="/courses/{{course.activeQuiz!.course_id}}/quiz">
								Other Active Quiz
							</div>
						</ng-template>
					</div>
				</ng-template>
			</ng-container>
			<ng-template #admin>
				<div class="my-alert zoomInSmall mt-3">
					Admin Quiz Review
				</div>
				<ng-container *ngIf="quiz.length > 0; else loading">
					<ng-container *ngFor="let q of quiz; let i = index">
						<div class="light-box fadeInUp delay-child mt-3 pointer">
							<div class="question-header">
								<h4 class="m-0">{{ q.question }}</h4>
								<div class="my-small" *ngIf="responses[i+1]"
										[class.red]="responses[i+1].length > q.num_correct" 
										[class.green]="responses[i+1].length == q.num_correct">
									Select&nbsp;{{ q.num_correct }}
								</div>
							</div>
							<ion-item *ngFor="let a of q.answers | keyvalue; let j = index" lines="none" class="quiz">
								<ion-checkbox slot="start" (ionChange)="checkbox($event, i, +a.key)" [checked]="responses[i+1] && responses[i+1].includes(a.key)"></ion-checkbox>
								<ion-label>{{ a.value }}</ion-label>
							</ion-item>
						</div>
					</ng-container>
				</ng-container>
				<ng-template #loading>
					<div class="flex-center mt-4 ">
						<ion-spinner color="primary" name="circles"></ion-spinner>
					</div>
				</ng-template>
			</ng-template>
		</ng-template>
	</ng-template>
</div>