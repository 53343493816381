import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoginComponent } from 'src/app/_modals/login/login.component';
import { RegisterComponent } from 'src/app/_modals/register/register.component';
import { AuthService } from 'src/app/_services/auth.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
	open = false
	
	get dark(){
		if (!this.system.settings) return true
		else if (this.system.settings && this.system.settings.dark) return true
		else return false
		// return !document.body.classList.contains('dark');
	}


	constructor(
		public system : SystemService,
		public modal : ModalController,
		public auth : AuthService ) { }

	ngOnInit(): void {

	}

	openMenu() {
        document.getElementById("mobile-menu")!.style.left = "0%";
    }
    
    closeMenu() {
        document.getElementById("mobile-menu")!.style.left = "-100%";
    }

	async openLogin(){
		this.closeMenu()

		let top = await this.modal.getTop()
		top?.dismiss()

		let m = await this.modal.create({
			component: LoginComponent,
			canDismiss: true,
			cssClass: 'main-modal'
		});
		return await m.present(); 
	}

    async openRegister(){
		this.closeMenu()

		let top = await this.modal.getTop()
		top?.dismiss()
		
		let m = await this.modal.create({
			component: RegisterComponent,
			canDismiss: true,
			backdropDismiss: false,
			cssClass: 'main-modal2'
		});
		return await m.present(); 
	}

	// toggleMenu(){
	// 	if (this.open){
	// 		document.getElementById("app-sidebar")!.style.marginLeft = "-100%";
	// 		this.open = false
	// 	}
	// 	else {
	// 		document.getElementById("app-sidebar")!.style.marginLeft = "0%";
	// 		this.open = true
	// 	}
	// }


}
