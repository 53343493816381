import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from '@angular/material/input'; 
import { MatTooltipModule } from '@angular/material/tooltip'; 

@NgModule({
    declarations: [
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatTooltipModule
    ],
    exports: [
        MatTooltipModule
    ],
    providers: [
    ]
})
export class MaterialModule {}