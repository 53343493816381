import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/_services/auth.service';
import { Course, CourseService } from 'src/app/_services/course.service';
import { SystemService } from 'src/app/_services/system.service';
import { CourseOptionsComponent } from '../course-options/course-options.component';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
	@Input() course! : Course | any
	@Input() noImgHover? : boolean = false
	@Input() noClick? : boolean = false
	@Input() mini? : boolean = false
	@Input() showState? : boolean = false
	@Input() loading? : boolean = false

	get wholeStars() : number    { return Math.floor(this.course.rating) }
	get halfStar() : boolean     { return this.course.rating > Math.floor(this.course.rating) }

	get lengthHours() : number   { return Math.floor((this.course.duration * 60) / 60) }
	get lengthMinutes() : number { return (this.course.duration * 60) - (this.lengthHours * 60) }
	get lengthSeconds() : number { return (this.course.duration * 60) - (this.lengthHours * 60) - (this.lengthMinutes * 60) }

	get percentCorrect() : number {
		if (this.course.finished) return this.totalCorrect 
		else return 0
	}
	get totalQuestions() : number{
		if (this.course.finished) return this.course.correct.split(',').length + ( this.course.incorrect != '' ? this.course.incorrect.split(',').length : 0)
		else return 0
	}
	get totalCorrect(){
		if (this.course.finished) return this.course.correct.split(',').length
		else return 0
	}

	imgLoading = true;

	constructor(
		public system : SystemService,
		private popover : PopoverController,
		public auth : AuthService,
		public Course : CourseService,
		private nav : NavController){}

	ngOnInit(){
		// console.log(this.course.thumbnail);
	}

	openCourse(){
		if (this.course && !this.noClick)
			this.nav.navigateForward(['courses', this.course.course_id])
	}

    onLoad() { 
		this.imgLoading = false; 
	}

	async openOptions(e : Event){
        e.stopPropagation();

        const popover = await this.popover.create({
            component: CourseOptionsComponent,
            cssClass: 'options-popover',
            event: e,
            translucent: true,
            componentProps: { course : this.course }
        });
        await popover.present();
    }


	review(){
		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			this.nav.navigateForward(['courses', this.course.course_id])
		}
	}
	approve(){

	}
	deny(){

	}
}
