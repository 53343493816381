<ion-content id="register">
	<ion-icon color="primary" size="large" (click)="modal.dismiss()" name="close-outline" class="fixed-top-right pointer"></ion-icon>

	<div class="flex-center h-100 w-100">
		<div class="block">
			<div class="block mb-2">
				<div class="flex-center ">
					<img class="header-icon" src="/assets/icons/icon_{{system.settings.dark ? 'dark' : 'purple' }}.png" alt="">	
				</div>
				<h2 class="flex-center purple mt-2">Register</h2>
				<div class="my-small text-center m-auto" style="max-width: 70%;">
					Enter a unique username for your new account. It must be 
					<span class="userRequirements">less than 13 characters and contain only a-z and/or 1-5.</span>
				</div>
				<div class="my-small red fadeIn" style="text-align: center; height: 18px; margin-bottom: -15px;">{{ username_error }}</div>
			</div>

			<div class="p-0 w80" style="margin: auto;">
				<ion-item >
					<ion-label position="floating">Username:</ion-label>
					<ion-input autocomplete="username" ngDefaultControl [(ngModel)]="username" 
						(ionChange)="checkUsername($event)" maxlength="12" id="username"></ion-input>
					<div class="icon-container" slot="end" [class.red]="username_error != ''">
						<i class="fa-solid fa-circle-user fa-lg"
                            [ngClass]="{'success': touched && valid_username, 'danger': touched && !valid_username && !checkingName, 'primary': !touched || (touched && checkingName) }"></i>
					</div>
				</ion-item>


				<ng-container *ngIf="!sentEmail">
					<ion-item id="email">
						<ion-label position="floating">Email:</ion-label>
						<ion-input autocomplete="email" type="email" inputmode="email" 
						    ngDefaultControl [(ngModel)]="email"></ion-input>
						<div class="icon-container" slot="end">
							<i class="fa-solid fa-at fa-lg" ></i>
						</div>
					</ion-item>
				</ng-container>

				<ng-container *ngIf="sentEmail">
					<div class="row m-0">
						<div class="col-md-8 col-12" style="padding:0">
							<ion-item id="email">
								<ion-label position="floating">Email:</ion-label>
								<ion-input autocomplete="email" type="email" inputmode="email" 
									ngDefaultControl [(ngModel)]="email"></ion-input>
								<div class="icon-container d-md-none d-flex" slot="end">
									<i class="fa-solid fa-at fa-lg" ></i>
								</div>
							</ion-item>
						</div>
						<div class="col-md-4 col-12" style="padding:0">
							<ion-item>
								<ion-label position="floating">Code:</ion-label>
								<ion-input ngDefaultControl [(ngModel)]="code" #codeInput maxlength="6"></ion-input>
								<div class="icon-container" slot="end">
									<i class="fa-solid fa-at fa-lg" ></i>
								</div>
							</ion-item>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="sentEmail && code && code.toString().length == 6">
					<ion-item>
						<ion-label position="floating">Password:</ion-label>
						<ion-input autocomplete="password" ngDefaultControl [(ngModel)]="password" [type]="hidePassword ? 'password' : 'text'"></ion-input>
						<div class="icon-container pointer" slot="end">
							<i class="fa-solid fa-lg" [ngClass]="hidePassword ? 'fa-eye-low-vision' : 'fa-eye'"
								(click)="hidePassword = !hidePassword"></i>
						</div>
					</ion-item>
					<ion-item class="pointer" (click)="clickAddress()" lines="full" style="padding-top: 9px; --padding-bottom: 7px;">
						<ion-label>
							ETH Address:&nbsp;&nbsp;
							<span class="my-input" *ngIf="connect.connected" >{{ connect.accounts[0].slice(0,6) }}...{{ connect.accounts[0].slice(-4) }}</span>
							<span class="my-input conn" *ngIf="!connect.connected" style="font-weight: 400 !important;">Connect (Optional)</span>
						</ion-label>
						<div class="icon-container" style="margin-top: 0 !important;">
							<i class="fa-solid fa-link fa-lg"></i>
						</div>
					</ion-item>
				</ng-container>
				<ng-container *ngIf="(sentEmail && code && code.toString().length == 6) || referral">
					<ion-item >
						<ion-label position="floating">Referral Code:&nbsp;(Optional)</ion-label>
						<ion-input ngDefaultControl [(ngModel)]="referral"></ion-input>
						<div class="icon-container" slot="end" >
							<i class="fa-solid fa-handshake-simple fa-lg" ></i>
						</div>
					</ion-item>
				</ng-container>
			</div>

			<div class="block">
				<div class="flex-center">
					<div class="btn-main item-shadow mt-4" (click)="register()" [class.disabled]="!canRegister" *ngIf="sentEmail">
						{{ code && code.toString().length == 6 ? 'Register' : 'Enter Code' }}
					</div>
					<div class="btn-main item-shadow mt-4" (click)="sendEmail()" [class.disabled]="!email || !valid_username" *ngIf="!sentEmail">
						<ng-container *ngIf="sendingEmail; else naw">
							<ion-spinner color="primary" name="crescent" id="loader"></ion-spinner>
						</ng-container>
						<ng-template #naw>
							Verify Email
						</ng-template>
					</div>
				</div>
				<div class="mt-2 lower w-100 flex-center">
					<div (click)="openLogin()" class="purple my-small" style="width: max-content">
						Login
					</div>
					<ng-container *ngIf="(sentEmail || sentError) && !sendingEmail">
						<div class="purple">&nbsp;&nbsp;•&nbsp;&nbsp;</div>
						<div (click)="resendEmail()" class="purple my-small" style="width: max-content">
							Resend Email
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ion-content>

<!-- <ion-content>
	<p class="text-center light-blue p-3">
		Enter a unique username for your new account. It must be less than 13 characters and contain only a-z and/or 1-5.
	</p>
	<div class="p-md-3 p-0">
		<ion-item >
			<ion-label position="floating">Username:</ion-label>
			<ion-input autocomplete="username" ngDefaultControl [(ngModel)]="username"></ion-input>
			<div class="icon-container" slot="end">
				<i class="fa-solid fa-circle-user fa-lg" ></i>
			</div>
		</ion-item>
		<ion-item>
			<ion-label>Email:</ion-label>
			<ion-input autocomplete="email" type="email" pattern="email" inputmode="email" 
					ngDefaultControl [(ngModel)]="email"></ion-input>
			<div class="icon-container" slot="end">
				<i class="fa-solid fa-at fa-lg" ></i>
			</div>
		</ion-item>
		<ion-item>
			<ion-label>Password:</ion-label>
			<ion-input autocomplete="password" ngDefaultControl [(ngModel)]="password" [type]="hidePassword ? 'password' : 'text'"></ion-input>
			<div class="icon-container pointer" slot="end">
				<i class="fa-solid fa-lg" [ngClass]="hidePassword ? 'fa-eye-low-vision' : 'fa-eye'"
					(click)="hidePassword = !hidePassword"></i>
			</div>
		</ion-item>
		<ion-item class="pointer" (click)="clickAddress()">
			<ion-label>
				ETH Address:&nbsp;&nbsp;
				<span class="my-input" *ngIf="connect.connected">{{ connect.accounts[0].slice(0,6) }}...{{ connect.accounts[0].slice(-4) }}</span>
				<span class="my-input" *ngIf="!connect.connected">Connect Metamask</span>
			</ion-label>
			<div class="icon-container">
				<i class="fa-solid fa-link fa-lg"></i>
			</div>
		</ion-item>
	</div>
</ion-content> -->

<!-- <ion-footer class="ion-no-border my-modal-footer">
	<div class="flex-between  mb-2 mt-2">
		<div class="btn-main w-100 no-grow" style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" (click)="register()" [class.disabled]="!canRegister">
			Register
		</div>
		<div class="btn-outline no-grow" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" (click)="openLogin()">
			Login
		</div>
	</div>
</ion-footer> -->



		<!-- <form [formGroup]="metamask_register_form">
			<mat-form-field class="w-100" appearance="standard">
				<mat-label>Username</mat-label>
				<input matInput maxlength="12" formControlName="username" id="new_username" (keydown.enter)="generateLocalKey()">
				<i class="fa-solid fa-user" matSuffix></i>
				<mat-error *ngIf="!valid_username" class="red">{{ username_error }}</mat-error>
			</mat-form-field>
			<mat-form-field class="w-100" appearance="standard" style="margin-top: -10px">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" type="email" email="true">
				<i class="fa-solid fa-at" matSuffix></i>
				<mat-error>Please enter a valid email address</mat-error>
			</mat-form-field>
		</form> -->



<!-- <div class="p-4">
	<div class="logo fadeIn pl-3 pr-3">
		<img class="w-100 glow-img" src="../../../assets/logos/DS_Logo_Blue.png" alt="">
	</div>

	<form [formGroup]="register_form" class="fadeIn mt-2">
		<mat-form-field class="w-100" appearance="standard">
			<mat-label>Username</mat-label>
			<input matInput maxlength="12" formControlName="username" name="username">
			<i class="fa-solid fa-user" matSuffix></i>
			<mat-error *ngIf="!valid_username" class="red">{{ username_error }}</mat-error>
		</mat-form-field>
		<mat-form-field class="w-100" appearance="standard" style="margin-top: -10px">
			<mat-label>Email</mat-label>
			<input matInput formControlName="email" type="email" email="true">
			<i class="fa-solid fa-at" matSuffix></i>
			<mat-error>Please enter a valid email address</mat-error>
		</mat-form-field>
		<mat-form-field class="w-100 pt-0" appearance="standard" style="margin-top: -10px">
			<mat-label>Password</mat-label>
			<input name="password" matInput [type]="passwordType" formControlName="password" (keydown.enter)="register()">
			<i [class]="'pointer fa-solid ' + passwordIcon" matSuffix (click)="togglePassword()"></i>
		</mat-form-field>
	</form>

	<div class="btn-main w-100 mb-3 fadeIn" (click)="register()" [class.disabled]="standardDisabled">
		Register DragonSpawn Account
	</div>
	<small class="w-100 light-blue pointer d-flex flex-center uppercase">
		<span (click)="tabIndex = 0">Register with Metamask</span>&nbsp;&nbsp;•&nbsp;&nbsp;
		<span (click)="openLogin()" class="pointer">Log in</span>&nbsp;&nbsp;•&nbsp;&nbsp;
		<span (click)="dialogRef.close()" class="pointer">Close</span>
	</small>
</div> -->