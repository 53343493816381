<div id="store-card" [class.noHover]="noHover" (click)="openListing()">
	<ng-container *ngIf="nft && !loading; else load">
		<div class="inner" [class.disable]="allClaimed || error" >
			<div class="img-container" >
				<div class="chain" matTooltip="Ethereum NFT" (click)="openNft($event)">
					<img [src]="'/assets/networks/' + nft.chainid + '.png'" alt="">
					<!-- <i *ngIf="nft.chainid == 1 || nft.chainid == 11155111" class="fa-brands fa-ethereum"></i> -->
					<!-- <i *ngIf="nft.chainid == 137 || nft.chainid == 80001" class="fa-brands fa-polygon"></i> -->
				</div>
				
                <!-- <ng-container *ngIf="!addImg"> -->
                    <span class="img-cover"></span>
                    <ion-skeleton-text *ngIf="imgLoading" class="size m-0" animated></ion-skeleton-text>
                    <img [src]="system.safeSanitize(nft.image) " [hidden]="imgLoading" (load)="onLoad()">
                <!-- </ng-container> -->
                <!-- <ng-container *ngIf="addImg">
                    <img src="/assets/placeholders/add_{{ system.mode }}.png">
                </ng-container> -->
				<div class="testnet" *ngIf="isTestnet">
					TESTNET
				</div>
			</div>

			<div class="course-card-body">
				<div class="course-card-title">
					{{ nft.title }}
				</div>
				<ng-container *ngIf="auth.user && nft.seller == auth.user.username">
					<ng-container *ngIf="nft.status == 0">
						<ion-chip class="zoomIn" style="margin-left: 0" color="primary">
							<!-- <i class="fa-solid fa-pen-to-square"></i> -->
							<ion-label class="bold">Draft</ion-label>
						</ion-chip>
					</ng-container>
					<ng-container *ngIf="nft.status == 1">
						<ion-chip class="zoomIn" style="margin-left: 0" color="warning">
							<!-- <i class="fa-solid fa-pen-to-square"></i> -->
							<ion-label class="bold">Sending to Vault</ion-label>
						</ion-chip>
					</ng-container>
					<ng-container *ngIf="nft.status == 2">
						<ion-chip class="zoomIn" style="margin-left: 0" color="warning">
							<!-- <i class="fa-solid fa-pen-to-square"></i> -->
							<ion-label class="bold">Pending Review</ion-label>
						</ion-chip>
					</ng-container>
					<ng-container *ngIf="nft.status == 5">
                        <div class="deny" style="max-width: 98%">
                            <ion-label>ERROR: {{ nft.gas }}  </ion-label>
                        </div>
						<!-- <ion-chip class="zoomIn" style="margin-left: 0" color="danger">
							<ion-label class="bold">ERROR</ion-label>
						</ion-chip> -->
					</ng-container>
					<ng-container *ngIf="nft.status == 3">
						<ion-chip class="zoomIn" style="margin-left: 0" color="success">
							<!-- <i class="fa-solid fa-pen-to-square"></i> -->
							<ion-label class="bold">Active Listing</ion-label>
						</ion-chip>
					</ng-container>
					<!-- <ng-container *ngIf="nft.status == 3">
						<ion-chip class="zoomIn" style="margin-left: 0" color="warning">
							<i class="fa-solid fa-pen-to-square"></i>
							<ion-label>Pending Claim</ion-label>
						</ion-chip>
					</ng-container> -->
					<!-- <ng-container *ngIf="nft.status == 4">
						<ion-chip class="zoomIn" style="margin-left: 0" color="danger">
							<ion-label>Inactive: {{ nft.claimed == nft.available ? 'All Claimed' : 'Denied' }}</ion-label>
						</ion-chip>
					</ng-container> -->
				</ng-container>
				<div class="length">
					<div class="flex-start left">
						<i class="fa-solid fa-user-group"></i>
						<div class="sub-label">
							{{ nft.company }}
						</div>
					</div>
					<div class="flex-end right" *ngIf="nft.categories != ''">
						<i class="fa-solid fa-tags"></i>
						<div class="sub-label">
							{{ nft.categories.split(',')[0] }}
							<span *ngIf="nft.categories.split(',').length > 1">&nbsp;+{{ nft.categories.split(',').length -1 }}</span>			
						</div>	
					</div>
				</div>
				<div class="claimed">
					<div class="flex-start left green" [class.red]="cantAfford">
						{{ nft.price | number:'1.0-0':'en-US' }}&nbsp;&nbsp;<span>REWARDS</span>
					</div>
					<div class="flex-end right" [class.red]="allClaimed">
						{{ nft.claimed | number:'1.0-0':'en-US' }}/{{ nft.available | number:'1.0-0':'en-US' }}&nbsp;&nbsp;<span>Claimed</span>
					</div>
				</div>

			</div>
			<!-- <div class="button-button btn-main no-grow" (click)="claim($event)" *ngIf="!allClaimed || error && !cantAfford && !isTestnet"> -->
			<div class="button-button btn-main no-grow" (click)="claim($event)" *ngIf="!allClaimed && !error && !cantAfford && activeListing">
				Claim
			</div>
			<div class="button-button btn-main no-grow" (click)="delete($event)" *ngIf="error && auth.user && auth.user.username == nft.seller">
				DELETE LISTING
			</div>
		</div>
	</ng-container>

	<ng-template #load>
		<div class="inner">
			<div class="img-container">
				<span class="img-cover"></span>
				<ion-skeleton-text class="size m-0" animated></ion-skeleton-text>
			</div>

			<div class="course-card-body">
				<div >
					<ion-skeleton-text class="w-100" style="height: 16px" animated></ion-skeleton-text>
				</div>
				<div style="margin-top: 12px;">
					<ion-skeleton-text class="w-100" animated style="height: 14px;"></ion-skeleton-text>
				</div>
				<div class="flex-between" style="margin-top: 12px;">
					<ion-skeleton-text class="w-100" style="margin-right:.5rem !important; height: 15px" animated></ion-skeleton-text>
					<ion-skeleton-text class="w-100" style="margin-left:.5rem !important; height: 15px" animated></ion-skeleton-text>
				</div>
				<div class="separator-lightest"></div>
				<div class="flex-between" style="padding: 3px 0;">
					<ion-skeleton-text class="w-100" style="margin-right:.5rem !important; height: 15px" animated></ion-skeleton-text>
					<ion-skeleton-text class="w-100" style="margin-left:.5rem !important; height: 15px" animated></ion-skeleton-text>
				</div>
			</div>
		</div>
	</ng-template>
</div>