import { Injectable } from "@angular/core";
import { PartialObserver, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlertController, LoadingController, NavController } from "@ionic/angular";
import { API, IPFS_GATEWAY } from "../_constants/constants";
import { FormLecture, FormValues } from "../edit-course/edit-course.component";
import { AuthService } from "./auth.service";
import { SystemService } from "./system.service";
import { MetaGuideService } from "./metaguide.service";
import { ContractService, GetRowData } from "./contract.service";

@Injectable()
export class CourseService  {
    private events : any = {};

	categoriesIndex = 0
	metaguideIndex = 1
    public filters : CourseFilter[] = [
		{   title: 'Categories',
			options: [
				{ name : 'Blockchain',	checked: false },
				{ name : 'Crypto',		checked: false },
				{ name : 'Development',	checked: false },
				{ name : 'Marketing',	checked: false },
				{ name : 'Business',	checked: false },
				// { name : 'Photography',	checked: false },
				{ name : 'Gaming',      checked: false },
				{ name : 'Trading',		checked: false },
				{ name : 'DeFi',		checked: false },
				{ name : 'Security',	checked: false },
				{ name : 'NFTs',		checked: false },
				{ name : 'History',		checked: false }
			]
		},
		{   title: 'MetaGuide',
			options: [],
		},
		{   title: 'Difficulty',
			options: [
				{ name: 'Beginner',     checked: false },
				{ name: 'Expanded',     checked: false },
				// { name: 'Advanced',     checked: false },
				// { name: 'Expert',       checked: false },
			],
		},

		// {   title: 'Rating',
		// 	options: [
		// 		{ name : 'Lorem', checked : false },
		// 		{ name : 'Ipsum', checked : false }
		// 	],
		// },
		// {   title: 'Features',
		// 	options: [
		// 		{ name : 'Lorem', checked : false },
		// 		{ name : 'Ipsum', checked : false }
		// 	],
		// },
		// {   title: 'Earning',
		// 	options: [
		// 		{ name : 'Lorem', checked : false },
		// 		{ name : 'Ipsum', checked : false }
		// 	],
		// },
	]
	public courseSearch? : string 
	
	public sampleCourseDictionary : any = {
		10001: {
			title : 'Introduction into Art NFT Technology',
			metaguideName : 'Victor Huang',
			metaguideUser : 'VictorHuang',
			profilePic: 'https://ipfs.airwire.io/ipfs/QmSTAS2XKevVmjFBeVi9LoWJFz9MRf2nTRrZu3zzaxdgbW',
			thumbnail: 'https://ipfs.airwire.io/ipfs/QmS4wramGRDXj9zJes8y1WtkaZisFoxjTPVEj4tRXg5uBe',
			categories: 'nfts',
			rating : 4.7,
			duration : (143*60),
			numLectures : 11,
			overview: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
			difficulty: 'Beginner'
		},
		10002: {
			title : 'Blockchain Basics Lesson 1 - Terminology',
			metaguideName : 'Mohsi Khan',
			metaguideUser : 'MohsiKhan',
			profilePic: 'https://ipfs.airwire.io/ipfs/QmSTAS2XKevVmjFBeVi9LoWJFz9MRf2nTRrZu3zzaxdgbW',
			thumbnail: 'https://ipfs.airwire.io/ipfs/QmQzMnzQsA4JebwhrZx2XnNZcG8BcqyfY6ncBTjBxjbv5q',
			categories: 'blockchain',
			rating : 3.5,
			duration : (195*60),
			numLectures : 4,
			overview: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
			difficulty: 'Beginner'
		},
		10003: {
			title : 'Business Opportunities in the Web 3 Eco-System',
			metaguideName : 'Matty DCL Blogger',
			metaguideUser : 'MattyBlogger',
			profilePic: 'https://ipfs.airwire.io/ipfs/QmSTAS2XKevVmjFBeVi9LoWJFz9MRf2nTRrZu3zzaxdgbW',
			thumbnail: 'https://ipfs.airwire.io/ipfs/Qmb2fmfVZhVph2XUWH995y1SMninK6zK1Eyk1CmiLjuCWx',
			categories: 'business',
			rating : 4.9,
			duration : (150*60),
			numLectures : 24,
			overview: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
			difficulty: 'Beginner'
		},
		10004: {
			title : 'Decentraland Blockchain Gaming - 101',
			metaguideName : 'Tim Yang',
			metaguideUser : 'TimYang',
			profilePic: 'https://ipfs.airwire.io/ipfs/QmSTAS2XKevVmjFBeVi9LoWJFz9MRf2nTRrZu3zzaxdgbW',
			thumbnail: 'https://ipfs.airwire.io/ipfs/QmXKPGGYvA6cLD1UBS2dvefUvbvYVbTAHRw4vfUKo67Rge',
			categories: 'web3',
			rating : 4.0,
			duration : (84*60),
			numLectures : 17,
			overview: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
			difficulty: 'Beginner'
		},
	}
	public sampleReviews : any[] = [
		{
			name: 'argus_0',
			username: 'asd',
			title: 'MetaKademy User',
			review: `I just wanted to commend the crew on creating Metakademy. I spent my morning working on the course of the smart contract, and I've been experiencing a large brain since. I appreciate you providing this much value, guys. 
			`,
			profilePic: 'https://ipfs.fuego.airwire.io/QmVELHttBoDTNBwJN7ZZbEQJd4643uxzawMLy5Rf2bR2Dc?quality=30&width=200',
			rating: 5,
			course_id: 1000,
			metaguide: 'metabear'
		},
		{
			name: 'Unchained Ninja',
			username: 'asd',
			title: 'MetaKademy User',
			review: `I just watched Victor Huang's several videos for Metakademy's "Smart Contract Essentials," and they are excellent! I appreciate the Metakey team providing this platform for the community so we can all learn together. I'm eager to see more!`,
			profilePic: 'https://ipfs.fuego.airwire.io/QmVBvKbhRfLKgtoS2v3DR3wEumK3q5MJrKbBW3q7bnKibg?quality=30&width=200',
			rating: 5,
			course_id: 1000,
			metaguide: 'metabear'
		},
	]

	public quizResponse? : PostQuizResponse 
	public quizAnswers : any = {}

    public config? : Config

    constructor(
		private system : SystemService,
		public auth : AuthService,
		private nav : NavController,
		public metaGuide : MetaGuideService,
		private alert : AlertController,
		private load : LoadingController,
        private http : HttpClient,
		private contract : ContractService ){

        this.init()

        this.metaGuide.on('metaguides-loaded').subscribe((res : any)=>{
            for (let m of Object.keys(res)){
                this.filters[this.metaguideIndex].options.push({ name: res[m], checked: false })
            }
        })
    }

    init(){
        this.http.get<Config>(API + 'getConfig').subscribe((res : Config)=>{
            this.config = {...res}
            console.log(this.config);
            this.emit('config-loaded', this.config)
        })
    }

	getCourses(metaguide? : string): Promise<Course[]>{
		return new Promise((resolve, reject)=>{
			// resolve(this.sampleCourses)
			this.http.get(API + 'getCourses/' + (metaguide ? metaguide : '')).subscribe((res : any)=>{
				resolve(res as Course[])
			}, err => {
				reject(err)
			})
		})
	}
	async getCoursesFilter(): Promise<Course[]>{
		let filters : any = {}

		await this.filters.forEach(f => { 
			let arr = f.options.filter(o => o.checked).map(m => m.name) 
			if (arr.length){
				// console.log(f.title);
				if (f.title == 'MetaGuide') filters[f.title] = arr.map((x : string) => this.metaGuide.metaguidesFlip[x])
				else filters[f.title] = arr
			}
		});

		if (this.courseSearch) filters['Search'] = this.courseSearch

		return new Promise((resolve, reject)=>{
			// resolve(this.sampleCourses)
			this.http.post(API + 'getCourseFilters/', {filters}).subscribe((res : any)=>{
				this.emit('get-courses', res as Course[])
				resolve(res as Course[])
			}, err => {
				reject(err)
			})
		})
	}
	async getCoursesCategory(cat? : string): Promise<Course[]>{
		let filters : any = {}
		if (cat) filters['Categories'] = [cat]

		return new Promise((resolve, reject)=>{
			// resolve(this.sampleCourses)
			this.http.post(API + 'getCourseFilters/', {filters}).subscribe((res : any)=>{
				this.emit('get-courses', res as Course[])
				resolve(res as Course[])
			}, err => {
				reject(err)
			})
		})
	}
	getCourse(id : number): Promise<Course>{
		return new Promise((resolve, reject)=>{
			if (id.toString().length == 5)
				resolve(this.sampleCourseDictionary[id])
			else {
				this.http.get(API + 'getCourse/' + id).subscribe((res : any)=>{
					// console.log(res);
					resolve(res as Course)
				}, err => {
					reject(err)
				})
			}
		})
	}

	getLectures(id : number) : Promise<SqlLecture[]>{
		return new Promise((resolve, reject)=>{
			this.http.get(API + 'getLectures/' + id).subscribe((res : any)=>{
				resolve(res as SqlLecture[])
			}, err => {
				reject(err)
			})
		})
	}

	getLecture(cid : number, lid: number) : Promise<SqlLecture>{
		return new Promise((resolve, reject)=>{
			this.http.get(`${API}getLectures/${cid}/${lid}`).subscribe((res : any)=>{
				resolve(res as SqlLecture)
			}, err => {
				reject(err)
			})
		})
	}

	getQuiz(id : number) : Promise<SqlQuiz[]>{
		return new Promise((resolve, reject)=>{
			this.http.get(API + 'getQuiz/' + id).subscribe((res : any)=>{
				resolve(res as SqlQuiz[])
			}, err => {
				reject(err)
			})
		})
	}

	getQuizMetaGuide(course_id : number) : Promise<any[]>{
		return new Promise((resolve, reject)=>{
			this.http.post(API + 'getQuizMetaGuide', { course_id }, this.auth.authHeader).subscribe((res : any)=>{
				resolve(res as any[])
			}, err => {
				reject(err)
			})
		})
	}

	quizHasResponses(course_id : number) : Promise<boolean>{
		return new Promise((resolve, reject)=>{
			this.http.get<{status:boolean}>(API + 'quizHasResponses/' + course_id).subscribe((res : {status:boolean})=>{
				resolve(res.status)
			}, err => {
				resolve(true)
			})
		})
	}

	favorite(course_id : string | number){
		this.http.post(API + 'favorite', { course_id }, this.auth.authHeader).subscribe((res)=>{
			// console.log(res);
		})
	}

	checkFavorite(course_id : string | number) : Promise<boolean>{
		return new Promise((resolve, reject)=>{
			this.http.get<{ favorited : boolean }>(API + 'favCourse/' + this.auth.user.username + '/' + course_id)
			.subscribe((res : { favorited : boolean })=>{
				// console.log(res);
				resolve(res.favorited)
			})
		})
	}

	getFavCourses(username : string): Promise<Course[]>{
		return new Promise((resolve, reject)=>{
			this.http.get<Course[]>(API + 'favCourse/' + username).subscribe((res : Course[])=>{
				resolve(res)
			}, err=> reject(err))
		})
	}

	getReviews(): Promise<Review[]>{
		return new Promise((resolve, reject)=>{
			resolve(this.sampleReviews)
		})
	}

	formatSqlQuiz(quiz : SqlQuiz[]) : QuizQuestion[]{
		quiz = quiz.sort((a,b) => a.question_id - b.question_id)
		let questions : QuizQuestion[] = []
		for (let i = 0; i < quiz.length; i++){
			if (questions.length < quiz[i].question_id){
				let answers : any = {}
				answers[quiz[i].answer_id] = quiz[i].answer
				questions.push({
					question_id: quiz[i].question_id,
					question: quiz[i].question,
					num_correct: quiz[i].num_correct,
					answers
				})
			} else questions[questions.length-1].answers[quiz[i].answer_id] = quiz[i].answer 
		} return questions
	}

	startQuiz(course_id : number){
		this.quizResponse = {
			course_id,
			answers: [],
			started : new Date(),
			finished : null
		}
	}
	submitQuiz() : Promise<QuizResponse>{
		return new Promise((resolve, reject)=>{
			if (this.quizResponse){
				this.quizResponse.finished = new Date()
				this.quizResponse.answers = []

				for (let a of Object.keys(this.quizAnswers))
					this.quizResponse.answers.push(this.quizAnswers[a].join(','))

				let response : PostQuizResponse = {...this.quizResponse}
				this.http.post(API + 'submitQuiz', { response }, this.auth.authHeader).subscribe((res:any)=>{
					this.quizResponse = undefined
					this.quizAnswers = {}
					resolve(res as QuizResponse)
				}, err => { reject(err) })
			}
			else {
				reject('No active quiz to submit')
			}
		})
	}
	get activeQuiz(){
		return this.quizResponse ? this.quizResponse : null
	}
	get activeQuizTimer(){
		// console.log(this.activeQuiz ? this.system.timeFormat( ((new Date().getTime()) - (this.activeQuiz!.started.getTime()))/1000) : '');
		return this.activeQuiz ? this.system.timeFormat( ((new Date().getTime()) - (this.activeQuiz!.started.getTime()))/1000) : ''
	}

    public clearFilters(){
        this.filters.map((filter)=>{
            filter.options.map((opt)=>{
                opt.checked = false
            })
        })
		this.courseSearch = undefined
		this.emit('clear-filters')
		this.getCoursesFilter()
    }

	async storeCourse(x : FormValues, status : 0 | 1 | -1 | 2) : Promise<SqlPacket>{
		let thumbnail = ''
		if (x.info.thumbnail) thumbnail = IPFS_GATEWAY + await this.system.uploadFile(x.info.thumbnail, false, 'thumbnail')
		
		let course : PostCourse = {
			title : x.info.title,
			metaguideUser: this.auth.user.username,
			metaguideName: this.auth.user.name ? this.auth.user.name : this.auth.user.username,
			profilePic: this.auth.user.profilePic,
			thumbnail,
			categories: x.info.categories,
			duration: +x.content.lectures.reduce((x, lect : FormLecture) => { return x + lect.duration; }, 0).toFixed(2),
			numLectures: x.content.lectures.length,
			overview: x.info.overview,
			difficulty: x.info.difficulty,
			status,
            quizEnabled: x.quiz.enabled ? 1 : 0
		}

		let lectures : FormLecture[] = x.content.lectures

		return new Promise((resolve, reject)=>{
			this.http.post(API + 'storeCourse', { course, lectures }, this.auth.authHeader).subscribe((res: any)=>{
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
	}

	async updateCourse(x : FormValues, course_id : number) : Promise<SqlPacket>{
		let thumbnail = ''
		if (x.info.thumbnail) thumbnail = IPFS_GATEWAY + await this.system.uploadFile(x.info.thumbnail, false, 'thumbnail')
		
		let course : PostUpdateCourse = {
			course_id,
			title : x.info.title,
			thumbnail,
			categories: x.info.categories,
			duration: +x.content.lectures.reduce((x, lect : FormLecture) => { return x + lect.duration; }, 0).toFixed(2),
			numLectures: x.content.lectures.length,
			overview: x.info.overview,
			difficulty: x.info.difficulty,
            quizEnabled: x.quiz.enabled ? 1 : 0
		}

		return new Promise((resolve, reject)=>{
			this.http.post(API + 'updateCourse', { course }, this.auth.authHeader).subscribe((res: any)=>{
				// console.log(res);
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
	}

	// async storeQuiz(x : FormValues, course_id : number){
	async storeQuiz(x : FormValues, course_id : number, update? : boolean) : Promise<SqlPacket>{
		// console.log(x);
		
		let quiz : PostQuiz = { questions: [], answers: []}
		for (let i = 0; i < x.quiz.questions.length; i++){
			let question_key =  +(course_id.toString() + (i+1).toString())

			quiz.questions.push({
				question_key,
				question_id: i+1,
				course_id,
				metaguide: this.auth.user.username,
				question: x.quiz.questions[i].question,
				num_correct: x.quiz.questions[i].answers.filter(x => x.correct).length
			})

			for (let j = 0; j < x.quiz.questions[i].answers.length; j++)
				quiz.answers.push({
					question_key,
					course_id,
					answer_id: j+1,
					answer: x.quiz.questions[i].answers[j].answer,
					correct : x.quiz.questions[i].answers[j].correct ? 1 : 0
				})
		}

		// let reward = this.config?.quiz_reward
		// let reward = x.quiz.quizReward
		// console.log(reward);

		return new Promise((resolve, reject)=>{
			this.http.post(API + ( !update ? 'storeQuiz' : 'updateQuiz'), { quiz }, this.auth.authHeader).subscribe((res: any)=>{
				// console.log(res);
				resolve(res)
			}, err=>{
				reject(err)
			})
		})
	}

	getQuizResponse(course_id : number) : Promise<QuizResponse[]>{
		return new Promise((resolve, reject)=>{
			if (this.auth.user)
				this.http.get(`${API}getQuizResponse/${course_id}/${this.auth.user.username}`).subscribe((res : any)=>{
					// console.log(res);
					resolve(res)
				}, err => {
                    console.log(err);
                    
                    reject(err)
                })
			else reject('Not logged in')
		})
	}

	getQuizReward(course_id : number) : Promise<number>{
		return new Promise((resolve, reject)=>{
			if (this.auth.user)
				this.http.get(`${API}getQuizReward/${course_id}`).subscribe((res : any)=>{
					// console.log(res);
					resolve(res)
				}, err => reject(err))
			else reject('Not logged in')
		})
	}

	getQuizRewardUser(course_id : number, username : string) : Promise<Reward>{
		return new Promise((resolve, reject)=>{
			if (this.auth.user)
				this.http.get(`${API}getQuizReward/${course_id}/${username}`).subscribe((res : any)=>{
					resolve(res)
				}, err => {
                    reject(err)
                })
			else reject('Not logged in')
		})
	}

	public openCourse(course : Course){
		// let navigationExtras: NavigationExtras = { state: { course } };
		// this.router.navigateForward(['courses', course.course_id], navigationExtras);
        this.nav.navigateForward(['courses', course.course_id])
	}
	public openCourseID(course_id : number){
		// let navigationExtras: NavigationExtras = { state: { course } };
		// this.router.navigateForward(['courses', course.course_id], navigationExtras);
        this.nav.navigateForward(['courses', course_id])
	}


	watchLecture(course_id : number, lecture_id : number){
		return new Promise((resolve, reject)=>{
			this.http.post(API + 'watchLecture', { course_id, lecture_id }, this.auth.authHeader).subscribe((res)=>{
				resolve(res)
			}, err=> reject(err))
		})
	}

	checkRewards(username: string, course_id : number) : Promise<Reward[]>{
		// console.log('checking');
		// 
		return new Promise((resolve, reject)=>{
			this.contract.getRows<any>({
				contract: 'metakademy', 
				table: 'rewards',
				scope: username,
				key_type: 'i64',
				index_position: 2,
				upper_bound: course_id,
				lower_bound: course_id
			}).then((data)=>{
				resolve(data.rows)
			})

			// let url = API + 'checkRewards/' + username + (course_id ? '/' + course_id!.toString() : '')
			// // console.log(url);
			
			// this.http.get<Reward[]>(url).subscribe((res : Reward[])=>{
			// 	resolve(res)
			// }, err => reject(err))
		})
	}

	courseViews(course_id : number) : Promise<number>{
		return new Promise((resolve, reject)=>{
			this.http.get<{ views : number}>(API + 'courseViews/' + course_id.toString()).subscribe((res : { views : number})=>{
				resolve(res.views)
			}, err => reject(err))
		})
	}

	getCompletedCourses(user? : string){
		return new Promise((resolve, reject)=>{
			this.http.get(API + 'completedCourses' + (user ? '/' + user : null)).subscribe((res :any)=>{
				resolve(res)
			}, err => reject(err))
		})
	}

	getContinueCourses(user : string){
		return new Promise((resolve, reject)=>{
			this.http.get(API + 'continueCourses/' + user).subscribe((res :any)=>{
				let temp = []
				for (let x of res)
					if ((res.filter((i:any) => x.course_id == i.course_id).length) < x.numLectures)
						temp.push(x)
	
				// Grab unique unfinished courses
				let temp2 : any = []
				for (let t of temp)
					if (!temp2.filter((x:any) => t.course_id == x.course_id).length )	{
						let numCompleted = temp.filter((x:any) => t.course_id == x.course_id).length
						t.numCompleted = numCompleted
						temp2.push(t)
					}

				resolve(temp2)
			}, err => reject(err))
		})
	}

	// getUserRewards(user : string) : Promise<number>{
	// 	return new Promise((resolve, reject)=>{
	// 		this.http.get<number>(API + 'userRewards/' + user).subscribe((res :number)=>{
	// 			resolve(res)
	// 		}, err => reject(err))
	// 	})
	// }

	async approveCourse(course_id : number){
		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Approve Course Review',
				message: `Are you sure you want to approve this course review? This course will be available to all users if approved.`,
				buttons: [{
					text: 'Approve',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Approving...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post<Course[]>(API + 'approveCourse', { course_id }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Course Approved!', message: 'This course is now available to all users', color: 'success'})
							this.nav.navigateForward(['courses', course_id])
							this.emit('update-pending')
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error approving course...', message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}
	async denyCourse(course_id : number){
		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Deny Course Review',
				message: `Are you sure you want to deny this course review?`,
				buttons: [{
					text: 'Deny',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Denying...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post<Course[]>(API + 'denyCourse', { course_id }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Course Denied', color: 'success'})
							this.emit('update-pending')
							// this.nav.navigateForward(['courses', course_id])
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error denying course...', message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}
	async submitCourseReview(course_id : number){
		if (this.auth.user){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Submit for Review',
				message: `Are you sure you want to submit this course for review? If approved it will be available to all users.`,
				buttons: [{
					text: 'Submit',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Submitting...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post<Course[]>(API + 'submitCourseReview', { course_id }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Course Review Submitted', color: 'success'})
							this.emit('update-pending')
							this.nav.navigateForward(['courses', course_id])
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error submitting course...', message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}
	async deleteDraft(course_id : number){
		if (this.auth.user){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Delete Course Draft',
				message: `Are you sure you want to delete this course draft? All stored data will be removed.`,
				buttons: [{
					text: 'Delete',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Deleting...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post<Course[]>(API + 'deleteDraft', { course_id }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Course Draft Deleted', color: 'success'})
							this.emit('update-pending')
							this.nav.navigateForward(['courses'])
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error deleting course draft...', message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}

	// Admin functions
	getPendingCourses() : Promise<Course[]>{
		return new Promise((resolve, reject)=>{
			this.http.post<Course[]>(API + 'getPendingCourses', {}, this.auth.authHeader).subscribe((res : any)=>{
				resolve(res)
			}, err=> reject(err))
		})
	}
	getPendingApplications() : Promise<any[]>{
		return new Promise((resolve, reject)=>{
			this.http.post<any[]>(API + 'getPendingApplications', {}, this.auth.authHeader).subscribe((res : any)=>{
				resolve(res)
			}, err=> reject(err))
		})
	}
	getPendingReviews() : Promise<any[]>{
		return new Promise((resolve, reject)=>{
			this.http.post<any[]>(API + 'getPendingReviews', {}, this.auth.authHeader).subscribe((res : any)=>{
				resolve(res)
			}, err=> reject(err))
		})
	}
	
	addReview(review : Review) : Promise<any[]>{
		return new Promise((resolve, reject)=>{
			this.http.post<any[]>(API + 'addReview', review, this.auth.authHeader).subscribe((res : any)=>{
				resolve(res)
			}, err=> reject(err))
		})
	}

	async approveReview(key : number, course_id: number){
		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Approve Review',
				message: `Are you sure you want to approve this course review? Review will become public on the course page.`,
				buttons: [{
					text: 'Approve',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Approving...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post(API + 'approveReview', { key, course_id }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Review Approved!', color: 'success', linkText: 'nav', link: `courses/${course_id}`})
							this.emit('update-review')
							// setTimeout(()=>{
							// 	this.nav.navigateForward(['metaguides', username])
							// }, 500)
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error approving review...', message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}
	async denyReview(key : number){
		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Deny Review',
				message: `Are you sure you want to deny this review?`,
				buttons: [{
					text: 'Deny',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Denying...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post(API + 'denyReview', { key }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Review Denied', color: 'success'})
							this.emit('update-review')							
							// this.nav.navigateForward(['courses', course_id])
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error denying review...', message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}

	videoProcess(course_id : number, lecture_id? : number): Promise<VideoProcess[]>{
		return new Promise((resolve, reject)=>{
			let url = `${API}videoProcess/${course_id}`
			if (lecture_id) url += `/${lecture_id}`

			this.http.get<VideoProcess[]>(url).subscribe((res : any)=>{
				resolve(res)
			}, err=> resolve(err))
		})
	}

	objectFlip(obj : any) {
		return Object.keys(obj).reduce((ret : any, key : any) => {
			ret[obj[key]] = key;
			return ret;
		}, {});
	}


    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export interface PostCourse {
	title : string
	metaguideUser: string
	metaguideName: string
	profilePic: string
	thumbnail : string
	categories: string
	duration: number
	numLectures: number
	overview: string
	difficulty: string
	status?: -1 | 0 | 1 | 2
		// -1 review denied
		// 0 draft state
		// 1 submitted for review
		// 2 review accepted
    quizEnabled : 0 | 1
}
export interface PostUpdateCourse {
	course_id : number
	title : string
	// metaguideUser: string
	thumbnail : string
	categories: string
	duration: number
	numLectures: number
	overview: string
	difficulty: string
    quizEnabled : 0 | 1
}
export interface PostQuiz {
	questions : PostQuizQuestion[]
	answers : PostQuizAnswer[]
}
export interface PostQuizQuestion {
	question_key: number,
	question_id: number,
	course_id: number,
	metaguide: string,
	question: string,
	num_correct: number
}
export interface PostQuizAnswer {
	question_key: number
	course_id : number
	answer_id : number
	answer : string
	correct : number
}

export interface Course {
	// course_id : number
    // title : string
    // metaguide : string
    // profilePic : string
    // thumbnail : string
    // categories : string[]
    // rating : number
    // duration : number
    // numLectures : number

	course_id: number
	title : string
	overview : string
	metaguideName : string
	metaguideUser : string
	profilePic : string
	thumbnail : string
	categories : string
	difficulty : 'Beginner' | 'Expanded' | 'MetaGuide'
	duration : number // seconds
	numLectures : number 
	rating : number
	status? : number
	created? : string
	updated? : string
    pinned : 0 | 1
    quizEnabled : 0 | 1
}

export interface CourseFilter {
    title: string
    options: CourseFilterOption[]
}
export interface CourseFilterOption {
    name : string
    checked : boolean
}

export interface Review {
    key? : number
    course_id : number
    metaguide : string
    username? : string
	name? : string
    rating : number
    review? : string
    profilePic? : string
    title? : string
    date? : string
	status? : -1 | 0 | 1
		// -1: Denied
		// 0: Pending
		// 1: Approved
}

export interface Lecture {
	lectureTitle : string
	lectureMedia : any
}



export interface SqlLecture {
    lecture_key : number
    lecture_id : number
    course_id : number
    metaguide : string
    title : string
    description : string
    duration: number
    filename : string
    aws_key : string
	rewards : number
	views : number
    uploaded : string | Date
	res? : string
	resolutions : string
}

export interface SqlQuiz {
    // question_key : number
    question_id	: number
    // course_id : number
    // metaguide : string
    question :string
    num_correct : number
    // answer_key : number
    answer_id : number
    answer :string
	// reward? : number
    // correct : number
}

export interface SqlPacket {
    fieldCount: number
    affectedRows: number
    insertId: number
    serverStatus: number
    warningCount: number
    message: string
    protocol41: boolean
    changedRows: number
}

export interface QuizQuestion {
	question_id : number
	question : string
	answers : {[key: number]: string}
	num_correct : number,
	// reward? : number
}


export interface PostQuizResponse {
	course_id: number
	answers : string[]
	started : Date
	finished : Date | null
}

export interface QuizResponse {
    response_key?: number
    course_id : number
    user: string
    answers: string
    correct: string
    incorrect: string
    started: string
    finished: string
}

export interface Reward {
	key: number
	username: string
	// course_id: number
	course: number
	// lecture_id: number
	lecture: number
	reward: number
	multiplier: number
	date: string
	note? : string
}

export interface Config {
	id: number
	key_multiplier : number
	quiz_reward : number
}

export interface StoreLecture {
    filename : string
    title : string
    course_id : number
    lecture_id : number
    lecture_key : number
    description : string
    metaguide : string
    duration : number
    rewards : number
    uploaded : string | Date
}

export interface VideoProcess {
	key : number,
	course_id : number,
	lecture_id : number,
	resolution : number,
	metaguide : string,
	status : number,
	started : string,
	finished : string
}

