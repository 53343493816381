<div class="my-list lines" (click)="pin()" *ngIf="auth.isAdmin">
    <i class="fa-solid fa-map-pin"></i>
    <span>{{ pinned ? 'Unpin' : 'Pin'  }}</span>
</div>
<ng-container *ngIf="auth.user && course.metaguideUser == auth.user.username">
    <div class="my-list lines" (click)="edit()">
        <i class="fa-solid fa-pen-to-square"></i>
        <span>Edit</span>
    </div>
    <!-- <div class="my-list lines" (click)="confirmDelete()">
        <i class="fa-solid fa-trash-can"></i>
        <span>Delete</span>
    </div> -->
</ng-container>

<div class="my-list lines" (click)="metaguide()">
    <i class="fa-solid fa-circle-user"></i>
    <span>View MetaGuide</span>
</div>

<div class="my-list" (click)="share()">
    <i class="fa-solid fa-share-nodes"></i>
    <span>Share</span>
</div>