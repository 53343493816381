import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import Hls from 'hls.js';
import { VgAPI } from 'ngx-videogular';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { AwsService } from 'src/app/_services/aws.service';
import { CourseService, SqlLecture, VideoProcess } from 'src/app/_services/course.service';
import { SocketService } from 'src/app/_services/socket.service';
import { SystemService } from 'src/app/_services/system.service';
// import videojs from 'video.js';

// 80% watch to be rewarded
const watchPercent = .8

const headers = new HttpHeaders ({
    'Accept': '*/*',
    'Access-Control-Allow-Origin': '*'
})

const hlsLevels = {
    360: 0,
    480: 1,
    720: 2,
    1080: 3,
}

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnChanges {
    @Input() lecture! : SqlLecture
    @Input() res? : string
    @Input() rewarded? : boolean
    @Output() finished = new EventEmitter<number>();

    @ViewChild('videoPlayer', { static: true }) videoPlayer: any;
    hls : Hls = new Hls()

    videoLoading = true
    changedRes = false
    progress : number = 0

    // processTotal? : number
    // processComplete : number = 0
    // get isProcessing(){
    //     return this.processComplete && this.processTotal && this.processComplete != this.processTotal
    // }

    get isProcessing(){
        return this.status && this.status.length && this.status[3] != this.statusCount
    }

    get suffix(){
        return this.res ? `_${this.res}.m3u8` : '.m3u8'
    }

    get videoSrc(){
        return `https://api.metakademy.com/getFile2/${this.lecture.course_id}/${this.lecture.lecture_id}${this.suffix}`
    }
    get fallbackSrc(){
        return `https://api.metakademy.com/getFile/${this.lecture.course_id}/${this.lecture.lecture_id}.mp4`
    }

    videoTag : HTMLVideoElement = document.createElement('video');
	get hlsSupported() : boolean{
        return this.videoTag.canPlayType('application/vnd.apple.mpegurl') !== ''
    }
	get hlsjsSupported() : boolean{
		return Hls.isSupported()
	}

    status? : number[]
    get statusCount(): number {
        if (!this.status) return 0
        else return this.status.reduce((acc, curr) => acc + curr); 
    }

    constructor(
        private aws : AwsService,
        private course : CourseService,
        private load : LoadingController,
        public modal : ModalController,
        public auth : AuthService,
        private platform : Platform,
        private system : SystemService,
        private socket : SocketService,
        private api : VgAPI,
        private http: HttpClient){


        // this.socket.io?.on(`video-progress-${this.lecture?.lecture_key.toString()}`, (progress : any) => {
        this.socket.io?.on(`video-progress`, (data : { lecture_key : number, progress : number}) => {
            if (data.lecture_key == this.lecture.lecture_key){
                // console.log('received progress for', data.lecture_key, data.progress);
                this.progress = data.progress
            }
        })
        this.socket.io?.on(`video-rewarded`, (data : { lecture_key : number, res : any}) => {
            if (data.lecture_key == this.lecture.lecture_key){
                setTimeout(()=>{
                    let message = data.res.multiplier ? `Rewards: ${data.res.reward} (2x MetaKey Holder Multiplier)` : `Rewards: ${data.res.reward}`
                    this.system.showToast({ header: 'Rewards granted!', message, color: 'success', icon: 'trophy'})
                    this.finished.emit(this.lecture.lecture_id);
                }, 200)
            }
        })
        // this.socket.io?.on('video-status', (progress : any) => {
        //     console.log('progress', progress);
        //     this.progress = progress
        // })
    }

    ngOnChanges(changes: any) {
        if (changes.res) this.changeResolution(changes.res.currentValue)
        if (changes.rewarded && changes.rewarded.currentValue == true) this.removeListeners()
    }

    ngOnInit() {
        this.loadHls()
        if (!this.rewarded) this.init()
    }


    loadHls(){
        // console.log('Load HLS');
        if (this.lecture.resolutions.split(',').length < 5) this.checkProcessing()
        
        // HLS.js supported
        if (this.hlsjsSupported){
            this.hls.loadSource(this.videoSrc);
            this.hls.attachMedia(this.videoPlayer.nativeElement);

            this.hls.on(Hls.Events.ERROR, (event, data) => {
                // console.log('ERROR', event, data);
                if (data.fatal || data.details == 'manifestLoadError'){
                    this.videoPlayer.nativeElement.src = this.fallbackSrc
                    this.checkProcessing()
                }
            });
        }
        // HLS streaming to video tag supported
        else if (this.hlsSupported){
            this.res = '480'
            this.videoPlayer.nativeElement.src = this.videoSrc
        }
        // HLS not supported at all, load MP4
        else {
            this.videoPlayer.nativeElement.src = this.fallbackSrc
        }
    }
    changeResolution(resolution: '360' | '480' | '720' | '1080' | '') {
        // console.log('Change', resolution);
        if (this.hlsjsSupported){
            this.hls.currentLevel = resolution == '' ? -1 : hlsLevels[resolution]
            this.changedRes = true
        }
        else if (this.hlsSupported){
            this.videoPlayer.nativeElement.src = this.videoSrc
            this.changedRes = true
        }
    }
    async checkProcessing(){
        // console.log('Checking');
        await this.updateStatus()
        
        while(this.isProcessing){
            await this.updateStatus()
            if (!this.isProcessing) {
                this.loadHls() 
                this.status = undefined
            }
            await this.system.delay(2000)
        }
        this.course.emit('update-lectures')
        // console.log('finished processing');
    }
    updateStatus(){
        return new Promise((resolve, reject)=>{
            this.course.videoProcess(this.lecture.course_id, this.lecture.lecture_id).then(async (data : VideoProcess[])=>{
                this.status = [0, 0, 0, 0];
                await data.forEach(item => { this.status![item.status]++; });
                // console.log(this.status);
                resolve(this.status)
            })
        })
    }

    async init(){
        await this.system.delay(100)
        // var video : HTMLVideoElement | any = document.getElementById(this.lecture.lecture_id.toString());
        var video = this.videoPlayer.nativeElement
        if (!video) return

        var timeStarted : number = -1;
        var timePlayed : number = 0;
        var duration : number = 0;
        var buffering : boolean = false
        // var manualPause = false
        var _this = this

        if(video.readyState > 0) getDuration.call(video);
        else video.addEventListener('loadedmetadata', getDuration);

        video.addEventListener("play", videoStarted);
        video.addEventListener("playing", videoStarted);

        video.addEventListener("ended", videoStopped);
        video.addEventListener("pause", videoStopped);

        video.addEventListener("waiting", videoBuffering);
        video.addEventListener("stalled", videoBuffering);
        video.addEventListener("error", videoBuffering);

        video.addEventListener("loadstart", loadstart);

        var isInPiP = false;
        video.addEventListener('enterpictureinpicture', () => { isInPiP = true; });
        video.addEventListener('leavepictureinpicture', () => { isInPiP = false; });

        function getDuration() { 
            if (_this.rewarded) return
            duration = +video!.duration.toFixed(4); 
            _this.socket.emit('video-init', { lecture: _this.lecture, duration })
        }

        function videoStarted() {
            if (_this.rewarded) return
            if (!duration || duration == 0) getDuration()
            if (isInViewport(video!)){
                buffering = false
                timeStarted = +(new Date().getTime()/1000).toFixed(4);
                _this.socket.emit('video-start', { lecture: _this.lecture, timeStarted })
            } else videoStopped()
        }
        function videoStopped() {
            if (_this.rewarded) return
            if (timeStarted > 0) {
                var playedFor = +(new Date().getTime()/1000 - timeStarted).toFixed(4);
                timeStarted = -1;
                timePlayed = +(timePlayed + playedFor).toFixed(4);
                _this.socket.emit('video-stop', { lecture: _this.lecture, timePlayed })
            }
        }
        function videoBuffering() { 
            if (_this.rewarded) return
            buffering = true 
            // if (_this.changedRes && timeStarted > 0) {
            if (timeStarted > 0) {
                var playedFor = +(new Date().getTime()/1000 - timeStarted).toFixed(4);
                timeStarted = -1;
                timePlayed = +(timePlayed + playedFor).toFixed(4);
                _this.changedRes = false
                _this.socket.emit('video-stop', { lecture: _this.lecture, timePlayed })
            }
        }

        // function progress(){
        //     if (_this.rewarded) return
        //     if (!document.hasFocus()) video.pause();
        //     if (!isInViewport(video) && _this.platform.is('desktop') ) video.pause();
        //     if (timeStarted > 0 && duration > 0 && !buffering && _this.progress < 1){
        //     // if (timeStarted > 0 && duration > 0 && _this.progress < 1){
        //         console.log('sending progress');
        //         _this.socket.emit('progress', { lecture: _this.lecture, playbackRate: video.playbackRate })
        //     }
        // }

        function isInViewport(element : HTMLVideoElement | HTMLElement) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function loadstart() { buffering = true }

        // Add playing property to video
        Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
            get: function(){ return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2); }
        })

        // Track progress bar
        // setInterval(()=>{
        //     if (_this.rewarded) return
        //     if (!document.hasFocus()) video.pause();
        //     if (!isInViewport(video) && _this.platform.is('desktop') ) video.pause();
        //     if (timeStarted > 0 && duration > 0 && !buffering && _this.progress < 1)
        //         _this.socket.emit('progress', { lecture: _this.lecture, playbackRate: video.playbackRate })
        // }, 100)
        // Track progress bar
        setInterval(() => {
            if (_this.rewarded) return

            // Check if the video is in fullscreen mode
            const isInFullscreen = (document.fullscreenElement && document.fullscreenElement === video);

            if (
                ((!isInViewport(video) && _this.platform.is('desktop')) || !document.hasFocus() )
                && 
                (!isInFullscreen && !isInPiP)) 
                    video.pause();

            if (timeStarted > 0 && duration > 0 && !buffering && _this.progress < 1)
                _this.socket.emit('progress', { lecture: _this.lecture, playbackRate: video.playbackRate })
        }, 100);

    }

    removeListeners(){
        // console.log('removing listeners');
        var video = this.videoPlayer.nativeElement
        if (!video) return
        
        video.removeEventListener('loadedmetadata', function(){})
        video.removeEventListener("play", function(){})
        video.removeEventListener("playing", function(){})
        video.removeEventListener("ended", function(){})
        video.removeEventListener("pause", function(){})
        video.removeEventListener("waiting", function(){})
        video.removeEventListener("stalled", function(){})
        video.removeEventListener("error", function(){})
        video.removeEventListener("loadstart", function(){})
        video.removeEventListener("timeupdate", function(){})
        video.removeEventListener("enterpictureinpicture", function(){})
        video.removeEventListener("leavepictureinpicture", function(){})

        this.socket.emit('video-delete', { lecture: this.lecture })
    }

    
    // Make the request for the video
    getVideo(): Observable<Blob> {
        // console.log('getting video');
        
        // Make a GET request to the video URL with the "Range" headers
        return this.http.get<Blob>(`http://api.metakademy.com/getFile/${this.lecture.course_id}/${this.lecture.lecture_id}.mp4`, { headers });
    }

    async reward(){
        // No rewards if not signed in, or if metaguide is current user, or metaadmin
        if (!this.auth.user || (this.auth.user && (this.auth.user.username == this.lecture.metaguide || this.auth.user.username == 'metaadmin'))) 
            return

        // const loading = await this.load.create({ spinner: 'crescent', message: `Rewarding...`, cssClass: 'loading-overlay', backdropDismiss: false });
        // await loading.present();

        this.course.watchLecture(this.lecture.course_id, this.lecture.lecture_id).then((res : any)=>{
            setTimeout(()=>{
                // loading.dismiss()
                let message = res.multiplier ? `Rewards: ${res.reward} (2x MetaKey Holder Multiplier)` : `Rewards: ${res.reward}`
                this.system.showToast({ header: 'Rewards granted!', message, color: 'success', icon: 'trophy'})
                this.finished.emit(this.lecture.lecture_id);
                // this.modal.dismiss(true)
            }, 200)
        }, err => {
            console.log(err);
            // loading.dismiss()
            this.system.showToast({ header: err.hasOwnProperty('error') ? err.error : err, color: 'warning'})
        })
    }
}



            // hls.on(Hls.Events.MANIFEST_PARSED, (event: any, data: any) => {
            //     console.log(">",data);
                
                // let resolutions = data.levels.map((level: any) => {
                //     return level.height;
                // });
                // console.log(resolutions);
                
                // this.resolutions = [...new Set(resolutions)];
            // });


            // console.log('Resolutions:', this.lecture.resolutions);
            
            // this.course.getLecture(this.lecture.course_id, this.lecture.lecture_id).then((lect)=>{
            //     console.log(lect);
            //     this.lecture = lect
            // })


                    // Track progress bar
        // setInterval(()=>{
        //     if (!document.hasFocus()) video.pause();

        //     if (!isInViewport(video) && this.platform.is('desktop') ){
        //         video.pause();
        //     }
        //     // else if (manualPause && !video.playing){
        //     //     video.play()
        //     //     manualPause = false
        //     // }
            
        //     if (timeStarted > 0 && duration > 0 && !buffering && this.progress < 1){
        //         // var playedFor = +(new Date().getTime()/1000 - timeStarted).toFixed(4);
        //         // var tempTimePlayed = +(timePlayed + playedFor).toFixed(4);
        //         // var progress = (tempTimePlayed * video.playbackRate) / (duration * watchPercent)
        //         // this.progress = (tempTimePlayed * video.playbackRate) / (duration * watchPercent)
        //         console.log('sending progress');
        //         if (!_this.rewarded) _this.socket.emit('progress', { lecture: _this.lecture, playbackRate: video.playbackRate })
        //         // if (this.progress >= 1 && this.auth.user) this.reward();
        //     }
        // }, 100)