<ion-content id="login">
	<ion-icon color="primary" size="large" (click)="modal.dismiss()" name="close-outline" class="fixed-top-right pointer"></ion-icon>

	<div class="flex-center h-100 w-100">
		<div class="block">
			<div class="block">
				<div class="flex-center ">
				<img class="header-icon" src="/assets/icons/icon_{{!system.settings.dark ? 'purple' : 'dark' }}.png" alt="">	
				</div>
				<h2 class="flex-center purple mt-2">Log In</h2>
			</div>

			<div class="p-md-3 p-0">
				<ion-item>
					<ion-label position="floating" >Username:</ion-label>
					<ion-input autocomplete="username" ngDefaultControl [(ngModel)]="username" maxlength="12"></ion-input>
					<div class="icon-container" slot="end">
						<i class="fa-solid fa-circle-user fa-lg" ></i>
					</div>
				</ion-item>
				<ion-item>
					<ion-label position="floating" >Password:</ion-label>
					<ion-input autocomplete="password" ngDefaultControl [(ngModel)]="password" [type]="hidePassword ? 'password' : 'text'"></ion-input>
					<div class="icon-container pointer" slot="end">
						<i class="fa-solid fa-lg" [ngClass]="hidePassword ? 'fa-eye-low-vision' : 'fa-eye'"
							(click)="hidePassword = !hidePassword"></i>
					</div>
				</ion-item>
			</div>

			<div class="block">
				<div class="flex-center">
					<div class="btn-main item-shadow mt-4" (click)="login()" [class.disabled]="!canLogin">
						Log In
					</div>
				</div>
				<div class="mt-2 lower w-100 flex-center">
					<div (click)="openRegister()" class="purple my-small" style="width: max-content">
						Register
					</div>
					<div class="purple">&nbsp;&nbsp;•&nbsp;&nbsp;</div>
					<div (click)="resetPassword()" class="purple my-small" style="width: max-content">
						Forgot Password
					</div>
				</div>
				<!-- <div class="purple text-center my-small mt-2 lower" (click)="openRegister()">Register</div> -->
			</div>
		</div>
	</div>
</ion-content>

<!-- <ion-footer class="ion-no-border my-modal-footer">
	<div class="flex-between  mb-2 mt-2">
		<div class="btn-main w-100 no-grow" style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" (click)="login()" [class.disabled]="!canLogin">
			Log In
		</div>
		<div class="btn-outline no-grow" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" (click)="openRegister()">
			Register
		</div>
	</div>
</ion-footer> -->



		<!-- <form [formGroup]="metamask_register_form">
			<mat-form-field class="w-100" appearance="standard">
				<mat-label>Username</mat-label>
				<input matInput maxlength="12" formControlName="username" id="new_username" (keydown.enter)="generateLocalKey()">
				<i class="fa-solid fa-user" matSuffix></i>
				<mat-error *ngIf="!valid_username" class="red">{{ username_error }}</mat-error>
			</mat-form-field>
			<mat-form-field class="w-100" appearance="standard" style="margin-top: -10px">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" type="email" email="true">
				<i class="fa-solid fa-at" matSuffix></i>
				<mat-error>Please enter a valid email address</mat-error>
			</mat-form-field>
		</form> -->



<!-- <div class="p-4">
	<div class="logo fadeIn pl-3 pr-3">
		<img class="w-100 glow-img" src="../../../assets/logos/DS_Logo_Blue.png" alt="">
	</div>

	<form [formGroup]="register_form" class="fadeIn mt-2">
		<mat-form-field class="w-100" appearance="standard">
			<mat-label>Username</mat-label>
			<input matInput maxlength="12" formControlName="username" name="username">
			<i class="fa-solid fa-user" matSuffix></i>
			<mat-error *ngIf="!valid_username" class="red">{{ username_error }}</mat-error>
		</mat-form-field>
		<mat-form-field class="w-100" appearance="standard" style="margin-top: -10px">
			<mat-label>Email</mat-label>
			<input matInput formControlName="email" type="email" email="true">
			<i class="fa-solid fa-at" matSuffix></i>
			<mat-error>Please enter a valid email address</mat-error>
		</mat-form-field>
		<mat-form-field class="w-100 pt-0" appearance="standard" style="margin-top: -10px">
			<mat-label>Password</mat-label>
			<input name="password" matInput [type]="passwordType" formControlName="password" (keydown.enter)="register()">
			<i [class]="'pointer fa-solid ' + passwordIcon" matSuffix (click)="togglePassword()"></i>
		</mat-form-field>
	</form>

	<div class="btn-main w-100 mb-3 fadeIn" (click)="register()" [class.disabled]="standardDisabled">
		Register DragonSpawn Account
	</div>
	<small class="w-100 light-blue pointer d-flex flex-center uppercase">
		<span (click)="tabIndex = 0">Register with Metamask</span>&nbsp;&nbsp;•&nbsp;&nbsp;
		<span (click)="openLogin()" class="pointer">Log in</span>&nbsp;&nbsp;•&nbsp;&nbsp;
		<span (click)="dialogRef.close()" class="pointer">Close</span>
	</small>
</div> -->