<div id="filters" class="course-filters">
	<ion-searchbar mode="ios" (ionChange)="search($event)" class="fadeInUp"></ion-searchbar>

	<ng-container *ngFor="let filter of course.filters">
		<div [id]="'courseFilter.' + filter.title" class="filter fadeInUp delay-child" [class.colored]="numFilters(filter.options) > 0">
			<div class="filter-title" (click)="toggleOpen(filter.title)" >
				<h3>{{ filter.title }}
					<small class="gray-text" *ngIf="numFilters(filter.options) > 0">
						&nbsp;({{ numFilters(filter.options) }})
					</small>
				</h3>
				<i class="fa-solid fa-chevron-down"></i>
			</div>
			<div class="options">
				<ion-item *ngFor="let option of filter.options" lines="none">
					<ion-checkbox color="primary" (ionChange)="toggle($event, option)" ngDefaultControl [checked]="option.checked"></ion-checkbox>
					<ion-label>{{ option.name }}</ion-label>
				</ion-item>
			</div>
		</div>
	</ng-container>
</div>
