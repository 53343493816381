<div id="appFooter" class="fadeInUp duration2" style="min-height: 72px;">
	<div class="container h-100">
		<div class="row h-100">
			<div class="col-lg-3 col-md-12 flex-start">
				<img class="header-icon" src="/assets/icons/icon_{{ system.mode }}.png" alt="">	
				<div class="text-logo" routerLink="/">Metakademy</div>
			</div>
			<div class="col-lg-6 col-md-12 ">
				<div class="menu">
					<div class="menu-item zoomIn" (click)="openHelp()">
						Help
					</div>
					<div class="menu-item zoomIn delay1" routerLink="/privacy">
						Privacy Policy
					</div>
					<div class="menu-item zoomIn delay2" routerLink="/terms">
						Terms of Use
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-12 flex-end">
				<div class="socials">
					<ng-container *ngFor="let social of socials">
						<div [class]="system.social(social) + ' social-icon'" (click)="system.openLink(social)">
							<i [class]="system.socialIcon(social)"></i>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="bottom-bar">
		© 2023 Metakademy | All Rights Reserved
	</div>
</div>