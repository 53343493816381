import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LoginComponent } from 'src/app/_modals/login/login.component';
import { RegisterComponent } from 'src/app/_modals/register/register.component';
import { AuthService } from 'src/app/_services/auth.service';
import { ConnectService } from 'src/app/_services/connect.service';
import { ContractService } from 'src/app/_services/contract.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	loginModal? : HTMLIonModalElement
	registerModal? : HTMLIonModalElement

	get dark(){
		if (!this.system.settings) return true
		else if (this.system.settings && this.system.settings.dark) return true
		else return false
		// return !document.body.classList.contains('dark');
	}

	constructor(
		public auth : AuthService,
		public system : SystemService,
		public modal : ModalController,
		private alert : AlertController,
		public connect : ConnectService,
		private contract : ContractService
	) { }

	ngOnInit() {
		// this.connect()

	}


	openMenu(){
		document.getElementById("mobile-menu")!.style.left = "0%";
	}
	
	async openLogin(){
		let top = await this.modal.getTop()
		top?.dismiss()

		this.loginModal = await this.modal.create({
			component: LoginComponent,
			canDismiss: true,
			cssClass: 'main-modal'
		});
		return await this.loginModal.present(); 
	}

    async openRegister(){
		let top = await this.modal.getTop()
		top?.dismiss()
		
		this.registerModal = await this.modal.create({
			component: RegisterComponent,
			canDismiss: true,
			backdropDismiss: false,
			cssClass: 'main-modal2'
		});
		return await this.registerModal.present(); 
	}

	// async accountOptions() {
	// 	let ethereum = (window as any)?.ethereum;
	// 	// if (typeof ethereum !== 'undefined') {
	// 	// 	console.log('MetaMask is available');
	// 	// }
	// 	if (ethereum && typeof ethereum !== 'undefined') {
	// 		if (this.auth.address && this.auth.address.length >= 40) {
	// 			const alert = await this.alert.create({
	// 				cssClass: 'my-custom-class',
	// 				header: 'MetaMask Options',
	// 				message: `Link a different account via MetaMask, or Disconnect from MetaMask.<br><br>Linked Address:<br><span class="courier light-blue">${this.auth.address}</span>`,
	// 				buttons: [
	// 					{
	// 						text: 'Link Different',
	// 						handler: () => { this.connect.editAccounts().then((addr : string)=>{
	// 							localStorage.setItem('address', addr)
	// 							this.auth.emit('new-address-set', addr)
	// 						})}
	// 					}, 
	// 					{
	// 						text: 'Unlink',
	// 						handler: () => { 
	// 							this.contract.unlinkAddr()
	// 						}
	// 					}
	// 				]
	// 			});
	// 			await alert.present();
	// 		}
	// 		else {
	// 			// if (!this.connect.connected) this.connect.connect()
	// 			// else {
	// 				this.connect.editAccounts().then((addr : string)=>{
	// 					localStorage.setItem('address', addr)
	// 					this.auth.emit('new-address-set', addr)
	// 				})
	// 			// }
	// 		}
	// 	}
	// 	else {
	// 		console.log('no mm');
	// 		const alert = await this.alert.create({
    //             cssClass: 'my-custom-class',
    //             header: 'Open Metamask',
    //             message: `This browser doesn't support Metamask. Click to continue with Metamask browser to link you address.`,
    //             buttons: [{
    //                 text: 'Continue',
    //                 handler: () => {
    //                     let url = 'https://metamask.app.link/dapp/metakademy.com'
    //                     if (this.auth.user) url = `https://metamask.app.link/dapp/metakademy.com`
    //                     window.open(url, '_blank')
    //                 }
    //             },{
    //                 text: 'Cancel',
    //                 role: 'cancel'
    //             }]
    //         });
    //         await alert.present();
	// 	}
    // }
}

