import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { ComponentsModule } from "../_components/components.module";
import { MaterialModule } from "../_components/material.module";
import { ApplyModalComponent } from "./apply/apply.component";
import { ListingModalComponent } from "./listing/listing.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ReviewModalComponent } from "./review/review.component";
// import { VideoPlayerComponent } from "../_components/video-player/video-player.component";

const modals = [
    LoginComponent,
    RegisterComponent,
    // VideoPlayerComponent,
    ReviewModalComponent,
    ResetPasswordComponent,
    ApplyModalComponent,
    ListingModalComponent
]

@NgModule({
    declarations: [
        ...modals
    ],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialModule,
        ComponentsModule,
        IonicModule
    ],
    exports: [
        ...modals
    ]
})
export class ModalsModule { }