import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './_components/components.module';
import { AuthService } from './_services/auth.service';
import { SystemService } from './_services/system.service';
import { CourseService } from './_services/course.service';
import { CryptoService } from './_services/crypto.service';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
import { MetaGuideService } from './_services/metaguide.service';
import { ContractService } from './_services/contract.service';
import { ConnectService } from './_services/connect.service';
import { ModalsModule } from './_modals/modals.module';
import { NgxJdenticonModule } from "ngx-jdenticon";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AwsService } from './_services/aws.service';
import { SocketService } from './_services/socket.service';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { NftService } from './_services/nft.service';
import { EthService } from './_services/eth.service';
import { VgBufferingModule, VgControlsModule, VgCoreModule, VgOverlayPlayModule, VgStreamingModule } from 'ngx-videogular';

// import { VgCoreModule } from 'ngx-videogular/core';
// import { VgControlsModule } from 'ngx-videogular/controls';
// import { VgOverlayPlayModule } from 'ngx-videogular/overlay-play';
// import { VgBufferingModule } from 'ngx-videogular/buffering';


@NgModule({
	declarations: [ AppComponent ],
	bootstrap: [AppComponent],
    imports: [
		CommonModule,
		BrowserModule, 
		HttpClientModule,
		IonicModule.forRoot(), 
		AppRoutingModule,
		ComponentsModule,
		SwiperModule,
		FormsModule,
		ModalsModule,
		NgxJdenticonModule,
  		BrowserAnimationsModule,
		NgxLinkifyjsModule.forRoot(),
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		VgStreamingModule
	],
    providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		HttpClient,
		Storage,
		Clipboard,
		AuthService,
		SystemService,
		CryptoService,
		CourseService,
		MetaGuideService,
		ContractService,
		ConnectService,
		AwsService,
		SocketService,
		NftService,
		EthService
	],
})
export class AppModule {}
