<div id="appHeader" >
	<div class="container flex-between fadeInDown duration2">
		<div class="flex-start">
			<img class="header-icon" src="/assets/icons/icon_{{ system.mode }}.png" alt="">	
			<div class="text-logo" routerLink="/">Metakademy</div>
		</div>
		<div class="menu">
			<!-- <div class="menu-item" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
				Home
			</div> -->
			<div class="menu-item zoomIn" routerLink="/courses" routerLinkActive="active">
				Courses
			</div>
			<div class="menu-item zoomIn delay1" routerLink="/metaguides" routerLinkActive="active">
				MetaGuides
			</div>
			<!-- <div class="menu-item zoomIn delay2" routerLink="/dashboard" routerLinkActive="active">
				Dashboard
			</div> -->
			<div class="flex-center pointer" routerLink="/shop" >
				<div class="menu-item zoomIn delay2" routerLink="/shop" routerLinkActive="active">
					Shop
				</div>
				<!-- <div class="new zoomIn delay15">NEW</div> -->
			</div>
			<div class="menu-item zoomIn delay3" routerLink="/faq" routerLinkActive="active">
				FAQ
			</div>
			<!-- <div class="menu-item zoomIn delay3">
				Feelin Lucky
			</div> -->
		</div>

		<div>
			<div class="right-desktop zoomIn delay4">
				<i class="fa-solid fa-magnifying-glass primary fa-xl purple-hover" routerLink="/courses" style="transition: .2s;"></i>
				<!-- <div class="connect-button" id="open-modal"> -->
					
                <ng-container *ngIf="auth.user; else notLogged">
					<ng-container *ngIf="auth.user.username != 'metaadmin'; else admin">
						<ng-container >
							<div class="connect-button zoomIn" (click)="connect.accountOptions()">
								<span class="name">{{ auth.address && auth.address.length >= 40 ? auth.addressShort2 : 'Link Address' }}</span>
								<i class="fa-solid fa-link"></i>
							</div>
						</ng-container>
						<ng-container *ngIf="auth.user.profilePic && auth.user.profilePic != ''; else noPic">
							<img class="pfp pointer" [src]="auth.user.profilePic" alt="Profile Pic" routerLink="/dashboard" >
						</ng-container>
						<ng-template #noPic>
							<svg routerLink="/dashboard" class="pointer zoomIn delay1" width="60" height="60" [data-jdenticon-value]="auth.user.username"></svg>
						</ng-template>
					</ng-container>
					<ng-template #admin>
						<div class="connect-button zoomIn" routerLink="/dashboard" style="margin-right: 10px;">
							<span>Admin</span>
						</div>
					</ng-template>
				</ng-container>
				
				<ng-template #notLogged>
					<div class="btn-main" style="padding: 16px 18px !important; margin: 0 .5rem 0 1.5rem;" (click)="openLogin()" >
						Connect
					</div>
					<!-- <div class="flex-center" style="margin-left: 1rem;">
						<ion-chip outline color="primary" class="zoomIn" (click)="openRegister()">
							<i class="fa-solid fa-pen-to-square"></i>
							<ion-label>Register</ion-label>
						</ion-chip>
						<ion-chip outline color="primary" class="zoomIn" (click)="openLogin()" id="login">
							<i class="fa-solid fa-arrow-right-to-bracket"></i>
							<ion-label>Login</ion-label>
						</ion-chip>
					</div> -->
				</ng-template>
				
				<div class="sun-moon" style="margin-left:1rem;">
					<i [ngClass]="dark ? 'fa-sun' : 'fa-moon'"
					class="fa-solid primary fa-xl zoomIn" (click)="system.toggleDark()"></i>
				</div>
			</div>
			<div class="right-mobile">
				<!-- <i class="fa-solid fa-magnifying-glass primary fa-xl"></i> -->
				<!-- <div class="sun-moon ">
					<i [ngClass]="dark ? 'fa-moon' : 'fa-sun'"
					class="fa-solid primary fa-xl zoomIn" (click)="system.toggleDark()"></i>
				</div> -->
				<i class="fa-solid fa-bars fa-2xl" (click)="openMenu()"></i>
				<!-- <img class="pfp" src="../../../assets/pfp.png" alt=""> -->
			</div>
		</div>
	</div>
</div>


<!-- <ion-modal #modal trigger="open-modal" class="main-modal" [initialBreakpoint]="0.5" [breakpoints]="[0, 0.25, 0.5, 0.75]">
	<app-login2></app-login2>
</ion-modal> -->