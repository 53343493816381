import { Component, Input, OnInit } from '@angular/core';
import { Share } from '@capacitor/share';
import { AlertController, LoadingController, ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { SystemService } from 'src/app/_services/system.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Course } from 'src/app/_services/course.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
	selector: 'app-course-options',
	templateUrl: './course-options.component.html',
	styleUrls: ['./course-options.component.scss'],
})
export class CourseOptionsComponent implements OnInit {
    @Input() course! : Course

    get pinned(){
        return this.course && this.course.pinned && this.course.pinned === 1
    }

    constructor(
		public auth : AuthService,
		public platform : Platform,
		public system : SystemService,
		public popover : PopoverController,
		public modal : ModalController,
		private alert : AlertController,
		private nav : NavController,
        private router : Router
	) {}

    ngOnInit() {
		// console.log(this.asset);
	}

	async list(){
		this.popover.dismiss()
	}

	metaguide(){
		this.popover.dismiss()
		this.nav.navigateForward(['metaguides', this.course.metaguideUser])
	}

	async share(){
		this.popover.dismiss()
        try {
            await Share.share({
                title: `Metakademy Course: ${this.course.title} by ${this.course.metaguideName}`,
                text: `Metakademy Course: ${this.course.title} by ${this.course.metaguideName}`,
                url: `https://metakademy.com/courses/${this.course.course_id}`,
                dialogTitle: 'Share',
            });
        }
        catch { 
            this.system.copyClipboard(`https://metakademy.com/courses/${this.course.course_id}`, true) 
        }
	}

	async copy(){
		this.popover.dismiss()
        // this.system.copyClipboard(this.course.asset_id) 
	}

	async newTab(){
		this.popover.dismiss()
        // window.open(`https://www.r4r3.io/nfts/${this.asset!.owner}/${this.asset!.asset_id}`, '_blank') 
	}

	async confirmDelete(){
		this.popover.dismiss()
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Cancel Sale',
            message: 'Are you sure you want to cancel this sale listing?',
            buttons: [
                {
                    text: 'Cancel Sale',
                    handler: (data : any) => {
                    }
                },
                {
                    text: 'Close',
                    role: 'cancel',
                }

            ]
        });
        await alert.present();
    }

    edit(){
		this.popover.dismiss()
        let navigationExtras: NavigationExtras = { state: { course: this.course } };
        this.router.navigate(['courses', this.course.course_id, 'edit-course'], navigationExtras);
    }

    pin(){
		this.popover.dismiss(true)
        const prev = this.course.pinned
        this.course.pinned = prev == 1 ? 0 : 1
        this.auth.pinCourse(this.course.course_id).then((res) => {
            console.log(res);
            // this.system.showToast({ header: 'Course Pinned to Homepage', color: 'success', icon: 'pin-sharp' })
        }, err => {
            this.system.showToast({ header: 'Error pinning course:', message: err.message ? err.message : JSON.stringify(err), color: 'danger' })
            this.course.pinned = prev
        })
    }
}
