import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'img-loader',
  templateUrl: './img-loader.component.html',
  styleUrls: ['./img-loader.component.scss'],
})
export class ImgLoaderComponent implements OnInit {
  @Input() src? : string
  @HostBinding('style.background') public bg : string  | undefined
  @HostBinding('class.fadeInDown') public animate : boolean = false 

  constructor() { }

	ngOnInit() {
		var bgImg = new Image();

		bgImg.onload = () => {
			this.animate = true
			this.bg = 'linear-gradient(transparent, var(--header-color)), url(' + bgImg.src + ') no-repeat center';
		};
		bgImg!.src = this.src!;
	}
}
