import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CourseCardComponent } from "./course-card/course-card.component";
import { CourseFiltersComponent } from "./course-filters/course-filters.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { MetaGuideCardComponent } from "./metaguide-card/metaguide-card.component";
import { MobileMenuComponent } from "./mobile-menu/mobile-menu.component";
import { ReviewCardComponent } from "./review-card/review-card.component";
import { MetaGuideFiltersComponent } from "./metaguide-filters/metaguide-filters.component";
import { ImgLoaderComponent } from "./img-loader/img-loader.component";
import { NgxJdenticonModule } from "ngx-jdenticon";
import { QuizComponent } from "./quiz/quiz.component";
import { CourseOptionsComponent } from "./course-options/course-options.component";
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { IonicModule } from "@ionic/angular";
import { StoreFiltersComponent } from "./store-filters/store-filters.component";
import { StoreCardComponent } from "./store-card/store-card.component";
import { MaterialModule } from "./material.module";
import { VgBufferingModule, VgControlsModule, VgCoreModule, VgOverlayPlayModule, VgStreamingModule } from 'ngx-videogular';

const Components = [
    HeaderComponent,
    MobileMenuComponent,
    CourseCardComponent,
    MetaGuideCardComponent,
    CourseFiltersComponent,
    ReviewCardComponent,
    FooterComponent,
    MetaGuideFiltersComponent,
    ImgLoaderComponent,
    QuizComponent,
    CourseOptionsComponent,
    VideoPlayerComponent,
    StoreFiltersComponent,
    StoreCardComponent
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxJdenticonModule,
        IonicModule,
        MaterialModule,
        VgCoreModule,
		VgControlsModule,
		VgBufferingModule,
		VgStreamingModule,
        VgOverlayPlayModule,
        
    ],
    exports: [
        ...Components
    ],
    declarations: [
        ...Components
    ]
})
export class ComponentsModule{}