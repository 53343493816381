import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Readable } from "stream";
import { API } from "../_constants/constants";
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, timestamp } from 'rxjs/operators';
import { FormLecture } from "../edit-course/edit-course.component";
import { AuthService } from "./auth.service";
import { SystemService } from "./system.service";

const httpOptions: any = {
    responseType: 'blob',
    headers: new HttpHeaders ({
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*'
    })
}

@Injectable()
export class AwsService {
    private events : any = {};

    public uploads : UploadMedia[] = []
    public uploadLimit = 5

    constructor(
        public http : HttpClient, 
        public auth : AuthService,
        public system : SystemService
        ){

    }

    getFile(filename: string): Promise<Blob>{
        return new Promise((resolve, reject)=>{
            this.http.get<any>(API + 'getFile/' + filename).subscribe(async (blob: any)=>{
                resolve(blob as Blob);
            }, err => reject(err) )
        })
    }

    uploadFile(file : File, course_id : string, filename : string){
        return new Promise((resolve, reject)=>{
            let data = new FormData();
            data.append('file', file, 'img');
            data.append('name', filename);
            data.append('directory', course_id);
            
            this.http.post(API + 'uploadFile', data).subscribe((res)=>{
                resolve(res)
            }, err => reject(err))
        })
    }

    get uploadComplete() { return this.uploads.filter(x => x.started && x.completed ).length == this.uploads.length }
    get uploadActive()   { return this.uploads.filter(x => x.started && !x.completed ).length }
    get myUploads()     { return this.uploads }

    // uploadFiles(files: File[]): Observable<HttpEvent<any>>{
    uploadLectures(lectures: FormLecture[], course_id : number){
        // console.log('Uploading lectures:', lectures);

        let id = 1
        for (let lecture of lectures){
            let upload : UploadStatus = {
                id: id++,
                file : lecture.media!,
                title : lecture.title,
                description: lecture.description,
                rewards: lecture.rewards ? lecture.rewards : 0,
                duration : lecture.duration,
                course_id,
            }
            if (lecture.media) this.uploads.push(new UploadMedia(this, upload))
            else this.updateLecture(upload)
        }

        for (let i = 0; i < this.uploadLimit; i++) if (this.uploads[i]) this.uploads[i].upload()
    }

    updateLectures(lectures: FormLecture[], course_id : number, addedRewards : number){
        // console.log('Update lectures', lectures);
        
        this.uploads = []
        for (let lecture of lectures){
            let upload : UploadStatus = {
                id: lecture.lecture_id,
                title : lecture.title,
                description: lecture.description,
                duration : lecture.duration,
                rewards: lecture.rewards ? lecture.rewards : 0,
                addedRewards,
                course_id,
                extras: lecture.extras ? lecture.extras : undefined,
                file : lecture.media ? lecture.media : undefined,
            }
            if (lecture.media) this.uploads.push(new UploadMedia(this, upload))
            else this.updateLecture(upload)
        }
        
        // console.log("uploads", this.uploads);

        for (let i = 0; i < this.uploadLimit; i++) 
            if (this.uploads[i]) this.uploads[i].update()
    }

    updateLecture(upload : UploadStatus){
        let data = new FormData();
        data.append('title', upload.title);
        data.append('course_id', upload.course_id.toString());
        data.append('lecture_id', upload.id.toString());
        data.append('description', upload.description);
        data.append('rewards', upload.rewards.toString());
        data.append('duration', upload.duration.toString());
        data.append('metaguide', this.auth.user.username);

        this.http.post(API + 'updateLecture_2', data, {
            headers: new HttpHeaders ({ 
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
                'username': this.auth.user.username,
                'signature': this.auth.authSignature!,
            }),
        })
        .subscribe((data:any)=>{
            // console.log(data);
            // if (data.type == 1){
            //     this.progress = +((data.loaded / data.total)).toFixed(4)
            // }
            // else if (data.type == 4){
            //     console.log('File upload success!');
                // this.completed = true
                // this.singleUploadComplete()
                this.emit('uploaded-course', upload.id)
            // }
            
        }, err => {
            console.log("ERR", err);
        })
    }

    singleUploadComplete(){
        let remaining : UploadMedia[] = this.uploads.filter(x => !x.started)

        if (remaining.length){
            console.log(remaining.length, 'files left');
            remaining[0].upload()
        }
        else {
            console.log('DONE');
            // return??
        }
    }

    delay(ms: number) {
        return new Promise((resolve) => { setTimeout(() => { resolve(true); }, ms) })
    }

    on(event: string) : any {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)

        else this.events[event] = [sub]
        return sub
    }
    emit(event: string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export class UploadMedia {
    progress : number = 0
    completed : boolean = false
    started : boolean = false

    constructor(
        public parent : AwsService, 
        public status : UploadStatus,
        // private update : boolean
         ){}

    upload(){
        this.started = true

        let data = new FormData();

        // let filename = `${this.status.id}_${this.status.title.replace(/ /g, "_")}.${this.status.file.type.split('/')[1]}`
        data.append('title', this.status.title);
        data.append('course_id', this.status.course_id.toString());
        data.append('lecture_id', this.status.id.toString());
        data.append('description', this.status.description);
        data.append('rewards', this.status.rewards.toString());
        data.append('metaguide', this.parent.auth.user.username);
        data.append('duration', this.status.duration.toString());

        if (this.status.file){
            let filename = `${this.status.id}.${this.status.file!.type.split('/')[1]}`
            data.append('file', this.status.file!, 'mp4');
            data.append('filename', filename);

            this.parent.http.post(API + 'storeLecture', data, {
                headers: new HttpHeaders ({ 
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': '*',
                    'username': this.parent.auth.user.username,
                    'signature': this.parent.auth.authSignature!,
                }),
                reportProgress: true,
                observe: "events"
            })
            .subscribe((data:any)=>{
                // console.log(data);
                if (data.type == 1){
                    this.progress = +((data.loaded / data.total)).toFixed(4)
                }
                else if (data.type == 4){
                    console.log('File upload success!');
                    this.completed = true
                    this.parent.singleUploadComplete()
                    this.parent.emit('uploaded-course', this.status.id)
                }
                
            }, err => {
                console.log("ERR", err);
                this.parent.system.showToast({ header: 'Error storing lecture.', message: 'Sorry, please try again', color: 'danger'})
            })
        }
        else this.parent.http.post(API + 'storeLecture', data, {
            headers: new HttpHeaders ({ 
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
                'username': this.parent.auth.user.username,
                'signature': this.parent.auth.authSignature!,
            }),
        })
        .subscribe((data:any)=>{
            this.progress = 1
            this.completed = true
            this.parent.singleUploadComplete()
            this.parent.emit('uploaded-course', this.status.id)
        }, err => {
            console.log("ERR", err);
            this.parent.system.showToast({ header: 'Error storing lecture.', message: 'Sorry, please try again', color: 'danger'})
        })

    }
    update(){
        /*  3 Cases:
            1 - Replace existing file on lecture
                - File included
                - Has Extras
                - Delete old file from AWS
                - Upload and process new file
                - Update SQL with new AWS key and lecture info
            2 - Update existing lecture with no new file
                - File undeinfed
                - Has extras
                - Update SQL listing only
            3 - Upload new lecture with new file
                - File included
                - No extras
                - Handle like normal upload
        */
        this.started = true

        // CASE 1 - Replace existing file on lecture
        if (this.status.file && this.status.extras){
            // console.log('CASE 1', this.status.id);

            let filename = `${this.status.id}.${this.status.file.type.split('/')[1]}`
            let data = new FormData();
            data.append('file', this.status.file, 'mp4');
            data.append('filename', filename);
            data.append('title', this.status.title);
            data.append('course_id', this.status.course_id.toString());
            data.append('lecture_id', this.status.id.toString());
            data.append('description', this.status.description);
            data.append('rewards', this.status.rewards.toString());
            data.append('duration', this.status.duration.toString());
            data.append('metaguide', this.parent.auth.user.username);

            this.parent.http.post(API + 'updateLecture_1', data, {
                headers: new HttpHeaders ({ 
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': '*',
                    'username': this.parent.auth.user.username,
                    'signature': this.parent.auth.authSignature!,
                }),
                reportProgress: true,
                observe: "events"
            })
            .subscribe((data:any)=>{
                // console.log(data);
                if (data.type == 1){
                    this.progress = +((data.loaded / data.total)).toFixed(4)
                }
                else if (data.type == 4){
                    // console.log('File upload success!');
                    this.completed = true
                    this.parent.singleUploadComplete()
                    this.parent.emit('uploaded-course', this.status.id)
                }
                
            }, err => {
                console.log("ERR", err);
                this.parent.system.showToast({ header: 'Error updating lecture.', message: 'Sorry, please try again', color: 'danger'})
            })
        }
        // CASE 2 - Update existing lecture with no new file
        else if (!this.status.file && this.status.extras) {
            // console.log('CASE 2', this.status.id);

            
        }
        // Case 3 - Upload new lecture with new file
        else if (this.status.file && !this.status.extras) {
            // console.log('CASE 3', this.status.id);
            this.upload()
        }

        // let filename = `${this.status.id}_${this.status.title.replace(/ /g, "_")}.${this.status.file.type.split('/')[1]}`

    }
}

export interface SqlCourse {
    course_id : number
    title : string
    metaguide : string
    profilePic : string
    thumbnail : string
    categories : string[]
    rating : number
    duration : number
    numLectures : number
	overview: string
	difficulty: string
    lectures : SqlLecture[]
    quiz : SqlQuiz[]
}
export interface SqlLecture {
	title : string
	media : File | undefined
	duration : number
}
export interface SqlQuiz {

}

export interface UploadStatus {
    id : number
    file : File | undefined
    title : string
    description: string
    addedRewards? : number
    rewards : number
    duration : number
    course_id : number
    // progress : number
    // started : boolean
    // completed : boolean
    extras? : any
}

export interface AwsUploadStatusObj {
    [key: string]: AwsUploadStatus
}
export interface AwsUploadStatus {
    progress : number
    completed : boolean
    // started : boolean
}

