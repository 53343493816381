<div id="mobile-menu">
    <!-- <div class="container"> -->

        <div class="top-bar">
            <div class="flex-start" routerLink="/" (click)="closeMenu()">
                <img class="header-icon" src="/assets/icons/icon_dark.png" alt="">	
                <div class="text-logo" style="color: white" >Metakademy</div>
            </div>
            <!-- <div class="text-logo" routerLink="/">metakademy</div> -->
            <i class="fa-solid fa-xmark fa-2xl" style="color: white" (click)="closeMenu()"></i>
        </div>


		
        <div class="menu">

            <div class="separator"></div>
 
            <div class="menu-item" routerLink="/courses" routerLinkActive="active" (click)="closeMenu()">
                Courses
            </div>
            <div class="menu-item" routerLink="/metaguides" routerLinkActive="active" (click)="closeMenu()">
                MetaGuides
            </div>
            <div class="menu-item" routerLink="/dashboard" routerLinkActive="active" (click)="closeMenu()">
                Dashboard
            </div>
            <div class="menu-item" routerLink="/faq" routerLinkActive="active" (click)="closeMenu()">
                FAQ
            </div>
            <!-- <div class="menu-item" >
                Feelin Lucky
            </div> -->

		    <div class="separator"></div>

            <ng-container *ngIf="auth.user; else notLogged">
                <ng-container *ngIf="auth.user.username != 'metaadmin'; else admin">
                    <!-- <ng-container >
                        <div class="connect-button zoomIn" (click)="accountOptions()">
                            <span>{{ auth.addressShort2 }}</span>
                            <i class="fa-solid fa-link"></i>
                        </div>
                    </ng-container> -->
                    <ng-container *ngIf="auth.user.profilePic && auth.user.profilePic != ''; else noPic">
                        <div class="flex-between">
                            <img class="pfp pointer" [src]="auth.user.profilePic" alt="Profile Pic" routerLink="/dashboard/settings" >
                            <div class="block w-100" style="margin-left: 1rem;">
                                <h3 class="m-0" style="color: rgb(255,255,255)">{{ auth.user.name }}</h3>
                                <div style="color: rgb(255,255,255,.7)">@{{ auth.user.username }}</div>
                            </div>
                            <div class="sun-moon" style="margin-left:1rem;">
                                <i [ngClass]="dark ? 'fa-sun' : 'fa-moon'"
                                    class="fa-solid primary fa-xl zoomIn" (click)="system.toggleDark()"></i>
			                </div>
                        </div>
                    </ng-container>
                    <ng-template #noPic>
                        <svg routerLink="/dashboard/settings" class="pointer zoomIn delay1" width="60" height="60" [data-jdenticon-value]="auth.user.username"></svg>
                    </ng-template>
                </ng-container>
                <ng-template #admin>
                    <div class="connect-button zoomIn" routerLink="/dashboard" style="margin-right: 10px;">
                        <span>Admin</span>
                    </div>
                </ng-template>
            </ng-container>

			<ng-template #notLogged>
				<div class="flex-center" style="margin-left: 1rem;">
					<ion-chip class="zoomIn" color="primary" (click)="openRegister()">
						<i class="fa-solid fa-pen-to-square"></i>
						<ion-label>Register</ion-label>
					</ion-chip>
					<ion-chip class="zoomIn"  color="primary"(click)="openLogin()" id="login">
						<i class="fa-solid fa-arrow-right-to-bracket"></i>
						<ion-label>Login</ion-label>
					</ion-chip>
                    <div class="sun-moon" style="margin-left:1rem;">
                        <i [ngClass]="dark ? 'fa-sun' : 'fa-moon'"
                            class="fa-solid primary fa-xl zoomIn" (click)="system.toggleDark()"></i>
                    </div>
				</div>
			</ng-template>


        </div>

	<!-- <div class="bottom">
		<ng-container *ngIf="auth.user; else notLogged">
			<div class="btn-main w-100 fadeInUp" (click)="auth.logout()">Log out</div>
        </ng-container>

        <ng-template #notLogged>
            <div class="row m-0 fadeInUp duration1">
                <div class="col-6 pl-0 pr-2">
                    <div class="btn-main" (click)="login()">
                        Login
                    </div>
                </div>
                <div class="col-6 pl-2 pr-0">
                    <div class="btn-main" (click)="register()">
                        Register
                    </div>
                </div>
            </div>
        </ng-template>
	</div> -->
</div>