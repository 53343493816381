import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Account, AuthService, User } from './auth.service';
import { AbiItem } from "web3-utils";

import { JsonRpc, RpcError, Api } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';
import { createInitialTypes, SerialBuffer } from 'eosjs/dist/eosjs-serialize';
import { TextDecoder, TextEncoder } from 'text-encoding';

import { SystemService } from './system.service';
import { HYPERION, API, EXPLORER_TRANSACTION } from '../_constants/constants';
import { CryptoService } from './crypto.service';
import { Router } from '@angular/router';
import { resolve } from 'dns';

import { PushTransactionArgs } from 'eosjs/dist/eosjs-rpc-interfaces';
import { TransactResult } from 'eosjs/dist/eosjs-api-interfaces';
import { environment } from 'src/environments/environment';
import { AlertController, LoadingController } from '@ionic/angular';
import { ShopClaim, ShopListing } from './eth.service';
// import { ConnectService } from './connect.service';
const ecc = require('eosjs-ecc')

@Injectable()
export class ContractService {
    private events : any = {};
    public rpc? : JsonRpc;
    public api? : Api;

    public ready: boolean = false;
    private contract_json?: ContractJson;
    private contractName : string = "mk.users";
    private endpoint : string = "https://swamprod.airwire.io";

    private endpoints = [
        "https://swamprod.airwire.io",
        'https://londonprod.airwire.io',
        // 'https://tokyoprod.airwire.io',
        'https://sydneyprod.airwire.io',
        // 'https://fremontprod.airwire.io',
        'https://wire.siliconswamp.info'
    ]
    
    lastAccount: string | undefined;
    maintenance = false

    async last_irreversible_block_num(): Promise<number | undefined> {
		if(this.rpc) {
            let info = await this.rpc.get_info();
            return info.last_irreversible_block_num;
        } else return
	}

    constructor(
        public auth : AuthService,
        private crypto : CryptoService,
        private http : HttpClient,
        public system : SystemService,
        private alert : AlertController,
        private load : LoadingController,
        // private connect : ConnectService,
        private router : Router ){

        this.init()

        // if (!this.auth.user) this.login()
        // else this.auth.getKey().then((key : Key) => { this.login(key) })

        this.auth.on('new-address-set').subscribe((address : any)=>{
            console.log('new address set', address);
            
            if (this.auth.user) this.linkAddr(address)
		})
    }

    async init(){
        this.findEndpoint().then((endpoint)=>{
            this.endpoint = endpoint
            if (!this.auth.user) this.login()
            else this.auth.getKey().then((key : Key) => { this.login(key) })
        })
    }

    login(key? : Key){
        let signatureProvider : JsSignatureProvider = new JsSignatureProvider(key ? [key.priv_key] : []);
        let rpc : JsonRpc = this.rpc = new JsonRpc(this.endpoint, { fetch });
        this.api = new Api({rpc, signatureProvider, textDecoder : new TextDecoder(), textEncoder : new TextEncoder()});
        this.ready = true;
        this.emit('contractReady', {});

        // this.getRows<any>({
        //     contract: 'metakademy', 
        //     table: 'rewards',
        //     scope: 'metabear',
        //     key_type: 'i64',
        //     index_position: 2,
        //     upper_bound: 1022,
        //     lower_bound: 1022
        // }).then((data)=>{
        //     console.log(data);
        //     let hasTakenQuiz : number = data.rows?.filter((x)=>{ return x.lecture == 2 }).length
        //     console.log(hasTakenQuiz);
            
        // })
    }

    async getApiFromKey(priv_key : string, mainnet? : boolean){
        const signatureProvider = new JsSignatureProvider([priv_key]);
        const rpc = new JsonRpc(this.endpoint, { fetch });
        const api = new Api({ rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });
        return api;
    }

    getRows(options: any): Promise<GetRowData>
    getRows<T>(options: any): Promise<GetRows<T>>
    getRows(options : any) {
        return new Promise(async (res, rej) => {
            let defaults: any = {
                // scope: this.contractName, 
                contract: this.contractName, 
                limit: 9999, 
                index: 1,
                reverse: false
            };
            ['scope', 'contract', 'limit', 'index', 'reverse'].forEach((key) => {
                if (!options.hasOwnProperty(key)) options[key] = defaults[key]}
            );     
            // console.log('GET ROWS', options);
                  
            try {
                let result : GetRowData = await this.rpc!.get_table_rows({
                    json: true,
                    code: options.contract,
                    scope: options.scope ? options.scope : options.contract,
                    table: options.table,
                    index_position: options.index_position,
                    limit: options.limit,
                    lower_bound: options.lower_bound,
                    upper_bound: options.upper_bound,
                    key_type: options.key_type,
                    reverse: options.reverse,
                });
                // console.log(result);
                res(result);
            } catch (e) {
                console.log('\nCaught exception on get_table_rows: ', e);
                this.emit('walletError', e);
                if (e instanceof RpcError) rej(JSON.stringify(e.json, null, 2));
            }
        })
    }

    pushTransaction(options: TransactionOptions | TransactionOptions[]): Promise<PushTransactionArgs | TransactResult>{
        return new Promise(async (res : any, rej) => {
            if (!this.maintenance){
                let actions = []

                if(Array.isArray(options)) {
                    for(let option of options) {
                        let { account, name, actor, data  } = option;
                        actions.push({
                            account: account ? account : this.contractName,
                            name: name,
                            authorization: [{
                                actor: actor,
                                permission: 'active'
                            }],
                            data: data
                        })
                    }
                } else {
                    let { account, name, actor, data  } = options;
                    actions.push({
                        account: account ? account : this.contractName,
                        name: name,
                        authorization: [{
                            actor: actor,
                            permission: 'active'
                        }],
                        data: data
                    })
                }
                
                try {
                    const result = await this.api!.transact(
                        { actions }, 
                        { blocksBehind: 3, expireSeconds: 3600 }
                    );

                    this.emit('success', result);
                    // console.log(result);
                    res(result);
                }
                catch (e : any) {
                    console.log('\nCaught exception on transact: ' + e);
                    this.emit('walletError', e);
                    rej(e.toString().replace('Error: assertion failure with message: ', ''))
                }
            }
            else {
                this.system.showToast({ header: "Maintenance Mode", message: "You cannot push transactions at this time. Click the banner below for more info.", color: "warning" })
                this.load.dismiss()
            }
        })
    }

    async pushTransactionFromActions(actions: Action[], auth_key?: string) {
        let api = auth_key ? await this.getApiFromKey(auth_key) : this.api;
        return new Promise(async (res, rej) => {
            if(!api) rej('Error getting API')
            else {
                try {
                    let transaction = { actions };
                    let options = { blocksBehind: 3, expireSeconds: 3600 };
                    //console.log(transaction);
                    const result = await api.transact(transaction, options);
                    this.emit('success', result);
                    // console.log(result);
                    res(result);
                } catch (e) {
                    console.log('\nCaught exception on transact: ' + e);
                    this.emit('walletError', e);
                    if (e instanceof RpcError)
                        res(JSON.stringify(e, null, 2));
                    else res(JSON.stringify(e))
                }
            }
        })
    }

    // getUser(username : string){
    //     return new Promise((resolve, reject) => {
    //         this.http.get(`${HYPERION}/v2/state/get_account?account=${username}`).subscribe((res:any) => {
    //             resolve(res)
    //         }, (err:any) => {
    //             reject(err)
    //         })
    //     })
    // }

    getAccount(username : string, checkEmail? : boolean){
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'mk.users',
                contract: 'mk.users',
                table: 'accounts',
                lower_bound: username,
                upper_bound: username,
                limit: 1
            })
            .then(async (data : GetRowData) => {
                if (data.rows && data.rows.length && data.rows[0].username == username){
                    if (checkEmail){
                        console.log(data.rows[0]);
                        
                        if (data.rows[0].email && data.rows[0].email != '') resolve(data.rows[0])
                        else reject({ error: 'Email not found' })
                    }
                    else resolve(data.rows[0])
                }
                else reject({ error: `Username '${username}' not found`})
            }, (err:any) => { reject(err) })
        })
    }
    getUser(username : string, checkEmail? : boolean): Promise<User>{
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'mk.users',
                contract: 'mk.users',
                table: 'users',
                lower_bound: username,
                upper_bound: username,
                limit: 1
            })
            .then(async (data : GetRowData) => {
                if (data.rows && data.rows.length && data.rows[0].username == username)
                    resolve(data.rows[0])
                else reject({ error: 'User not found' })
            }, (err:any) => { reject(err) })
        })
    }
    getLinks(username : string): Promise<Link[]>{
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: username,
                contract: 'mk.users',
                table: 'links',
            })
            .then(async (data : GetRowData) => {
                if (data.rows) resolve(data.rows)
                else reject({ error: 'User links not found' })
            }, (err:any) => { reject(err) })
        })
    }

    getActions(username : string, limit? : number, offset? : number){
        if (!limit) limit = 100
        if (!offset) offset = 0

        return this.rpc?.history_get_actions(username, 0, offset)
    }

    getTransferActions(username : string){
        return new Promise((resolve, reject) => {
            // this.http.post(`${CHAIN}/v1/history/get_actions`, {
            //     "account_name": username,
            //     "filter": "*:transfer",
            //     "sort": "asc",
            // }).subscribe((res:any) => {
            //     console.log(res);
                
            //     resolve(res)
            // }, (err:any) => {
            //     reject(err)
            // })
            this.http.get(`${HYPERION}/v2/history/get_actions?account=${username}&limit=1000&filter=*:transfer`).subscribe((res:any) => {
                resolve(res)
            }, (err:any) => {
                reject(err)
            })
        })
    }
    
    checkAddressLink(username : string){
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'wire.users',
                contract: 'wire.users',
                table: 'addresses',
                lower_bound: username,
                upper_bound: username
            })
            .then(async (data : GetRowData) => {
                if (data.rows.length > 0)
                    resolve(data.rows[0].address)
                else {
                    this.getRows({   
                        scope: 'wire.users',
                        contract: 'wire.users',
                        table: 'accounts',
                        lower_bound: username,
                        upper_bound: username
                    })
                    .then(async (data : GetRowData) => {
                        if (data.rows.length > 0)
                            resolve('0x' + data.rows[0].eth_address)
                        else reject(false)
                    }, (err:any) => { reject(err) })
                }
            }, (err:any) => { reject(err) })
        })
    }

    checkAccountLink(address : string){
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'wire.users',
                contract: 'wire.users',
                table: 'addresses',
            })
            .then(async (data : GetRowData) => {
                for (let user of data.rows) 
                    if (user.address.toLowerCase() == address.toLowerCase()) 
                        resolve(user)

                this.getRows({   
                    scope: 'wire.users',
                    contract: 'wire.users',
                    table: 'accounts',
                })
                .then(async (data : GetRowData) => {
                    for (let user2 of data.rows) 
                        if ('0x' + user2.eth_address.toLowerCase() == address.toLowerCase()) 
                            resolve(user2)
                    
                    reject(false)
                }, (err:any) => { reject(false) })

                reject(false)
            }, (err:any) => { reject(false) })
        })
    }

    checkEthAccount(username : string, address : string) {
        return new Promise((resolve, reject) => {
            this.getRows({   
                scope: 'wire.users',
                contract: 'wire.users',
                table: 'accounts',
                lower_bound: username, 
                upper_bound: username,
                key_type: 'name'
            })
            .then(async (data : GetRowData) => {
                if (data.rows.length > 0) 
                    if ('0x' + data.rows[0].eth_address.toString().toLowerCase() == address.toString().toLowerCase())
                        resolve(true)
                    else reject('Incorrect address for given username')
                else reject('Username not found')

                if (data.rows.length > 0 && '0x' + data.rows[0].eth_address.toString().toLowerCase() == address.toString().toLowerCase()) resolve(true)
                else reject(false)
            }, (err:any) => { reject(err) })
        })
    }

    findEndpoint(): Promise<string> {
        let prom = new Promise<string>((resolve, reject) => {
            let proms: Array<Promise<PingResponse>> = []
            for(let ep of this.endpoints) {
                proms.push(new Promise((resolve) => {
                    let start = new Date().getTime();
                    let url = ep + '/v1/chain/get_info';
                    this.http.get(url).subscribe((response) => {
                        let end = new Date().getTime();
                        let ms = end - start;
                        resolve({
                            ms,
                            endpoint: ep
                        })
                    }, err => {
                        // console.log('Error getting info');
                        resolve({
                            ms: undefined,
                            endpoint: ep
                        });
                    })
                }))
            }

            // console.log(proms);

            Promise.all(proms).then((pings) => {
                // console.log('FINISHED');
                let successful = pings.filter(p => p.ms != undefined);
                if(successful.length) {
                    let sorted = successful.sort((a, b) => {return  a.ms && b.ms ? a.ms > b.ms ? 1 : b.ms > a.ms ? -1 : 0 : 0 });
                    // console.log(sorted);
                    // console.log('FOUND', sorted[0].endpoint);
                    
                    resolve(sorted[0].endpoint);
                } else {
                    resolve(this.endpoint);
                }
            })
        })
        // console.log(prom);
        
        return prom;
    }

    getAllAccounts() : Promise<Account[]>{
        return new Promise ((resolve, reject)=>{
            let getAccounts  = async (lower_bound? : string) : Promise<Account[]> => {
                let userRow = await this.getRows<Account>({
                    table: 'accounts',
                    key_type: 'name',
                    lower_bound: lower_bound ? this.uint64ToName(lower_bound!) : undefined
                })
                let users = userRow.rows
                if (userRow && userRow.more && userRow.next_key) {
                    let moreUsers = await getAccounts(userRow.next_key)
                    return moreUsers.concat(users)
                }   else return users
            }
            getAccounts().then(async (users)=>{ resolve(users) })
        })
    }
    uint64ToName(int: string) {
        try {
            const builtinTypes = createInitialTypes()
            const typeUint64 = builtinTypes.get("uint64")
            const typeName = builtinTypes.get("name")
            var buffer = new SerialBuffer({ textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });
            typeUint64?.serialize(buffer, int)
            return typeName?.deserialize(buffer)
        } catch { return ''; }
    }

    async linkAddr(address : string){
        console.log('linkaddr');
        
        const loading = await this.load.create({ spinner: 'crescent', message: `Linking...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

        this.pushTransaction({
            name: 'linkaddr',
            actor: this.auth.user.username,
            data: { 
                username: this.auth.user.username,
                address
            }
        })
        .then((res : any) => {
            loading.dismiss();
            if (res.transaction_id){
                this.system.showToast({ 
                    header: `Address linked successfully`, 
                    // message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                    color: 'success', 
                    // link: EXPLORER_TRANSACTION + res.transaction_id + "?nodeUrl=https%3A%2F%2Fswamprod.airwire.io&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.airwire.io",
                    icon: 'link-outline'
                })
            }
        }, (err:any) => {
            // console.log(">>>",err);
            loading.dismiss();
            if (!err.includes('Error: duplicate transaction')) 
                this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
            
        }) 
    }

    async unlinkAddr(){
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Unlink',
            message: `Are you sure you want to unlink this address from your account?`,
            buttons: [{
                text: 'Unlink',
                handler: async () => {
                    const loading = await this.load.create({ spinner: 'crescent', message: `Unlinking...`, cssClass: 'loading-overlay', backdropDismiss: false });
                    await loading.present();

                    this.system.emit('disconnect')
                    // this.connect.disconnect() 

                    this.pushTransaction({
                        name: 'unlinkaddr',
                        actor: this.auth.user.username,
                        data: { 
                            username: this.auth.user.username
                        }
                    })
                    .then((res : any) => {
                        loading.dismiss();
                        if (res.transaction_id){
                            this.system.showToast({ 
                                header: `Address unlinked successfully`, 
                                // message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                                color: 'success', 
                                // link: EXPLORER_TRANSACTION + res.transaction_id + "?nodeUrl=https%3A%2F%2Fswamprod.airwire.io&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.airwire.io",
                                icon: 'link-outline'
                            })
                        }
                    }, (err:any) => {
                        console.log(err);
                        loading.dismiss();
                        this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
                    }) 
                }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }

    async approveListing(key : number){
        return new Promise(async (resolve, reject)=>{
		// if (this.auth.user && this.auth.user.username == 'metaadmin'){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Approve Listing',
				message: `Are you sure you want to approve this shop listing? Listing will become public on the shop page.`,
				buttons: [{
					text: 'Approve',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Approving...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

                        this.pushTransaction({
                            account: 'metakademy',
                            name: 'approvelist',
                            actor: this.auth.user.username,
                            data: { key }
                        })
                        .then((res : any) => {
                            loading.dismiss();
                            resolve(res)
                            this.emit('update-listings')
                            this.system.showToast({ 
                                header: `Shop listing approved!`,
                                // message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                                color: 'success', 
                                // link: EXPLORER_TRANSACTION + res.transaction_id + "?nodeUrl=https%3A%2F%2Fswamprod.airwire.io&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.airwire.io",
                                icon: 'link-outline'
                            })
                        }, (err:any) => {
                            loading.dismiss();
                            console.log(">>>",err);
                            reject(err)
                            this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
                        })
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		// } else console.log('Not Authorized')
        })
	}
    async deleteListing(key : number){
        return new Promise(async (resolve, reject)=>{
            const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Delete Listing',
				message: `Are you sure you want to delete this listing? Your NFT is still in the Vault and can be resubmitted at any time. Contact support to have your NFT transferred back to you if you wish.`,
				buttons: [{
					text: 'Delete',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Deleting...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

                        this.pushTransaction({
                            account: 'metakademy',
                            name: 'deletelist',
                            actor: this.auth.user.username,
                            data: { key }
                        }).then((res : any) => {
                            loading.dismiss();
                            resolve(res)
                            this.emit('update-listings')
                            this.system.showToast({ 
                                header: `Shop listing deleted`,
                                // message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                                color: 'success', 
                                // link: EXPLORER_TRANSACTION + res.transaction_id + "?nodeUrl=https%3A%2F%2Fswamprod.airwire.io&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.airwire.io",
                                icon: 'link-outline'
                            })
                        }, (err:any) => {
                            loading.dismiss();
                            console.log(">>>",err);
                            reject(err)
                            this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
                        })
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
        })
	}
	// async denyListing(key : number){
    //     return new Promise(async (resolve, reject)=>{
	// 	// if (this.auth.user && this.auth.user.username == 'metaadmin'){
	// 		const alert = await this.alert.create({
	// 			cssClass: 'my-custom-class',
	// 			header: 'Deny Listing',
	// 			message: `Are you sure you want to deny this listing? It will return to the draft state and a metaguide can resubmit it after making changes.`,
	// 			buttons: [{
	// 				text: 'Deny',
	// 				handler: async () => {
	// 					const loading = await this.load.create({ spinner: 'crescent', message: `Denying...`, cssClass: 'loading-overlay', backdropDismiss: false });
	// 					await loading.present();

    //                     this.pushTransaction({
    //                         account: 'metakademy',
    //                         name: 'setstatus',
    //                         actor: this.auth.user.username,
    //                         data: { 
    //                             key,
    //                             seller : this.auth.user.username,
    //                             status: 0
    //                         }
    //                     })
    //                     .then((res : any) => {
    //                         loading.dismiss();
    //                         resolve(res)
    //                         this.system.showToast({ header: `Shop listing denied!`, color: 'success' })
    //                         this.emit('update-listings')
    //                     }, (err:any) => {
    //                         loading.dismiss();
    //                         console.log(">>>",err);
    //                         reject(err)
    //                         this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
    //                     })
	// 				}
	// 			},{
	// 				text: 'Cancel',
	// 				role: 'cancel',
	// 			}]
	// 		});
	// 		alert.present();
	// 	// } else console.log('Not Authorized')
    //     })
	// }

    submitClaim(nft : ShopListing){
        return new Promise(async (resolve, reject)=>{
            // Health checks
            if (!this.auth.user) {
                reject('Not logged in')
                return
            }
            if (!this.auth.address) {
                reject('You must link an ETH address to your account first. Click "Connect" in the header.')
                return
            }
            let bal = await this.auth.getRewardsBalance(this.auth.user.username)
            if (bal < nft.price!) {
                reject('Not enough funds - Cost: ' + nft.price + " REWARDS, Balance: " + bal + " REWARDS")
                return
            }

            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Confirm Claim',
                message: `Are you sure you want to claim this NFT? 
                    <br><br>When you click <i>Claim</i>, <b>${nft.price!} REWARDS</b> will be transfered from your account to the Metakademy shop and your claim will become pending. 
                    <br><br>Then, the secure vault API will automatically complete the transaction to transfer this NFT to your wallet <b>${this.auth.addressShort}</b>.
                    <br><br><i>Please allow a minute or two for the NFT to apprear in your ETH wallet. If there is an error, you will be notified and refunded.</i>`,
                buttons: [{
                    text: 'Claim',
                    handler: async () => {
                        const loading = await this.load.create({ spinner: 'crescent', message: `Claiming...`, cssClass: 'loading-overlay', backdropDismiss: false });
                        await loading.present();
    
                        this.pushTransaction({
                            account: 'eosio.token',
                            name: 'transfer',
                            actor: this.auth.user.username,
                            data: { 
                                from: this.auth.user.username,
                                to: "metakademy",
                                quantity: nft.price!.toFixed(4) + " REWARDS",
                                memo: nft.key + ":" + this.auth.address
                            }
                        })
                        .then((res : any) => {
                            if (res.transaction_id){
                                // this.system.showToast({ 
                                    //     header: `Claim Submitted!`, 
                                    //     message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                                    //     color: 'success', link: EXPLORER_TRANSACTION + res.transaction_id + "?nodeUrl=https%3A%2F%2Fswamprod.airwire.io&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.airwire.io",
                                    // })
                                setTimeout(()=>{
                                    loading.dismiss();
                                    this.emit('update-listings')
                                    this.auth.emit('check-claims')
                                    resolve(res)
                                }, 2000)
                            }
                            else loading.dismiss();
                        }, (err:any) => {
                            console.log(err);
                            loading.dismiss();
                            // this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
                            reject(err)
                        }) 
                    }
                },{
                    text: 'Cancel',
                    role: 'cancel',
                }]
            });
            alert.present();
        })
    }

    cancelClaim(claim : ShopClaim){
        return new Promise(async (resolve, reject)=>{
            if (!this.auth.user) {
                reject('Not logged in')
                return
            }
            if (this.auth.user.username != claim.buyer) {
                reject('You can only cancel claims where you are the Buyer')
                return
            }

            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Cancel Claim',
                message: `Are you sure you want to cancel this claim? 
                    <br><br>When you click <i>Confirm</i>, <b>${claim.price!} REWARDS</b> will be transfered back to your account and the pending claim will be deleted. 
                    <br><br>You can resubmit this claim anytime from the shop.`,
                buttons: [{
                    text: 'Confirm',
                    handler: async () => {
                        const loading = await this.load.create({ spinner: 'crescent', message: `Cancelling...`, cssClass: 'loading-overlay', backdropDismiss: false });
                        await loading.present();
    
                        this.pushTransaction({
                            account: 'metakademy',
                            name: 'cancelclaim',
                            actor: this.auth.user.username,
                            data: { claimkey: +claim.claimkey }
                        })
                        .then((res : any) => {
                            loading.dismiss();
                            if (res.transaction_id){
                                this.system.showToast({ 
                                    header: `Claim Cancelled`, 
                                    // message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                                    color: 'success', 
                                    // link: EXPLORER_TRANSACTION + res.transaction_id + "?nodeUrl=https%3A%2F%2Fswamprod.airwire.io&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.airwire.io",
                                })
                                setTimeout(()=>{
                                    this.emit('update-listings')
                                    resolve(res)
                                }, 1000)
                            }
                        }, (err:any) => {
                            console.log(err);
                            loading.dismiss();
                            this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
                            reject(err)
                        }) 
                    }
                },{
                    text: 'Nevermind',
                    role: 'cancel',
                }]
            });
            alert.present();
        })
    }

    denyClaim(claim : ShopClaim){
        return new Promise(async (resolve, reject)=>{
            if (!this.auth.user) {
                reject('Not logged in')
                return
            }
            if (this.auth.user.username != claim.seller) {
                reject('You can only deny claims where you are the Seller')
                return
            }

            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Deny Claim',
                message: `Are you sure you want to deny this claim? 
                    <br><br>When you click <i>Confirm</i>, <b>${claim.price!} REWARDS</b> will be transfered back to the buyer <i>${claim.buyer}</i> and the pending claim will be deleted. 
                    <br><br>This listing will then become available again for anyone to claim from the shop.`,
                buttons: [{
                    text: 'Confirm',
                    handler: async () => {
                        const loading = await this.load.create({ spinner: 'crescent', message: `Denying...`, cssClass: 'loading-overlay', backdropDismiss: false });
                        await loading.present();
    
                        this.pushTransaction({
                            account: 'metakademy',
                            name: 'cancelclaim',
                            actor: this.auth.user.username,
                            data: { claimkey: +claim.claimkey }
                        })
                        .then((res : any) => {
                            loading.dismiss();
                            if (res.transaction_id){
                                this.system.showToast({ 
                                    header: `Claim Denied`, 
                                    // message: `TRX ID: ${res.transaction_id?.slice(0,10)}`, 
                                    color: 'success', 
                                    // link: EXPLORER_TRANSACTION + res.transaction_id + "?nodeUrl=https%3A%2F%2Fswamprod.airwire.io&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.airwire.io",
                                })
                                setTimeout(()=>{
                                    this.emit('update-listings')
                                    resolve(res)
                                }, 1000)
                            }
                        }, (err:any) => {
                            console.log(err);
                            loading.dismiss();
                            this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : err, color: 'danger'})
                            reject(err)
                        }) 
                    }
                },{
                    text: 'Nevermind',
                    role: 'cancel',
                }]
            });
            alert.present();
        })
    }

    getListingByKey(key : number): Promise<ShopListing>{
        return new Promise((resolve, reject)=>{
            this.getRows<ShopListing>({
                contract: 'metakademy',
                table: 'listings',
                lower_bound : key,
                upper_bound : key,
                limit: 1 
            }).then((data)=>{
                if (data.rows.length) resolve(data.rows[0])
                else reject()
            })
        })

    }

    showError(error : any){
        this.system.emit('toast', { header: "Something went wrong...", message: error.message, color: 'danger' })
    }
    shortenAddress(address : string) {
        const prefix = address.slice(0, 6);
        const suffix = address.slice(-4);
        return `${prefix}...${suffix}`;
    }

    on(event : string) : Subject<any> {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export function makeSingleKeyAuth(key : string) {
	return {
		'threshold': 1,
		'keys': [{'key': key, 'weight': 1}],
		'accounts': [],
		'waits': []
	};
}
export function makeAsset(amount: number | string, symbol: string, precision: number) {
	const value = typeof amount === 'string' ? parseFloat(amount) : amount;
	return `${value.toFixed(precision)} ${symbol}`;
}

export interface GetRows<T> {
    rows : Array<T>,
    more : boolean,
    next_key : string
}
export interface GetRowData {
    rows : Array<any>,
    more : boolean,
    next_key : string
}
export interface getKeys {
    data: Keys,
    msg: string,
    result: number
}
export interface Keys {
    active: Key,
    owner: Key,
}
export interface Key {
    pub_key : string,
    priv_key : string,
}
export interface Action {
    account: string,
    name: string,
    authorization: Auth[],
    data: Object,
}
export interface Auth {
    actor: string,
    permission: string
}
export interface TransactionOptions {
    account?: string;
    name: string;
    actor: string;
    data: any;
}
export interface Permission {
    type: string;
    actor: string;
}
export interface TokenListOptions {
    token?: string;
    meta?: boolean; 
    stat?: boolean;
}
export interface NFTResult {
	more: boolean;
	next_key: string;
	rows: NFTRow[]
}

export interface NFTRow {
	address: string;
	ceil_price: string;
	floor_price: string;
	id: number
	image: string;
	name: string;
	popularity: number;
}
interface PingResponse {
    ms?: number;
    endpoint: string;
}
export interface WireChainUser {
    user: string;
    eth_address?: string;
    nonce: number;
    verified: number;
    metamask_user: boolean;
    added: string | Date;
    modified: string | Date;
}
interface ContractJson {
    _format: string;
    contractName: string;
    souceName: string;
    abi: AbiItem | AbiItem[];
    bytecode: string;
    deployedBytecode: string;
    linkReference: any;
    deployedLinkReferences: any;
}
export interface GroupUser {
    group: string
    permission: 0 | 1 | 2 | 3
}
export interface Link {
    key : number
    link : string
}
export interface LinkDictionary {
    [key : number]: string
}