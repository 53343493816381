import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
    },
    {
        path: 'courses',
        loadChildren: () => import('./courses/courses.module').then( m => m.CoursesPageModule)
    },
    {
        path: 'metaguides',
        loadChildren: () => import('./metaguides/metaguides.module').then( m => m.MetaGuidesPageModule)
    },
    {
        path: 'shop',
        loadChildren: () => import('./store/store.module').then( m => m.StorePageModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./extras/faq/faq.module').then( m => m.FaqPageModule)
    },
    {
        path: 'terms',
        loadChildren: () => import('./extras/terms/terms.module').then( m => m.TermsPageModule)
    },
    {
        path: 'privacy',
        loadChildren: () => import('./extras/privacy/privacy.module').then( m => m.PrivacyPageModule)
    },
    {
        path: 'claim',
        loadChildren: () => import('./claim/claim.module').then( m => m.ClaimPageModule)
    },
    {
        path: 'create-course',
        loadChildren: () => import('./edit-course/edit-course.module').then( m => m.EditCourseModule)
    },
    {
        path: 'claim',
        loadChildren: () => import('./claim/claim.module').then( m => m.ClaimPageModule)
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    },
    {   path: '**', 
        redirectTo: ''
    },
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
