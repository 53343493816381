import { Component, Input, OnInit } from '@angular/core';
import { SystemService } from '../../_services/system.service';
import { AuthService } from '../../_services/auth.service';
import { HttpClient } from '@angular/common/http';
import { ConnectService } from "../../_services/connect.service";
import { ContractService } from 'src/app/_services/contract.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { ShopListing } from 'src/app/_services/eth.service';

@Component({
    selector: 'app-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss'],
})
export class ListingModalComponent implements OnInit {
	@Input() nft! : ShopListing

    get allClaimed(){
		return this.nft && this.nft.claimed != undefined && this.nft.available != undefined && this.nft.claimed >= this.nft.available
	}

    constructor(
        private contract : ContractService,
        public connect: ConnectService,
        private alert: AlertController,
        public system: SystemService,
        private auth: AuthService,
        private load: LoadingController,
        public modal : ModalController,
        private modal2 : ModalController,
        private nav : NavController,
        private http: HttpClient) {
    }

    ngOnInit() {
        // console.log(this.nft);
        
    }

    claim(){
        this.modal.dismiss(true)
    }

    openMetaguide(){
        this.modal.dismiss()
        this.nav.navigateForward(['metaguides', this.nft.seller])
    }
    openTokenAddress(){
        if (this.nft.chainid == 1)
            window.open(`https://etherscan.io/nft/${this.nft.token_address}/${ this.nft.token_id ? this.nft.token_id : '' }`, "_blank")
        else if (this.nft.chainid == 137)
            window.open(`https://polygonscan.com/token/${this.nft.token_address}/${ this.nft.token_id ? '?a=' + this.nft.token_id : '' }`, "_blank")

        // window.open(`https://etherscan.io/token/${this.nft.token_address}`, '_blank')
    }
    openTokenID(){
        if (this.nft.chainid == 1)
            window.open(`https://etherscan.io/nft/${this.nft.token_address}/${ this.nft.token_id ? this.nft.token_id : '' }`, "_blank")
        else if (this.nft.chainid == 137)
            window.open(`https://polygonscan.com/token/${this.nft.token_address}/${ this.nft.token_id ? '?a=' + this.nft.token_id : '' }`, "_blank")

        // window.open(`https://etherscan.io/nft/${this.nft.token_address}/${this.nft.token_id}`, '_blank')
    }
    openAddress(){
        if (this.nft.chainid == 1)
            window.open(`https://etherscan.io/address/${this.nft.seller_address}`, "_blank")
        else if (this.nft.chainid == 137)
            window.open(`https://polygonscan.com/address/${this.nft.seller_address}`, "_blank")

        // window.open(`https://etherscan.io/address/${this.nft.seller_address!}`, '_blank')
    }

    // async login(){
    //     if (!this.canLogin){
    //         this.system.showToast({ header: 'Username & Password required!', color: 'warning'})
    //         return
    //     }

    //     let username = this.username.toLowerCase()
    //     let password = this.password

    //     const loading = await this.load.create({ spinner: 'crescent', message: `Logging in...`, cssClass: 'loading-overlay', backdropDismiss: false });
    //     await loading.present();

    //     this.crypto.generateKeySeed(username, password).then((key : Key) => {
    //         this.http.post(API + "login", { username, key: key.pub_key }).subscribe((res : any) => {
    //             // console.log(res);
    //             loading.dismiss()
    //             let user : Account = res
    //             this.system.showToast({ header: `Login Success`, message: `Welcome, ${user.username}!`, color: 'success' })
    //             // user.profileImgURL = res.profileImgURL
    //             this.auth.login(user)
    //             // this.discord.login(user.username)
    //             this.auth.setKey(key)
    //             this.contract.login(key)
    //             this.modal.dismiss()

    //             setTimeout(() => {
    //                 this.auth.emit('login', user.username)
    //             }, 500)

    //         }, (error:any) => {
    //             console.log(error.error);
    //             loading.dismiss()
    //             this.system.showToast({ header: error.error, color: 'danger' })
    //         })
    //     })
    // }

    // async openRegister(){
    //     let top = await this.modal2.getTop()
	// 	top?.dismiss()

    //     this.modal.dismiss().then(async ()=>{
    //         const modal = await this.modal2.create({
    //             component: RegisterComponent,
    //             canDismiss: true,
    //             backdropDismiss: false,
    //             cssClass: 'main-modal2'
    //         });
    //         return await modal.present(); 
    //     })
	// }

    // async resetPassword(){
    //     let top = await this.modal2.getTop()
	// 	top?.dismiss()

    //     this.modal.dismiss().then(async ()=>{
    //         const modal = await this.modal2.create({
    //             component: ResetPasswordComponent,
    //             canDismiss: true,
    //             cssClass: 'main-modal'
    //         });
    //         return await modal.present(); 
    //     })
	// }
}