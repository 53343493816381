<div id="course-card" (click)="openCourse()" [class.imgHover]="!noImgHover">
	<ng-container *ngIf="!loading; else load">
		<ng-container *ngIf="!mini">
			<div class="inner">
				<div class="img-container">
					<div class="category">
						{{ course.categories.split(',')[0] }}
						<span *ngIf="course.categories.split(',').length > 1">&nbsp;+{{ course.categories.split(',').length -1 }}</span>
					</div>
					
					<span class="img-cover"></span>
					<ion-skeleton-text *ngIf="imgLoading" class="size m-0" animated></ion-skeleton-text>
					<img [src]="system.safeSanitize(course.thumbnail + (noImgHover ? '' : '?quality=30&width=600')) " [hidden]="imgLoading" (load)="onLoad()">
				</div>

				<div class="course-card-body">
					<div class="course-card-title">
						{{ course.title }}
					</div>
					<div class="rating">
						<ng-container *ngIf="course.rating > 0; else noRatings">
							<div class="num">{{ course.rating | number:'1.1':'en-US'}}</div>
							<div class="stars">
								<img src="/assets/icons/heart_{{ system.mode }}.png" *ngFor="let x of [].constructor(wholeStars)"> 	
								<img src="/assets/icons/heart_{{ system.mode }}_half.png" *ngIf="halfStar"> 	
								<!-- <i class="fa-solid fa-sm fa-star" *ngFor="let x of [].constructor(wholeStars)"></i>
								<i class="fa-solid fa-sm fa-star-half" *ngIf="halfStar"></i> -->
							</div>
						</ng-container>
						<ng-template #noRatings>
							<div class="stars" style="margin: 4px 0.75rem 3px 0">
								<img src="/assets/icons/heart_{{ system.mode }}.png" style="margin-left: 0 !important; filter: grayscale(1)"> 	
								<!-- <i class="fa-regular fa-sm fa-star m-0" ></i> -->
							</div>
							<small style="opacity: .6;">No Ratings Yet</small>
						</ng-template>
					</div>
					<div class="length">
						<div class="flex-start">
							<i class="fa-regular fa-clock"></i>
							<!-- <small>{{ system.timeFormat(course.duration).split(':')[0] }}</small> -->
							<small>
								{{ system.timeFormat(course.duration) }}
								<!-- <ng-container *ngIf="system.timeFormatLong(course.duration).length == 3">
									{{ system.timeFormatLong(course.duration)[2] }}&nbsp;hr&nbsp;
								</ng-container>
								{{ system.timeFormatLong(course.duration)[1] }}&nbsp;min&nbsp;
								{{ system.timeFormatLong(course.duration)[0] }}&nbsp;sec -->
							</small>
						</div>
						<div class="flex-end">
							<i class="fa-solid fa-chalkboard"></i>				
							<small>{{ course.numLectures }}&nbsp;Lecture{{ course.numLectures > 1 ? 's' : '' }}</small>
						</div>
					</div>
					<div class="author">
						<div class="flex-start">
							<ng-container *ngIf="course.profilePic && course.profilePic != ''; else noPic">
								<img class="pfp-sm" [src]="course.profilePic + '?quality=30&width=50'" alt="">
							</ng-container>
							<ng-template #noPic>
								<svg width="40" height="40" [data-jdenticon-value]="course.metaguideUser" style="margin-right: 1rem;"></svg>
							</ng-template>

							<div class="block">
								<div>{{ course.metaguideName }}</div>
								<div class="my-small" style="margin-top: -5px;">@{{ course.metaguideUser }}</div>
							</div>
						</div>
						<div class="flex-end">
							<div [class]="'my-alert-small ' + system.courseStatusColor(course.status)" *ngIf="showState">
								{{ system.courseStatus(course.status) }}
							</div>
                            <div *ngIf="course.pinned == 1" style="width:20px;" class="flex-end">
                                <i class="fa-solid fa-map-pin fa-lg"></i>
                            </div>
							<div style="width:20px;" class="flex-end" (click)="openOptions($event)">
								<i class="fa-solid fa-ellipsis-vertical fa-xl"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		
		<ng-container *ngIf="mini">
			<div class="mini">
				<img [src]="course.thumbnail" class="img-small" alt="">
				<div class="mini-inner">
					<div class="mini-card-title">
						{{ course.title }}
					</div>
					<div class="length">
						<div class="flex-start">
							<i class="fa-solid fa-circle-user fa-sm"></i>
							<small>{{ course.metaguideName }}</small>
						</div>
						<div class="flex-start second">
							<i class="fa-regular fa-clock fa-sm"></i>
							<small>{{ system.timeFormat(course.duration) }}</small>
						</div>
						<div class="flex-start second">
							<i class="fa-solid fa-chalkboard fa-sm"></i>				
							<small>{{ course.numLectures }}</small>
						</div>
					</div>
					<div class="metaguide" *ngIf="course.finished">
						<small>
							Your Score:&nbsp;
							<span class="text-color">
								{{ (totalCorrect / totalQuestions) * 100 | number:'1.0-0':'en-US'}}%&nbsp;
								({{ totalCorrect }}/{{ totalQuestions }})</span>
							&nbsp;&nbsp;Completed: 
							<span class="text-color">{{ course.finished | date:'short' }}</span>
						</small>
					</div>
					<div class="metaguide" *ngIf="course.numCompleted">
						<small>
							Your Progress:&nbsp;
							<span class="text-color">
								{{ (course.numCompleted / course.numLectures) * 100 | number:'1.0-0':'en-US'}}%&nbsp;
								({{ course.numCompleted }}/{{ course.numLectures }})</span>
						</small>
					</div>
				</div>
			</div>
			<ng-container *ngIf="auth.user && auth.user.username == 'metaadmin' && course.status==1">
				<div class="separator m-0"></div>
				<div class="w-100 p-2 flex-start">
					<div class="approve" (click)="Course.approveCourse(course.course_id)">
						<i class="fa-solid fa-check"></i>
						<ion-label>Approve</ion-label>
					</div>
					<div class="deny" (click)="Course.denyCourse(course.course_id)">
						<i class="fa-solid fa-xmark"></i>
						<ion-label>Deny</ion-label>
					</div>
					<ion-chip class="zoomIn" (click)="review()">
						<i class="fa-solid fa-eye"></i>
						<ion-label>Review</ion-label>
					</ion-chip>
				</div>
			</ng-container>

		</ng-container>
	</ng-container>

	<ng-template #load>
		<div class="inner">
			<div class="img-container">
				<span class="img-cover"></span>
				<ion-skeleton-text class="size m-0" animated></ion-skeleton-text>
			</div>

			<div class="course-card-body">
				<div >
					<ion-skeleton-text class="w-100" style="height: 16px" animated></ion-skeleton-text>
				</div>
				<div style="margin-top: 15px;">
					<ion-skeleton-text class="w-100" animated style="height: 14px;"></ion-skeleton-text>
				</div>
				<div class="flex-between" style="margin-top: 15px;">
					<ion-skeleton-text class="w-100" style="margin-right:.5rem !important; height: 15px" animated></ion-skeleton-text>
					<ion-skeleton-text class="w-100" style="margin-left:.5rem !important; height: 15px" animated></ion-skeleton-text>
				</div>
				<div class="separator-lightest"></div>
				<div class="flex-between" style="padding: 9px 0;">
					<ion-skeleton-text class="w-100" style="margin-right:.5rem !important; height: 15px" animated></ion-skeleton-text>
					<ion-skeleton-text class="w-100" style="margin-left:.5rem !important; height: 15px" animated></ion-skeleton-text>
				</div>
			</div>
		</div>
	</ng-template>
</div>