import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { FormControl, FormGroup } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Account, AuthService, User } from 'src/app/_services/auth.service';
import { ContractService, Key } from 'src/app/_services/contract.service';
import { CryptoService } from 'src/app/_services/crypto.service';
import { SystemService } from 'src/app/_services/system.service';
import { API } from '../../_constants/constants';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

	username : string = this.auth.user ? this.auth.user.username : ''
	loadingCheck = false
	checked = false

	phone : boolean = false
	email : boolean = false

	error? : string

	sendingCode = false
	codeSent = false

	code? : string

	checkingCode? : boolean
	checkedCode? : boolean
	verifiedCode? : boolean

	// valid_password : boolean = false
    // valid_confirm : boolean = false

	checkSpecial? : boolean
    checkUpper? : boolean
    checkLower? : boolean
    checkNumber? : boolean

	passwordType: string = 'password'
    passwordIcon: string = 'eye-off-outline'

	password? : string
	password_confirm? : string

	constructor(
		public auth : AuthService,
		private contract : ContractService,
		private crypto : CryptoService,
		private system : SystemService,
		private http : HttpClient,
		public modal : ModalController,
		private load : LoadingController,
		private alert : AlertController ){}

	ngOnInit() {
	}

	checkVerified(){
		this.username = this.username?.toLowerCase()
		this.codeSent = false
		this.checkedCode = false
		this.checked = false
		this.loadingCheck = true
		this.error = undefined

		this.contract.getAccount(this.username, true).then((res)=>{
			this.email = true
			this.loadingCheck = false
			this.checked = true
		}, err =>{
			console.log(err);
			
			this.loadingCheck = false
			this.error = err.error
			this.checked = true
		})
	}

	sendEmail(){
		this.sendingCode = true

		this.http.post(API + "sendEmail_for_Reset", { username : this.username }).subscribe((res:any) => {
			this.sendingCode = false
			this.codeSent = true
		}, (err:any) => {
			this.sendingCode = false
		})
	}
	// sendPhone(){
	// 	this.sendingCode = true

	// 	this.http.post(API + "/sendPhone_for_Reset", { username : this.username }).subscribe((res:any) => {
	// 		this.sendingCode = false
	// 		this.codeSent = true
	// 	}, (err:any) => {
	// 		this.sendingCode = false
	// 	})	
	// }

	checkCode(){
		this.checkedCode = true
		this.checkingCode = true

		this.http.post(API + "verifyReset", { username : this.username, code : this.code}).subscribe((res:any) => {
			this.checkingCode = false
			this.verifiedCode = true

			setTimeout(()=>{
				window.scroll(0, document.documentElement.offsetHeight);			
			},500)
		}, (err:any) => {
			this.checkingCode = false
			this.verifiedCode = false
			
			setTimeout(()=>{
				window.scroll(0, document.documentElement.offsetHeight);			
			},500)
		})
	}

	async confirmChange(){
		const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Change Password',
            message: `Are you sure you want to change the password for account '${this.username}'?`,
            buttons: [{
                text: 'Change',
                handler: () => {
					this.changePassword()
                }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        await alert.present();
	}

	async changePassword(){
		const loading = await this.load.create({ spinner: 'crescent', message: `Changing...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

		this.crypto.generateKeySeed(this.username!, this.password!).then((key : Key) => {
			this.http.post(API + "changePassword", { username: this.username!, code: this.code, key: key.pub_key }).subscribe((res : any) => {
				console.log(res);
				loading.dismiss()
                let user : Account = res
                this.system.showToast({ header: `Password Reset Success`, message: `Welcome, ${user.username}!`, color: 'success' })
                // user.profileImgURL = res.profileImgURL
                this.auth.login(user)
                // this.discord.login(user.username)
                this.auth.setKey(key)
                this.contract.login(key)
                this.modal.dismiss()

                // setTimeout(() => {
                //     this.auth.emit('login', user.username)
                // }, 500)
			}, (error:any) => {
				console.log(error.error);
				loading.dismiss()
				this.system.showToast({ header: `Login Error`, message: error.error, color: 'danger' })
			})
        })
	}


	get valid_password() : boolean{
		return (this.checkUpper! && this.checkLower! && this.checkSpecial! && this.checkNumber!);
	}
    checkPassword(x: any) {
        let pass = x.target.value

        this.checkSpecial = /[*@!#%&()^~{}]+/.test(pass)
        this.checkUpper   = /[A-Z]+/.test(pass)
        this.checkLower   = /[a-z]+/.test(pass)
        this.checkNumber  = /[0-9]+/.test(pass)
        // this.valid_password = false;
    
		// this.valid_password = 
    }
    // checkConfirmPassword(x : FormControl){
    //     let pass = x.value
    //     let valid = (pass == this.password_form?.value.password)
    //     this.valid_confirm = valid
    //     return valid ? null : { checkConfirmPassword: { valid: false } };
    // }
	togglePassword(){
        this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
        this.passwordIcon = this.passwordIcon === 'eye-off-outline' ? 'eye-outline' : 'eye-off-outline';
    }

	resend(){
		this.codeSent = false
		this.checkingCode = undefined
		this.checkedCode = undefined
		this.verifiedCode = undefined
		this.code = undefined

		this.sendEmail()
	}
}
